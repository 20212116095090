import { Controller } from 'stimulus';

export default class extends Controller {
    // get targets
    static targets = ['selectedModalityvalue'];
    connect() {
        this.dependentSelect();
      }
    dependentSelect() {
        const selectedModalitySelect = $(this.selectedModalityvalueTarget);
        const selectedModalityText = selectedModalitySelect.find(':selected').text();
        const secondModality = document.getElementById('secondModality');


        if (selectedModalityText === "Multimodal") {
            secondModality.classList.remove('hidden');
        }else{
            secondModality.classList.add('hidden');
        }
    }
}
