import { Controller } from 'stimulus';
import { Formio } from 'formiojs';

/**
 * API Docs for Form.io
 * https://github.com/formio/formio.js/wiki
 *
 * Full API Docs
 * https://formio.github.io/formio.js/docs/
 *
 * Examples:
 * https://formio.github.io/formio.js/app/examples/
 *
 */
export default class extends Controller {
    static targets = ['editor', 'preview', 'formData', 'formSchema'];


    async initialize() {
        const editorElem = this.editorTarget;
        const previewElem = this.previewTarget;

        this.formSaveUrl = document.location.origin + this.data.get('updatePath') + '.json';
        const initialSchema = JSON.parse(this.data.get('form') || '{}');

        // renderer
        this.formRenderer = await Formio.createForm(previewElem, initialSchema);

        this.formRenderer.on('submit', data => {
            console.log('data :', data);
        });

        // builder
        this.formBuilder = await Formio.builder(editorElem, initialSchema, {
            noDefaultSubmitButton: true
        });

        this.formSchemaTarget.innerHTML  = JSON.stringify(initialSchema);

        this.formBuilder.on('change', changed => {
            // update the preview
            this.formRenderer.setForm(this.formBuilder.schema);
            // save the schema to backend
            this.formSchemaTarget.innerHTML  = JSON.stringify(this.formBuilder.schema);
            this.saveFormSchema(this.formBuilder.schema);
        });
        // save the schema to backend
        this.formBuilderResponsive();
    }

    formBuilderResponsive() {
        let el = document.querySelector('.formcomponents');
        let el2 = document.querySelector('.formarea');

        el.classList.remove('col-xs-4', 'col-sm-3', 'col-md-2');
        el.classList.add('col-xs-12', 'col-sm-3', 'col-md-3', 'col-lg-3');

        el2.classList.remove('col-xs-8', 'col-sm-9', 'col-md-10');
        el2.classList.add('col-xs-12', 'col-sm-9', 'col-md-9', 'col-lg-9');
    }

    saveFormSchema(schema) {

        fetch(this.formSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({
                form_template: {
                    form: schema
                }
            })
        }).then(response => {
            console.log(response);
        });
    }
}