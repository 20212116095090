import { Controller } from 'stimulus';
export default class extends Controller {

  static targets = ["checkbox"];

  toggleCheckboxes(event) { 
    const checkboxes = document.querySelectorAll('.deviceCheckBox input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox !== event.target) {
        checkbox.checked = false;
      }
    });
  }
}