import { Controller } from 'stimulus';
import { Formio } from 'formiojs';

/**
 * API Docs for Form.io
 * https://github.com/formio/formio.js/wiki
 *
 * Full API Docs
 * https://formio.github.io/formio.js/docs/
 *
 * Examples:
 * https://formio.github.io/formio.js/app/examples/
 *
 */
export default class extends Controller {
    static targets = ['editor', 'preview', 'GradingFormTitle'];


    async initialize() {
        const editorElem = this.editorTarget;
        const previewElem = this.previewTarget;

        //get data properties
        this.gradingFormSaveUrl = document.location.origin + this.data.get('gradingSubmitPath') + '.json';
        this.gradingFormSaveUrlToUpdateVersion = document.location.origin + this.data.get('gradingFormVersionUpdate') + '.json';
        this.gradingFormRedirectUrl = document.location.origin + this.data.get('gradingRedirectPath');
        const initialSchema = JSON.parse(this.data.get('gradingFormSchema') || '{}');
        this.formType = this.data.get('formType');
        this.formVersion = this.data.get('formVersion');

        // renderer
        this.formRenderer = await Formio.createForm(previewElem, initialSchema);

        this.formRenderer.on('submit', data => {
            console.log('data :', data);
        });

        // builder
        this.formBuilder = await Formio.builder(editorElem, initialSchema, {
            noDefaultSubmitButton: true,
            builder: {
                layout: {
                    components: {
                        table: false,
                        tabs: false,
                        columns: false,
                        fieldset: false,
                        well: false,
                        content: false
                    }
                },
                data: false,
                premium: false,
            }
        });

        this.formBuilder.on('change', () => {
            // update the preview
            this.formRenderer.setForm(this.formBuilder.schema);
            // save the schema to backend only in edit mode
            if (this.data.get('gradingEditMode') == "yes") {
                this.saveFormSchema(this.formBuilder.schema);
                this.GradingFormTitleTarget.addEventListener('keyup', () => {
                    this.updateGradingFormTitle();
                });
            }
        });
        if (this.data.get('gradingEditMode') == "yes") {
            this.GradingFormTitleTarget.addEventListener('keyup', () => {
                this.updateGradingFormTitle();
            });
        }
        this.formBuilderResponsive();

    }

    formBuilderResponsive() {
        let el = document.querySelector('.formcomponents');
        let el2 = document.querySelector('.formarea');

        el.classList.remove('col-xs-4', 'col-sm-3', 'col-md-2');
        el.classList.add('col-xs-12', 'col-sm-3', 'col-md-3', 'col-lg-3');

        el2.classList.remove('col-xs-8', 'col-sm-9', 'col-md-10');
        el2.classList.add('col-xs-12', 'col-sm-9', 'col-md-9', 'col-lg-9');
    }

    saveFormSchema(schema) {
        const form_title = document.getElementById('form_title').value;
        if (form_title == '') {
            alert('Form title is requried');
            return false;
        } else {
            const method = this.data.get('gradingEditMode') == "yes"
                ? "PATCH" : "POST";
            // send request
            fetch(this.gradingFormSaveUrl, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: method,
                body: JSON.stringify({
                    form_version: {
                        form: schema,
                        form_type: this.formType,
                        version_number: this.formVersion,
                        form_title: form_title
                    }
                })
            }).then(response => {
                if (method == "POST") {
                    window.location = this.gradingFormRedirectUrl;
                    return false;
                }
            });
        }
    }

    saveFormSchemaWithVersionUpdate(schema) {
        const form_title = document.getElementById('form_title').value;
        if (form_title == '') {
            alert('Form title is requried');
            return false;
        } else {
            const method = "POST";
            // send request
            fetch(this.gradingFormSaveUrlToUpdateVersion, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: method,
                body: JSON.stringify({
                    form_version: {
                        form: schema,
                        form_type: this.formType,
                        version_number: this.formVersion,
                        form_title: form_title
                    }
                })
            }).then(response => {
                if (method == "POST") {
                    window.location = this.gradingFormRedirectUrl;
                    return false;
                }
            });
        }
    }

    updateGradingFormTitle() {
        const form_title = document.getElementById('form_title').value;
        if (form_title == '') {
            alert('Form title is requried');
            return false;
        } else {
            const method = "PATCH";
            // send request
            fetch(this.gradingFormSaveUrl, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: method,
                body: JSON.stringify({
                    form_version: {
                        form_title: form_title
                    }
                })
            })
        }
    }

    async changeHandler(event) {
        // get json form from template dropdown
        const formSchema = JSON.parse(event.target.value);
        // renderer
        this.formRenderer = await Formio.createForm(this.previewTarget, formSchema);
        // builder
        this.formBuilder = await Formio.builder(this.editorTarget, formSchema, {
            noDefaultSubmitButton: true
        });
        // change event
        this.formBuilder.on('change', () => {
            // update the preview
            this.formRenderer.setForm(this.formBuilder.schema);
        });
        // save the schema to backend
        this.formBuilderResponsive();

    }

    submitForm() {
        // submit the form
        this.saveFormSchema(this.formBuilder.schema);
    }

    submitFormWithUpdateVersion() {
        this.formVersion++;
        this.saveFormSchemaWithVersionUpdate(this.formBuilder.schema);
    }
}