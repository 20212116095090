import { Controller } from 'stimulus';
import { Formio } from 'formiojs';

/**
 * API Docs for Form.io
 * https://github.com/formio/formio.js/wiki
 *
 * Full API Docs
 * https://formio.github.io/formio.js/docs/
 *
 * Examples:
 * https://formio.github.io/formio.js/app/examples/
 *
 */
export default class extends Controller {
    static targets = [];


    async initialize() {
        //get data properties
        this.eligibilityFormSaveUrl = document.location.origin + this.data.get('eligibilitySubmitPath') + '.json';
        this.eligibilityFormRedirectUrl = document.location.origin + this.data.get('eligibilityRedirectPath');
        this.formType = this.data.get('formType');
        // renderer
    }

    saveFormSchema() {
        const form_title = document.getElementById('form_title').value;
        // Get the form input element by ID
        const formInput = document.getElementById('form').value;

        if (form_title == '') {
            alert('Form title is requried');
            return false;
        } else {
            // send request
            fetch(this.eligibilityFormSaveUrl, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: 'POST',
                body: JSON.stringify({
                    form_version: {
                        form_title: form_title,
                        form: formInput
                    }
                })
            }).then(response => {
                window.location = this.eligibilityFormRedirectUrl;
                return false;
            });
        }
    }
}