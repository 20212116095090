import { Controller } from 'stimulus';
export default class extends Controller {

  createComments(event) {
    event.preventDefault();
    this.selectChangeUrl = document.location.origin + `/create_certification_officer_comments.json`;
    const certification_request_id = this.data.get('certificationRequestId');
    const property_set_image = this.data.get('propertySetImage');
    const user_id = this.data.get('userId');
    const comments = document.getElementById("fetch_comments").value;
    if (comments !== '')
    {
      fetch(this.selectChangeUrl, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ certification_request_id: certification_request_id, property_set_image_id: property_set_image,
           user_id: user_id, comments: comments }),
        method: 'POST',
      }).then(response => response.json())
      .then((data) => {
        document.getElementById('certification_comments').innerHTML = data.html;               
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Please write something in comments section.',
      });
    }
  }
  deleteComments(event) {
    const comment_id = event.currentTarget.dataset.commentsId 
    event.preventDefault();
    this.selectChangeUrl = document.location.origin + `/delete_certification_officer_comments.json`;
    fetch(this.selectChangeUrl, {
      headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ id: comment_id }),
      method: 'POST',
    }).then(response => response.json())
    .then((data) => {
      document.getElementById('certification_comments').innerHTML = data.html;               
    });
  }
  editComments(event) { 
    const comment_id = event.currentTarget.dataset.commentsId 
    event.preventDefault();
    this.selectChangeUrl = document.location.origin + `/edit_certification_officer_comments.json`;
    fetch(this.selectChangeUrl, {
      headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ id: comment_id }),
      method: 'POST',
    }).then(response => response.json())
    .then((data) => {
      document.getElementById('certification_comments').innerHTML = data.html;
      document.getElementById('create_comments').style.display = "none";
      document.getElementById('edit_comments').style.display = "block";
      document.getElementById('edit_comment_field').innerHTML = data.comments.comments;
      document.getElementById('comment_id').value = data.comments.id;       
    });
  }
  cancelEditComments(event) {  
    event.preventDefault(); 
    document.getElementById('create_comments').style.display = "block";
    document.getElementById('edit_comments').style.display = "none";      
  }
  updateComments(event) {
    event.preventDefault();
    this.selectChangeUrl = document.location.origin + `/update_certification_officer_comments.json`;
    const comment_id = document.getElementById("comment_id").value;
    const comments = document.getElementById("edit_comment_field").value;
    if (comments !== '')
    {
      fetch(this.selectChangeUrl, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ id: comment_id, comments: comments}),
        method: 'POST',
      }).then(response => response.json())
      .then((data) => {
        document.getElementById('certification_comments').innerHTML = data.html;               
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Please write something in comments section.',
      });
    }
  }

  commentHistory(event) { 
    const comment_id = event.currentTarget.dataset.commentsId 
    event.preventDefault();
    const comment = document.getElementById("comments_history_"+ comment_id)
    if (comment.style.display=== 'none'){
      comment.style.display = "block";
    }
    else {
      comment.style.display = "none";
    }
  }
}
