import { Controller } from "stimulus";
import Swal from 'sweetalert2';
import $ from 'jquery';
export default class extends Controller {
    static targets = ["parent", "child","selectUser"];
    initialize() {
    this.studyId = this.data.get("studyId");
    }
    connect() {
    $('.modality-search').select2();
    this.modal = document.getElementById("popup-modal");
    }

    assignSubjects(event) {
    this.modal.classList.remove("hidden");  
    const clickedElement = event.currentTarget; 
    const modality_id = clickedElement.getAttribute("data-modality-id");
    const study_id = clickedElement.getAttribute("data-study-id");
    const stack_id = clickedElement.getAttribute("data-stack-id");
    const form_version_id = clickedElement.getAttribute("data-form-version-id"); 
    const hidden_form_version_id = document.getElementById('form_version_id');
    hidden_form_version_id.value = form_version_id;
    const hidden_study_id = document.getElementById('study_id');
    hidden_study_id.value = study_id;
    const hidden_stack_id = document.getElementById('stack_id');
    hidden_stack_id.value = stack_id;
    const Url =  document.location.origin + `/studies/${study_id}/worklists/get_grader_pool`;
    fetch(Url, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        method: 'POST',
        body: JSON.stringify({
            modality_id: modality_id,
            study_id: study_id
        })
    }).then(response => response.json())
    .then(data => {
        const select_user = document.getElementById('select_user');
        data.forEach(item => {
            const option = document.createElement('option');
            option.value = item[1]; 
            option.text = item[1];   
            select_user.appendChild(option);
          }); 
    });
    }

    
    send() {
        const selectedUserSelect = document.querySelector('#select_user');
        const hidden_study_id = document.getElementById('study_id');
        const hidden_stack_id = document.getElementById('stack_id');
        const hidden_form_version_id = document.getElementById('form_version_id');
        const Url = document.location.origin + `/studies/${hidden_study_id.value}/worklists/send_email`;
        const selectedValues = Array.from(selectedUserSelect.selectedOptions).map(option => option.value);
        if (selectedValues == '') {
            alert('Please select an email');
        } else{
        fetch(Url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'POST',
            body: JSON.stringify({
                user_emails: selectedValues,
                stack_id: hidden_stack_id.value,
                form_version_id: hidden_form_version_id.value
            })
        }).then(response => {
            // Check if the response is OK (status in the range 200-299)
            if (response.ok) {
                // Hide the modal
                this.modal.classList.add("hidden");
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Emails sent successfully.'
                });
                selectedUserSelect.innerHTML = '';

                // Deselect all selected options
                Array.from(selectedUserSelect.selectedOptions).forEach(option => {
                    option.selected = false;
                })
            }
        });
     }

    }

  
    close() {
        this.modal.classList.add("hidden");
        const selectedUserSelect = document.querySelector('#select_user');
        selectedUserSelect.innerHTML = '';
        // Deselect all selected options
        Array.from(selectedUserSelect.selectedOptions).forEach(option => {
            option.selected = false;
        });
   
    }
}