import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['modalitySelect', 'childSelect', 'devices'];
  
  fetchModality() {
    this.selectChangeUrl= document.location.origin + `/modalities/${this.modalitySelectTarget.value}/certification_request_child_modalities.json`;
    const study_id = this.data.get('study');
    const childModalityId = this.data.get('childModalityId');
    const deviceId = this.data.get('deviceId');
    fetch(this.selectChangeUrl, {
      headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ study_id: study_id }),
      method: 'POST',
    })
    .then(response => response.json())
      .then((data) => {
          const selectBox = this.childSelectTarget;
          selectBox.disabled = false;
          selectBox.innerHTML = '';
          // default text
          const defaultOption = document.createElement('option');
          defaultOption.value = "";
          defaultOption.innerHTML = "Select Child Modality";
          defaultOption.selected = childModalityId === "";
          selectBox.appendChild(defaultOption);
          // loop through data
          data["child_modalities"].forEach((item, index) => {
              const opt = document.createElement('option');
              opt.value = item[0];
              opt.innerHTML = item[1];
              opt.selected = childModalityId === item[0];
              selectBox.appendChild(opt);
          });
          const deviceselectBox = this.devicesTarget;
          deviceselectBox.disabled = false;
          deviceselectBox.innerHTML = '';
          // default text
          const devicesdefaultOption = document.createElement('option');
          devicesdefaultOption.value = "";
          devicesdefaultOption.innerHTML = "Select Device";
          devicesdefaultOption.selected = deviceId === "";
          deviceselectBox.appendChild(devicesdefaultOption);
          // loop through data
          data["devices"].forEach((item, index) => {
              const opt = document.createElement('option');
              opt.value = item["id"];
              opt.innerHTML = item["name"];
              opt.selected = deviceId === item["id"];
              deviceselectBox.appendChild(opt);
          }); 
      });
    }
}
