import { Controller } from 'stimulus';

import Hammer from 'hammerjs';
import * as cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.configure({
    beforeSend: function(xhr) {
        
        // Add custom headers here (e.g. auth tokens)
        //xhr.setRequestHeader('x-auth-token', 'my auth token');
    },
    useWebWorkers: true,
});

const { PanTool, WwwcTool, ZoomMouseWheelTool, StackScrollMouseWheelTool } = cornerstoneTools;
const LEFT_CLICK = 1;
const MIDDLE_CLICK = 4;
const RIGHT_CLICK = 2;

export default class extends Controller {
    static targets = ['viewer', 'url', 'imageTag']

    initialize() {
        this.imageTag = this.imageTagTarget;
        this.imageTagList = [{"label": "Study Date", "hex": 'x00080020'}, 
            {"label": "Study Time", "hex": 'x00080030'}, 
            {"label": "Operators Name", "hex": 'x00081070'}, 
            {"label": "Manufacturer's Model Name", "hex": 'x00081090'}, 
            {"label": "Patient ID", "hex": 'x00100020'}, 
            {"label": "Device Serial Number", "hex": 'x00181000'}, 
            {"label": "Software Version(s)", "hex": 'x00181020'}, 
            {"label": "Image Laterality", "hex": 'x00200062'}];
        this.currentImage = 0;
        this.images = [];
        this.readImages();

        cornerstoneWebImageLoader.external.cornerstone = cornerstone;
        cornerstoneTools.external.cornerstone = cornerstone;
        cornerstoneTools.external.Hammer = Hammer;
        cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
        cornerstoneTools.init({
            mouseEnabled: true,
            touchEnabled: true,
            globalToolSyncEnabled: true,
            showSVGCursors: true

        });

        cornerstoneTools.loadHandlerManager.setErrorLoadingHandler(
            (element, imageId, error) => {
                console.error(imageId);
                // throw error;
            }
        );

        const elem = this.viewerTarget;
        this.viewerElement = elem;
        cornerstone.enable(elem);       
        this.addCornerstoneTools();
        this.imageLoader(this.url);
        const img = this.data.get("representableImg");
        if (img !== null && JSON.parse(img)[0]["show_image_path"] == "false") { 
            const converted_array = JSON.parse(img)
            document.getElementById('showPath').innerHTML = converted_array[0]["complete_path"];
            document.getElementById('showFileFullName').innerHTML = converted_array[0]["filename"]
            document.getElementById('showAVD').style.display = 'block';
            const allImageBoxes = document.querySelectorAll('.image-box');
            allImageBoxes[0].classList.add('border-primary-500');
            document.getElementById('hideImg').style.display = 'block';
            if (document.getElementById('hideImg') !== null) {
                document.getElementById('hideImg').style.display = 'none';
            }  
        }
    }

    imageLoader(imageUrl){

        imageUrl = document.location.origin + '/' + imageUrl;
        this.imageTag.innerHTML = "";
        if(imageUrl){
          
            let imageIds = [];
            let imageIdRoot = 'wadouri:' + imageUrl;
            let stack = {};
            // http:
            if(imageUrl.includes('.dcm')){
                
                cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.load(imageUrl, cornerstoneWADOImageLoader.internal.xhrRequest).then((dataSet) => {
                    // dataset is now loaded, get the # of frames so we can build the array of imageIds
                    //(0028,0008)	IS	Number of Frames is the tag that defines the number of frames as per dicomliberary which means this will not change
                
                    let numFrames = dataSet.intString('x00280008');     
                    
                    this.imageTagList.forEach(t => {
                        if (dataSet.intString(t.hex)) {
                            this.imageTag.innerHTML += `<div>${t.label} : ${dataSet.intString(t.hex)}</div>`;
                        }
                       
                    })
                    
                
                    if(!numFrames) {

                        imageIds.push(imageIdRoot);
                        
                    } else {
                    
                        for(var i=0; i < numFrames; i++) { 
                            imageIds.push(imageIdRoot + "?frame="+i);
                        }
                    
                    
                    } 
    
                    stack = {
                    currentImageIdIndex : 0,
                    imageIds: imageIds
                    };
                    
                    if(imageIds.length > 2){
                    
                        cornerstone.loadAndCacheImage(imageIds[0]).then((image) => {
                        
                            // now that we have an image frame in the cornerstone cache, we can decrement
                            // the reference count added by load() above when we loaded the metadata.  This way
                            // cornerstone will free all memory once all imageId's are removed from the cache

                            cornerstone.displayImage(this.viewerElement, image);  
                            cornerstoneTools.addStackStateManager(this.viewerElement, ["stack"]);
                            cornerstoneTools.addToolState(this.viewerElement, "stack", stack); 

                            
                            cornerstoneTools.addTool(StackScrollMouseWheelTool);
                            cornerstoneTools.setToolActive('StackScrollMouseWheel', {});
                            
                        }, function(err) {
                            console.log('loadAndCacheImage error', err );
                        });
                    } 
                    else {
                    
                    cornerstone.loadAndCacheImage(imageUrl).then((image) => {
                        
                        this.addCornerstoneToolsAndAnnotations(imageUrl);
                        
                    }, function(err) {
                        console.log('loadImage error', err );
                    });
                    }
              
                });

            }
            else{
                
                cornerstone.loadAndCacheImage(imageUrl).then(image => {
                    cornerstone.displayImage(this.viewerElement, image); 
                });
              
            }          
          
        }
    }

    get url() {
        return this.data.get('url');
    }

    changeImage(e, elem) {
        if (e.target.classList.contains('showImg')){
            document.getElementById('showAVD').style.display = 'none';
            document.getElementById('hideImg').style.display = 'block'; 
        }
        let target;
        if (e) target = e.target;
        else target = elem;
        // load new image
        this.imageLoader(target.dataset.url);

        // if the call came from click adjust the currentImage index
        if (e) {
            this.currentImage = target.dataset.idx;
            console.log(`current index seleted ${this.currentImage}`);
         }
        // remove all active borders
        const allImageBoxes = document.querySelectorAll('.image-box');
        allImageBoxes.forEach(i => i.classList.remove('border-primary-500'));

        // active border around current image
        target.parentNode.classList.add('border-primary-500');
    }

    nextImage(e) {
        console.log(`current index: ${this.currentImage}`);
        const len = this.images.length;
        this.currentImage = Math.abs((this.currentImage + 1) % len);
        console.log(` after: ${this.currentImage}`);
        this.changeImage(null, this.images[this.currentImage].elem);
        e.preventDefault();
    }

    previousImage(e) {
        console.log(`current index: ${this.currentImage}`);
        const len = this.images.length;
        this.currentImage = Math.abs((this.currentImage + len - 1)) % len;
        console.log(` after: ${this.currentImage}`);
        this.changeImage(null, this.images[this.currentImage].elem);
        e.preventDefault();
    }

    readImages() {
        const images = document.querySelectorAll('.image');
        images.forEach(i => {
            this.images.push({
                id: i.getAttribute('data-id'),
                url: i.getAttribute('data-url'),
                elem: i
            });
        });
        // console.log(this.images);
    }

    toggleTags(e){
        this.imageTag.classList.toggle("hidden");
        e.preventDefault();
    }

    addCornerstoneTools(){
        
        //this.loadExistingAnnotationsForImage(imageUrl);

        // Activate Pan Tool on Middle Click Drag
        cornerstoneTools.addTool(PanTool);
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: MIDDLE_CLICK });

        // Activate WWWc tool on Left Click Drag
        cornerstoneTools.addTool(WwwcTool);
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });
        

        // Activate Zoom Mouse Wheel Tool
        cornerstoneTools.addTool(ZoomMouseWheelTool, {
            // Optional configuration
            configuration: {
                invert: false,
                preventZoomOutsideImage: false,
                minScale: 0.01,
                maxScale: 20.0
            }
        });
        cornerstoneTools.setToolActive('ZoomMouseWheel', {});

        
    }

    levelsTool(e) {
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    panTool(e) {
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }
    acceptImages(event){
        if (check_modality_value.innerHTML.trim().length > 0) {
            let show_form = document.getElementById("show_certification_form");
            if(show_form.style.display === "none"){
                show_form.style.display = "block";
            }
            document.getElementById("show_rejected_form").style.display = "none";
            document.getElementById("accept_images").style.display = "none";
        }
        else {
            Swal.fire({
                icon: 'warning',
                title: 'Please Assign Child Modality First and then Generate Certificate.',
            });
        }
        event.preventDefault();
    }

    rejectImages(event){
      let reject_form = document.getElementById("show_rejected_form")
      if(reject_form.style.display === "none"){
          reject_form.style.display = "block";
      }
      document.getElementById("show_certification_form").style.display = "none";
      document.getElementById("accept_images").style.display = "none";
      event.preventDefault();
    }
    closeForm(event){
        document.getElementById("show_certification_form").style.display = "none";
        document.getElementById("accept_images").style.display = "block";
        event.preventDefault();
    }
    closeRejctedForm(event){
        document.getElementById("show_rejected_form").style.display = "none";
        document.getElementById("accept_images").style.display = "block";
        event.preventDefault();
    }
    show_set_images(event){
      event.preventDefault();
      const selectChangeUrl = document.location.origin + `/show_other_set_images.json`;
      fetch(selectChangeUrl, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ property_set_image_id: event.currentTarget.id }),
        method: 'POST',
      })
      .then(response => response.json())
            .then((data) => {
              document.getElementById('lower_preview').innerHTML = data.html;
              document.getElementById(data.property_set_image_id).style.backgroundColor = 'blue';                
      });
    }

    show_set_images_photographer(event){
        event.preventDefault();
        const selectChangeUrl = document.location.origin + `/show_other_set_images_photographer.json`;
        fetch(selectChangeUrl, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
              'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({ property_set_image_id: event.currentTarget.id }),
          method: 'POST',
        })
        .then(response => response.json())
              .then((data) => {
                document.getElementById('lower_preview').innerHTML = data.html;
                document.getElementById(data.property_set_image_id).style.backgroundColor = 'blue';                
        });
      }
      showAVDPath(event){ 
        event.preventDefault();
        const selectChangeUrl = document.location.origin + `/fetch_path_on_avd.json`;
        fetch(selectChangeUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ object: event.currentTarget.dataset }),
            method: 'POST',
        })
        .then(response => response.json())
                .then((data) => {
                document.getElementById('showPath').innerHTML = data['html'];
                document.getElementById('showFileFullName').innerHTML = data['path'];
                document.getElementById('showAVD').style.display = 'block';
                const allImageBoxes = document.querySelectorAll('.image-box');
                allImageBoxes.forEach(i => i.classList.remove('border-primary-500'));
                event.target.parentNode.classList.add('border-primary-500');
                if (document.getElementById('hideImg') !== null) {
                    document.getElementById('hideImg').style.display = 'none';
                }                
        });
        }
}
