import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import { Dropdown, Modal, Tabs,Alert } from 'tailwindcss-stimulus-components';

import Flatpickr from 'stimulus-flatpickr';
import Notification from 'stimulus-notification';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
// Import your controller
import ChatMenuController from './chat_menu_controller';

application.register('chat-menu', ChatMenuController);
application.load(definitionsFromContext(context));
application.register('dropdown', Dropdown);
application.register('modal', Modal);
application.register('tabs', Tabs); 
application.register('alert', Alert); 
application.register('flatpickr', Flatpickr);
application.register('notification', Notification);
