import consumer from "./consumer"
consumer.subscriptions.create("ChatroomChannel", {
  connected() {
    console.log("Channel Is Connected")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const logged_in = document.getElementById('logged_in_user')
    const logged_in_user = logged_in.innerText.trim(); 
    if (data.reply) {
      const chatContainer = document.getElementById('chat-container');
      // const messageContainer = document.getElementById('message-container');

      // Create a new chat messages container
      const messageDiv = document.createElement('div');  
      messageDiv.className = 'pt-2 px-3 inline-block mb-2 clearfix';
      messageDiv.style.maxWidth = '60%';  
      // create a username element
      const usernameP = document.createElement('p');
      usernameP.style.color = 'black';
      usernameP.style.fontSize = '10px';
      usernameP.innerHTML = data.user_name;   
      // create a reply element
      const newReplyElement = document.createElement('div');
      newReplyElement.classList.add('h-auto','text-sm', 'font-normal', 'rounded' ,'px-3', 'py-1');
      newReplyElement.style.fontSize = '16px';   
      newReplyElement.style.borderRadius = '17px';
      newReplyElement.setAttribute('title', data.created_ago + ' ago');
      newReplyElement.innerHTML = `${data.reply}<br>`;   
      // checking conditions for both current user and the receiver end
      if(logged_in_user == data.submitted_user){
        messageDiv.style.float = 'right';
        newReplyElement.style.backgroundColor = '#007bff';
      newReplyElement.classList.add('text-white');

      }else{
        messageDiv.style.float = 'left';
        newReplyElement.style.backgroundColor = '#eeeeee';
        newReplyElement.classList.add('text-black');
        messageDiv.appendChild(usernameP);
      }
      // create a time element 
      const time = document.createElement('span');
      time.style.color = 'black';
      time.style.fontSize = '8px';
      time.style.float = 'right';
      time.innerHTML = data.created_ago + ' ago'
      // appending answer on frontend
      messageDiv.appendChild(newReplyElement);
      messageDiv.appendChild(time);
      chatContainer.appendChild(messageDiv);

      // create a clearfix element for smooth work
      const clearfixDiv = document.createElement('div');
      clearfixDiv.className = 'clearfix';
      chatContainer.appendChild(clearfixDiv);
    }
   
  }
});
