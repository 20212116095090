import { Controller } from "stimulus";
import Swal from 'sweetalert2';

export default class extends Controller {
    static targets = ["parent", "child"];
    initialize() {
        this.studyId = this.data.get("studyId");
        const childExists = this.childTargets.length > 0;
        if (!childExists) {
            this.parentTarget.disabled = true;
        }
    }
    connect() {
        this.modal = document.getElementById("popup-modal");
    }

    toggleChildren() {
        if (this.parentTarget.checked) {
            this.childTargets.forEach(child => {
                child.checked = true;
            });
        } else {
            this.childTargets.forEach(child => {
                child.checked = false;
            });
        }
    }
    assignSubjects() {
        const selectedChildCheckboxes = this.childTargets.filter(child => child.checked);
        const selectAllCheckbox = this.parentTarget;
        const selectAllChecked = selectAllCheckbox.checked;
        let selectedValues = [];
        if (selectAllChecked) {
            selectedValues = [selectedChildCheckboxes.map(child => child.value)];
            this.modal.classList.remove("hidden");
        } else if (selectedChildCheckboxes.length > 0) {
            selectedValues = selectedChildCheckboxes.map(child => child.value);
            this.modal.classList.remove("hidden");
        } else {
            alert("No subject selected");
            return;
        }
    }
    assignVisits(event) {
        event.preventDefault();
        const selectedChildCheckboxes = this.childTargets.filter(child => child.checked);
        const selectedValues = selectedChildCheckboxes.map(child => child.value);
        const selectedUserSelect = document.querySelector('#select_user');
        const selectedUserIds = Array.from(selectedUserSelect.selectedOptions).map(option => option.value);
        const dueDate = document.querySelector('#due_date').value;
        if ( selectedUserIds.length === 0 || !dueDate) {
            alert("Please fill in all required fields.");
            return;
        }
        const data = {
            selected_values: selectedValues,
            form_type: "quality_controller",
            selected_user_ids: selectedUserIds,
            due_date: dueDate,
            study_id: this.studyId,
            state_text: "assigned"
        };
        this.sendDataToServer(data);
    }
    async sendDataToServer(data) {
        const url = `${document.location.origin}/studies/${this.studyId}/assignworks/assign_visit`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                this.modal.classList.add("hidden");
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Visits assigned successfully.',
                });
                window.location.reload();
            } else {
                // Handle error
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    close() {
        this.modal.classList.add("hidden");
        let select_user = document.getElementById('select_user');
        let due_date = document.getElementById('due_date');
        let form_type = document.getElementById('form_type');
        form_type.value = '';
        due_date.value = '';
        select_user.value = '';
    }
}
