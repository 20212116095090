import { Controller } from "stimulus";
import Swal from 'sweetalert2';
import $ from 'jquery';
export default class extends Controller {
    static targets = ["parent", "child"];
    initialize() {
    this.studyId = this.data.get("studyId");
    }
    connect() {
    $('.modality-search').select2();
    this.modal = document.getElementById("popup-modal");
    }

    assignSubjects() {
    this.modal.classList.remove("hidden");   
    }

    assignModalities(event) {
    event.preventDefault();
    const selectedUserSelect = document.querySelector('#select_user');
    const selectedModalitySelect = document.querySelector('#select_modality');
    const selectedUserIds = Array.from(selectedUserSelect.selectedOptions).map(option => option.value);
    const selectedValues = Array.from(selectedModalitySelect.selectedOptions).map(option => option.value);
    if ( selectedUserIds.length === 0 || selectedValues.length === 0) {
        alert("Please fill in all required fields.");
        return;
    }
    const data = {
        selected_values: selectedValues,
        form_type: "grader",
        selected_user_ids: selectedUserIds,
        study_id: this.studyId,
        state_text: "assigned"
    };
    this.sendDataToServer(data);
    }

    async sendDataToServer(data) {
        const url = `${document.location.origin}/studies/${this.studyId}/assignworks/modalities_assigned`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                this.modal.classList.add("hidden");
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Modalities assigned successfully.',
                });
                window.location.reload();
            } else {
                // Handle error
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    close() {
        this.modal.classList.add("hidden");
        let select_user = document.getElementById('select_user');
        select_user.value = '';
    }
}