// app/javascript/controllers/menu_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
    initialize() {
        this.studyId = this.data.get("studyId");
    }

    deleteRecord(event) {
        const row = event.srcElement.closest("tr")
        const recordId = event.currentTarget.dataset.recordId;
        const url = `${document.location.origin}/studies/${this.studyId}/worklists/delete_record/${recordId}`;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        Swal.fire({
            icon: 'warning',
            title: 'Irrelevant Visit',
            text: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(url, {
                    method: "DELETE",
                    headers: {
                        "X-CSRF-Token": csrfToken,
                    },
                })
                    .then((response) => {
                        if (response.ok) {
                            row.remove();
                            Swal.fire(
                                'Reverted!',
                                'Your visit has been reverted.',
                                'success'
                            )
                        } else {
                            console.error("Failed to delete the record.");
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });

            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });

    }
}
