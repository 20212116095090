import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['partialButton'];
    checkNumbers(event) {
      const value = parseInt(event.target.value);
      const partialButton = this.partialButtonTarget;
      const elements = document.getElementsByClassName('all-btns');
      for (let i = 0; i < elements.length; i++) {
        elements[i].click();
      }
      for (let i = 1; i <= value; i++) {
        partialButton.click();
      }
    }
    submitForm() { 
      const dateElements = document.querySelectorAll('.all-sets-dates');
      const dateValues = Array.from(dateElements).map(element => element.value);
      const pathologyElements = document.querySelectorAll('.all-sets-pathology');
      const pathologyValues = Array.from(pathologyElements).map(element => element.value);
      if (!dateValues.includes('') && !pathologyValues.includes('')) {
        document.getElementById("loader").classList.add("visible");
      }
  }
}
