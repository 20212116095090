import { Controller } from 'stimulus';
import $ from 'jquery';
require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller {
    static targets = ['usersList', 'rolesList'];
    async initialize() {
        // this.appendList();
    }
    connect() {
        $('.visit-search').select2();
    }

    appendList() {
        let assign_to = document.querySelector('input[type=radio][name="query[assign_to]"]:checked').value;
        if (assign_to === 'users') {
            document.getElementById('query_user_ids').disabled = false;
            document.getElementById('query_roles').disabled = true;
        } else if (assign_to === 'role') {
            document.getElementById('query_roles').disabled = false;
            document.getElementById('query_user_ids').disabled = true;
        } else {
            document.getElementById('query_user_ids').disabled = false;
            document.getElementById('query_roles').disabled = false;
        }
    }

}