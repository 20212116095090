import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['chatMenu'];

  toggle() {
    this.chatMenuTarget.classList.remove('hidden');
  }
  
  hide(event) {
    if (!this.element.contains(event.target)) {
      this.chatMenuTarget.classList.add('hidden');
    }
  }
}
