import { Controller } from 'stimulus';
import { Formio } from 'formiojs';

/**
 * API Docs for Form.io
 * https://github.com/formio/formio.js/wiki
 *
 * Full API Docs
 * https://formio.github.io/formio.js/docs/
 *
 * Examples:
 * https://formio.github.io/formio.js/app/examples/
 *
 */

import Hammer from 'hammerjs';
import * as cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import ProbeTool from '../src/Probe';
import ArrowAnnotateTool from '../src/ArrowAnnotateTool';
import ProbeStatic from '../src/ProbeStatic';

import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';

const {
    PanTool,
    WwwcTool,
    ZoomMouseWheelTool,
    EllipticalRoiTool,
    EraserTool,
    LengthTool
} = cornerstoneTools;

//cornerstoneTools.ProbeTool = ProbeTool;
//cornerstoneTools.ArrowAnnotateTool = ArrowAnnotateTool;

const LEFT_CLICK = 1;
const MIDDLE_CLICK = 4;
// const RIGHT_CLICK = 2;

export default class extends Controller {
    static targets = ['viewer', 'url', 'form', 'path', 'agreement', 'annotationEditor', 'questionArea', 'submit', 'update', 'cornerstoneIcon', 'cornerstone', 'formSection', 'submitGradingbutton', 'annotationTools']

    async initialize() {
        // Define Targets
        const elem = this.viewerTarget;
        this.gradingFormElem = this.formTarget;
        this.gradingFormAgreementElem = this.agreementTarget;
        this.cornerstoneImages = this.cornerstoneTarget;
        this.formSection = this.formSectionTarget;
        this.viewerElement = elem;
        this.gradingSaveUrl = document.location.origin + this.data.get('updatePath') + '.json';
        this.rejectImageUrl = document.location.origin + this.data.get('rejectImagePath') + '.json';
        this.acceptImageUrl = document.location.origin + this.data.get('acceptImagePath') + '.json';
        this.updateImageValueUrl = document.location.origin + this.data.get('updateValuePath') + '.json';
        this.images = [];
        this.readImages();
        this.currentImage = 0;
        this.gradingFormVersionId = this.data.get('versionId');
        // load previously saved grading data if available
        this.annotation = JSON.parse(this.data.get('annotation'));
        this.annotationIsLoadedOnce = {};
        this.iolImageId = this.data.get('iolImageId');
        this.gradingIolFormUserId = this.data.get('userId');
        this.gradingIolFormStackId = this.data.get('stackId');
        this.isManuallyRegistered = this.data.get('manualRegistered');
        this.isRegistered = this.data.get('isRegistered');
        this.requestImageObj = JSON.parse(this.data.get('requestImage'));
        this.apiUrl = `${this.data.get("apiUrl")}iol/concatenate-images`;
        this.manualRegisterUrl = `${this.data.get("apiUrl")}iol/manual-register`;
        this.submitGradingToMLUrl = `${this.data.get("apiUrl")}iol/submit-grading`;
        this.apiKey = this.data.get("apiKey");
        this.imageValues = JSON.parse(this.data.get("value"));
        const imageKeys = this.data.get('imageKeys');
        if (imageKeys && imageKeys.trim() !== '[]') {
            // Split the string by commas and remove any leading/trailing spaces, then filter out empty strings
            this.gradingImages = imageKeys.split(',').map(key => key.trim()).filter(Boolean);
        } else {
            // If imageKeys is not provided or is empty/contains only [], set this.gradingImages to an empty array
            this.gradingImages = [];
        }
        this.imageMeta = {
            width: 0, height: 0
        }
        this.elementEnabled = false;
        this.selectedTool = '';


        /**
         * Grading Form Setup
         */

        this.gradingFormSchema = JSON.parse(this.data.get('gradingForm'));
        this.gradingFormResponses = JSON.parse(this.data.get('responses'));
        this.gradingFormSaveUrl = document.location.origin + this.data.get('formUpdatePath') + '.json';

        // renderer
        this.gradingForm = await Formio.createForm(this.gradingFormElem, this.gradingFormSchema, {
            noAlerts: true
        });

        if (Object.keys(this.imageValues).length) {
            this.setImageValueOnForm();
        }

        // set the draft responses if any
        this.gradingForm.submission = this.gradingFormResponses;
        this.formSaveDraftStatus = false;
        // attach event listeners to the form io
        this.gradingForm.on('submit', submission => {
            this.saveGradingForm(submission);
        });

        if (this.gradingFormAgreementElem.checked) {
            this.makeFormDisabled(this.gradingForm);
        } else {
            this.makeFormEnable(this.gradingForm);
        }

        // to show dropzones area
        this.element.addEventListener("successFileUpload", this.handleFileUploadEvent.bind(this));
        this.element.addEventListener("removeFile", this.handleRemoveFileEvent.bind(this));

        const displayImagesForQuestions = (formComponents, gradingFormResponses, ImagesArray) => {
            // Split the string into an array of URLs using a comma as the separator
            const imagesArray = ImagesArray.split('<==>').map(url => url.trim());
            // Iterate through the form components
            FormioUtils.eachComponent(formComponents, component => {
                // Check if the component key is in gradingFormResponses
                if (component.key in gradingFormResponses.data) {
                    const questionKey = component.key;
                    const verified_key = questionKey + "_sdv_verified";
                    const comments_key = questionKey + "_sdv_comments";

                    if (gradingFormResponses.sdv) {
                        if (verified_key in gradingFormResponses.sdv) {
                            const check_sdv = gradingFormResponses.sdv[verified_key];
                            const sdv_comments = gradingFormResponses.sdv[comments_key];
                            this.appendSdvWithQuestionTitles(component, check_sdv, sdv_comments);
                        }
                    }
                    if (gradingFormResponses && gradingFormResponses.files && questionKey in gradingFormResponses.files) {
                        const imageUrls = gradingFormResponses.files[questionKey];
                        this.appendImageWithQuestion(component, imageUrls, imagesArray)
                    }
                }
            });
        }
        // Call the function with your data
        Formio.createForm(this.gradingFormElem, 'https://examples.form.io/example').then((form) => {
            this.gradingForm.on("render", () => {
                if (Object.keys(this.gradingFormResponses).length > 0) {
                    displayImagesForQuestions(this.gradingForm.components, this.gradingFormResponses, this.data.get('attachments'));
                }
            });
            // uncomment the line below to rerender
            this.gradingForm.redraw();
            if (this.gradingForm.disabled) {
                const dropzoneElements = document.getElementsByClassName('dropzone');
                for (const element of dropzoneElements) {
                    element.classList.add('hidden');
                }
            }
        });
        /**
         * 
         *
         * Corner Stone Setup
         */
        cornerstoneWebImageLoader.external.cornerstone = cornerstone;
        cornerstoneTools.external.cornerstone = cornerstone;
        cornerstoneTools.external.Hammer = Hammer;
        cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
        cornerstoneTools.init({
            mouseEnabled: true,
            touchEnabled: true,
            globalToolSyncEnabled: true,
            showSVGCursors: true

        });

        cornerstoneTools.loadHandlerManager.setErrorLoadingHandler(
            (element, imageId, error) => {
                console.error(imageId);
                // throw error;
            }
        );

        const imageUrl = /** document.location.origin + '/' +**/ this.url;
        cornerstone.enable(elem);


        /**
         *
         * Cornerstone ProbeLabel Setup
        */
        this.measurement = [];
        this.activeMeasurement = "";
        this.viewPosition = { x: 0, y: 0 };
        this.annotationEditorVisible = this.annotationEditorTarget;

        // cornerstone.registerImageLoader = 'dicomweb';
        cornerstone.loadAndCacheImage(imageUrl).then(image => {
            this.loadExistingAnnotationsForImage(imageUrl);

            cornerstone.displayImage(elem, image);
            this.imageMeta = {
                width: image.width, height: image.height
            }
            this.elementEnabled = true;

            // Activate Pan Tool on Middle Click Drag
            cornerstoneTools.addTool(PanTool);
            cornerstoneTools.setToolActive('Pan', { mouseButtonMask: MIDDLE_CLICK });

            // Activate WWWc tool on Left Click Drag
            cornerstoneTools.addTool(WwwcTool);
            cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });
            cornerstoneTools.addTool(EraserTool);

            // Activate Zoom Mouse Wheel Tool
            cornerstoneTools.addTool(ZoomMouseWheelTool, {
                // Optional configuration
                configuration: {
                    invert: false,
                    preventZoomOutsideImage: false,
                    minScale: 0.01,
                    maxScale: 20.0
                }
            });
            cornerstoneTools.setToolActive('ZoomMouseWheel', {});

            // Add Non Active tools

            cornerstoneTools.addTool(ProbeTool);

            cornerstoneTools.addTool(EllipticalRoiTool, {
                configuration: {
                    drawHandlesOnHover: false,
                    hideHandlesIfMoving: true,
                    renderDashed: false
                }
            });
            cornerstoneTools.addTool(LengthTool);
            cornerstoneTools.addTool(ArrowAnnotateTool);
            cornerstoneTools.addTool(ProbeStatic);

            // register cornerstone tools events
            // https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/events.js
            elem.addEventListener('cornerstonetoolsmeasurementcompleted', e => {
                console.log(`------${e.detail.toolName}-----`);
                console.table(e.detail.measurementData);
            });

            elem.addEventListener("cornerstonetoolsmeasurementadded", (e) => {
                // On new measurement added
                if (e.detail.toolName !== "Probe") { return; }

                // If new measurement added is a Probe, we get tool data and add to state
                this.measurement = cornerstoneTools.getToolState(elem, "Probe").data

            });

            elem.addEventListener("cornerstonetoolsmousemove", (e) => {

                // On Mouse Move, if Eraser tool activated, show a circle around the mouse
                if (this.elementEnabled && this.selectedTool === "ProbeStatic") {
                    let image_x = parseInt(e.detail.currentPoints.image.x)
                    let image_y = parseInt(e.detail.currentPoints.image.y)


                    if (image_y > 0 && image_y < this.imageMeta.height && image_x > 0 && image_x < this.imageMeta.width) {

                        let new_x = 0
                        var leftImageText, rightImageText;
                        var half = this.imageMeta.width / 2
                        if (image_x > half) {
                            // Mouse is on the right half of the image
                            new_x = parseInt(image_x - half)

                            rightImageText = `X: ${image_x - half} Y: ${image_y}`
                            leftImageText = `X: ${new_x} Y: ${image_y}`
                        } else {
                            // Mouse is on the left half of the image
                            new_x = parseInt(image_x + half)

                            rightImageText = `X: ${new_x - half} Y: ${image_y}`
                            leftImageText = `X: ${image_x} Y: ${image_y}`
                        }

                        var textBoxes = [
                            { // Left
                                x: 50, y: this.imageMeta.height - 50, text: leftImageText
                            },
                            { // Right
                                x: half + 50, y: this.imageMeta.height - 50, text: rightImageText
                            }
                        ]

                        this.drawProbeStatic(this.viewerElement, [parseInt(new_x), parseInt(image_y)], textBoxes);
                        cornerstone.updateImage(e.detail.element)


                    }



                }
                else {
                    cornerstoneTools.clearToolState(this.viewerElement, 'ProbeStatic')
                    cornerstone.updateImage(this.viewerElement);
                }

            });

            // elem.addEventListener("cornerstonetoolsmouseclick", (e) => {
            //     // Mouse click on image
            //     // We get image points
            //     let xImage = e.detail.currentPoints.image.x;
            //     let yImage = e.detail.currentPoints.image.y;
            //     let oldData = this.measurement;
            //     // Check if click on any measurement
            //     for (var i = 0; i < oldData.length; i++) {
            //         if (this.isInsidePoint(oldData[i].handles.end.x, oldData[i].handles.end.y, xImage, yImage)) {

            //             this.activeMeasurement = oldData[i].uuid
            //             this.annotationEditorVisible.setAttribute("style", `top: ${e.detail.currentPoints.canvas.y}px; left: ${e.detail.currentPoints.canvas.x}px`)
            //             this.annotationEditorVisible.classList.remove("hidden");
            //         }
            //     }
            // });

            this.refreshTools();
        });

        // attach event listeners
        this.viewerElement.addEventListener('cornerstonenewimage', (e) => {
            // console.log(e);
            // By resetting viewer the tooldata is not affected
            cornerstone.reset(this.viewerElement)
            e.detail.viewport.displayedArea.brhc.x = e.detail.image.width;
            e.detail.viewport.displayedArea.brhc.y = e.detail.image.height;
        });


    }

    appendSdvWithQuestionTitles(component, check_sdv, sdv_comments) {

        if (check_sdv === 'true') {
            // Create a span element for the SVG icon with the tooltip
            const svgDive = document.createElement('span');
            svgDive.classList.add('has-tooltip');
            svgDive.innerHTML = `
          <?xml version="1.0" encoding="iso-8859-1"?>
            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg fill="#007BFF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                width="16px" height="16px" viewBox="0 0 509.604 509.604"
                xml:space="preserve" style ="margin-top:3px">
                <g>
                    <g>
                        <path d="M34.262,333.282c8.119,6.75,14.793,15.223,14.143,20.988c-0.382,3.443-0.593,6.943-0.593,10.5
                            c0,52.393,41.3,94.861,92.24,94.861c6.292,0,12.431-0.65,18.37-1.885c10.002-2.074,21.812,1.941,28.888,9.793
                            c16.82,18.646,40.803,30.342,67.492,30.342c28.19,0,53.426-13.016,70.342-33.518c6.723-8.146,18.103-11.533,28.22-8.5
                            c8.166,2.447,16.811,3.768,25.751,3.768c50.939,0,92.24-42.477,92.24-94.861c0-5.861-0.535-11.59-1.549-17.145
                            c-1.712-9.371,2.85-21.047,10.471-28.363c18.025-17.289,29.328-41.883,29.328-69.242c0-29.787-13.368-56.323-34.263-73.698
                            c-8.118-6.751-14.793-15.224-14.143-20.99c0.383-3.442,0.593-6.942,0.593-10.5c0-52.393-41.301-94.86-92.24-94.86
                            c-6.292,0-12.431,0.65-18.369,1.884c-10.002,2.075-21.812-1.941-28.889-9.792c-16.82-18.647-40.803-30.342-67.492-30.342
                            c-26.688,0-50.671,11.695-67.492,30.342c-7.076,7.841-18.886,11.867-28.888,9.792c-5.938-1.234-12.078-1.884-18.37-1.884
                            c-50.939,0-92.24,42.477-92.24,94.86c0,5.049,0.392,10.002,1.147,14.832c1.262,8.128-4.447,18.149-12.747,24.681
                            C14.219,201.663,0,228.887,0,259.583C0,289.37,13.368,315.907,34.262,333.282z M131.475,263.016
                            c2.046-3.625,7.268-3.672,12.049,0.479l48.119,33.918c2.61,1.588,5.106,2.4,7.506,2.4c4.963,0,8.893-3.576,12.689-7.02
                            l153.985-154.138c9.629-10.471,18.99-14.162,25.102-10.146c2.82,1.855,4.646,4.647,5.135,7.87
                            c0.583,3.825-0.756,7.946-3.768,11.599l-185.149,224.91c-2.687,3.26-6.11,5.059-9.629,5.059c-4.179,0-7.965-2.516-10.404-6.895
                            l-54.344-97.969C130.519,269.422,130.021,265.618,131.475,263.016z"/>
                    </g>
                </g>
            </svg>     
            <span class="tooltip bottom-2 w-min">${sdv_comments}</span>
          `;
            // Get the label element within component.element
            const labelElement = component.element.querySelector('label');
            if (labelElement) {
                labelElement.classList.add('flex', 'gap-2');
                // Concatenate component.label and svgDive and set it as the innerHTML of labelElement
                labelElement.innerHTML = component.label + svgDive.outerHTML;
                // Optionally, you can also console.log the updated label
                console.log(labelElement.innerHTML);
            }
        }
    }

    appendImageWithQuestion(component, imageUrls, imagesArray) {
        const addedImages = new Set();
        // Create a div to hold the images
        const imageDiv = document.createElement('div');
        imageDiv.classList.add('mt-1', 'border', 'rounded', 'border-green-300', 'flex', 'gap-2');
        // Iterate through the image URLs and create image elements
        for (const imageUrl of imageUrls) {
            for (let i = 0; i < imagesArray.length; i++) {
                let imageUrlParts = imagesArray[i].split('/');
                let imageName = imageUrlParts[imageUrlParts.length - 1];
                // Decode the URL containing %20
                let decodedImageUrl = decodeURIComponent(imageName);
                if (imageUrl == decodedImageUrl && !addedImages.has(decodedImageUrl)) {
                    const imageElement = document.createElement('img');
                    imageElement.setAttribute('src', imagesArray[i]);
                    imageElement.setAttribute('alt', 'Attachment Image');
                    imageElement.setAttribute('width', '100');
                    imageElement.setAttribute('height', '100');
                    imageElement.style.cursor = 'pointer';
                    // Create the delete icon using an SVG image
                    const deleteIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                    deleteIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                    deleteIcon.setAttribute('width', '20');
                    deleteIcon.setAttribute('height', '20');
                    deleteIcon.setAttribute('viewBox', '0 0 24 24');
                    deleteIcon.style.cursor = 'pointer';
                    deleteIcon.setAttribute('id', 'deleteIconId');

                    if (this.gradingForm._disabled == false) {
                    deleteIcon.innerHTML = `
                        <title>Delete Attachment</title>
                        <path fill="#FF0000" d="M18 4h-4.18l-1.82-2H10L8.82 4H4v2h16V4zM6.36 8h11.28l1.23 10.96l.01.04V20H4v-1.96l.01-.04L5.64 8zM8 18h8v-2H8v2zm0-4h8v-6H8v6z"/>
                        `;
                    }
                    const stackId = this.gradingIolFormStackId;

                    deleteIcon.addEventListener('click', () => {
                        // Display a confirmation dialog
                        const confirmation = confirm('Are you sure you want to delete this image?');
                        if (confirmation) {
                            imageElement.parentElement.remove();
                            addedImages.delete(decodedImageUrl);
                            // Get the image URL associated with the clicked delete icon
                            const imageUrlToDelete = imageElement.src;
                            const fileSegments = imageUrlToDelete.split('/');
                            const fileName = fileSegments[fileSegments.length - 1];

                            // Send a DELETE request to the server to delete the image
                            fetch(`/delete_iol_image`, {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json; charset=utf-8',
                                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                                },
                                body: JSON.stringify({
                                    image_url: fileName,
                                    stack_id: stackId,
                                    image_array: component.key

                                })
                            })
                                .then(response => {
                                    if (response.ok) {
                                        // Image deleted successfully from storage
                                        console.log('Image deleted successfully');
                                        // Remove the corresponding image element from the DOM
                                        window.location.reload();

                                    } else {
                                        // Failed to delete image
                                        console.error('Failed to delete image');
                                    }
                                })
                                .catch(error => {
                                    console.error('Error:', error);
                                });
                        }
                    });

                    // Create a container div for the image and delete icon
                    const imageContainer = document.createElement('div');
                    imageContainer.classList.add('image-container');
                    imageContainer.appendChild(imageElement);
                    imageContainer.appendChild(deleteIcon);
                    imageElement.addEventListener('click', function () {
                        const lightboxImage = document.getElementById('lightbox-image');
                        lightboxImage.src = imagesArray[i];
                        const lightboxContainer = document.getElementById('lightbox-container');
                        lightboxContainer.style.display = 'block';
                    });
                    const lightboxContainer = document.getElementById('lightbox-container');
                    lightboxContainer.addEventListener('click', function () {
                        lightboxContainer.style.display = 'none';
                    });
                    // Append the image to the div
                    imageDiv.appendChild(imageContainer);
                    addedImages.add(decodedImageUrl);
                }
            }
        }
        // Append the image div to the question component's element
        if (imageDiv.innerHTML.trim() != "") {
            component.element.appendChild(imageDiv);
        }
    }

    handleFileUploadEvent(event) {

        // Extract the necessary values from the event
        const fileName = event.detail.fileName;
        const questionName = event.detail.questionName;
        // Initialize this.qcForm.submission.files if it doesn't exist
        if (!this.gradingForm.submission.files) {
            this.gradingForm.submission.files = {};
        }
        // Check if the questionName already exists in the files object
        if (!this.gradingForm.submission.files.hasOwnProperty(questionName)) {
            this.gradingForm.submission.files[questionName] = [];
        }
        // // Add the fileName to the respective questionName in the files object
        this.gradingForm.submission.files[questionName].push(fileName);
    }

    handleRemoveFileEvent(event) {

        const fileName = event.detail.fileName;
        const questionName = event.detail.questionName;

        // Check if the questionName exists in the files object
        if (this.gradingForm.submission.files && this.gradingForm.submission.files.hasOwnProperty(questionName)) {
            const fileArray = this.gradingForm.submission.files[questionName];
            const index = fileArray.indexOf(fileName);

            // Check if the fileName exists in the questionName's file array
            if (index !== -1) {
                // Remove the fileName from the file array
                fileArray.splice(index, 1);

                // If the file array is empty, remove the questionName from files object
                if (fileArray.length === 0) {
                    delete this.gradingForm.submission.files[questionName];
                }
            }
        }
    }

    setImageValueOnForm() {
        if (Object.keys(this.gradingFormResponses).length) {
            this.gradingFormResponses.data.direction = this.imageValues.direction;
            this.gradingFormResponses.data.angle = this.imageValues.angle_small_v1_v2;
            this.gradingFormResponses.data.distanceSmall = this.imageValues.distance_small_v1_v2;
            this.gradingFormResponses.data.distanceV1 = this.imageValues.distance_small_large_v1;
            this.gradingFormResponses.data.distanceV2 = this.imageValues.distance_small_large_v2;
            this.gradingFormResponses.data.lensRotation = this.imageValues.lens_rotation_degress;
        } else {
            this.gradingFormResponses = {
                "data": {
                    "direction": this.imageValues.direction,
                    "angle": this.imageValues.angle_small_v1_v2,
                    "distanceSmall": this.imageValues.distance_small_v1_v2,
                    "distanceV1": this.imageValues.distance_small_large_v1,
                    "distanceV2": this.imageValues.distance_small_large_v2,
                    "lensRotation": this.imageValues.lens_rotation_degress
                }
            }
        }

    }



    isInsidePoint(cx, cy, px, py) {
        var dx = px - cx;
        var dy = py - cy;
        return (dx * dx + dy * dy) < 6 * 6;
    }

    closeAnnotationEditor(e) {
        this.annotationEditorVisible.classList.add("hidden");
    }

    onChangeAnnotionLabel(e) {
        // Get measurments data
        let oldData = this.measurement

        for (var i = 0; i < oldData.length; i++) {
            if (oldData[i].uuid === this.activeMeasurement) {
                if (e.target.name === 'calibration') {
                    oldData[i].calibration = e.target.value;
                } else {
                    oldData[i].label = e.target.value;
                }

            }
        }
        // Redraw to view changes
        cornerstone.draw(cornerstone.getEnabledElements()[0].element)
        this.measurement = oldData;
        this.annotationEditorVisible.classList.add("hidden")
    }

    loadExistingAnnotationsForImage(url) {
        if (this.annotation[url] && !this.annotationIsLoadedOnce[url]) {
            cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(url, this.annotation[url]);
            this.annotationIsLoadedOnce[url] = true;
            this.refreshTools();
        }
    }

    refreshTools() {
        const tools = ['Length', 'EllipticalRoi', 'Probe', 'ArrowAnnotate', 'ProbeStatic'];
        tools.forEach(tool => {
            cornerstoneTools.setToolActive(tool, { mouseButtonMask: LEFT_CLICK });
        });
    }

    readImages() {
        const images = document.querySelectorAll('.image');
        images.forEach(i => {
            this.images.push({
                id: i.getAttribute('data-id'),
                url: i.getAttribute('data-url'),
                elem: i
            });
        });
        // console.log(this.images);
    }

    loadImage(url, id) {
        url = document.location.origin + url;
        cornerstone.loadAndCacheImage(url).then((image) => {
            this.loadExistingAnnotationsForImage(url);
            cornerstone.displayImage(this.viewerElement, image);
            cornerstone.enable(this.viewerElement);
        });
    }

    changeImage(e, elem) {
        let target;
        if (e) target = e.target;
        else target = elem;
        // load new image
        this.loadImage(target.dataset.url, target.dataset.id);

        // if the call came from click adjust the currentImage index
        if (e) {
            this.currentImage = target.dataset.idx;
        }
        // remove all active borders
        const allImageBoxes = document.querySelectorAll('.image-box');
        allImageBoxes.forEach(i => i.classList.remove('border-primary-500'));

        // active border around current image
        target.parentNode.classList.add('border-primary-500');
    }

    nextImage(e) {
        console.log(`current index: ${this.currentImage}`);
        const len = this.images.length;
        this.currentImage = Math.abs((this.currentImage + 1) % len);
        console.log(` after: ${this.currentImage}`);
        this.changeImage(null, this.images[this.currentImage].elem);
        e.preventDefault();
    }

    previousImage(e) {
        console.log(`current index: ${this.currentImage}`);
        const len = this.images.length;
        this.currentImage = Math.abs((this.currentImage + len - 1)) % len;
        console.log(` after: ${this.currentImage}`);
        this.changeImage(null, this.images[this.currentImage].elem);
        e.preventDefault();
    }

    probeTool(e) {
        this.setSelectedTool('Probe');
        cornerstoneTools.setToolActive('Probe', { mouseButtonMask: 1, disableTextBox: true });
        e.preventDefault();
    }

    contrastTool(e) {

        // Activate WWWc tool on Left Click Drag
        cornerstoneTools.addTool(WwwcTool);
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    probeStaticTool(e) {
        this.setSelectedTool('ProbeStatic');
        cornerstoneTools.setToolActive('ProbeStatic', { mouseButtonMask: 1 });
        e.preventDefault();
    }


    ellipseTool(e) {
        this.setSelectedTool('EllipticalRoi');
        cornerstoneTools.setToolActive('EllipticalRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    arrowAnnotateTool(e) {
        this.setSelectedTool('ArrowAnnotate');
        cornerstoneTools.setToolActive('ArrowAnnotate', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    eraserTool(e) {
        this.setSelectedTool('Eraser');
        cornerstoneTools.setToolActive('Eraser', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    lengthTool(e) {
        this.setSelectedTool('Length');
        cornerstoneTools.setToolActive('Length', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    setSelectedTool(toolName) {
        this.selectedTool = toolName;
    }

    drawProbeStatic(ref, points, textBoxes) {

        cornerstoneTools.clearToolState(ref, 'ProbeStatic')
        cornerstoneTools.addToolState(ref, 'ProbeStatic',
            {
                "visible": true,
                "handles": {
                    "end": {
                        "x": points[0],
                        "y": points[1],
                    },
                    textBoxes: textBoxes
                },
                "uuid": "c77922bd-0e12-4591-bab4-7a00d9d37a6b",
            }

        )
        cornerstone.drawImage(ref);
    }

    submitGrading(e) {
        const tools = ['EllipticalRoi', 'Probe', 'ArrowAnnotate'];
        // const annotations = {};

        // https://groups.google.com/g/cornerstone-platform/c/QWHTGgkLufo
        // https:// github.com/cornerstonejs/cornerstoneTools/blob/3925399f72e69f69a4b108be10bfce115eda3247/src/stateManagement/imageIdSpecificStateManager.js
        const annotations = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();

        this.saveGrading({ ...annotations, ...this.annotation });
        e.preventDefault();
    }

    saveGrading(annotations) {
        const method = this.data.get('mode') == "update"
            ? "PATCH" : "POST";
        fetch(this.gradingSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: method,
            body: JSON.stringify({
                grading: {
                    comment: 'Update',
                    annotations: annotations,
                    user_id: this.gradingIolFormUserId,
                    stack_id: this.gradingIolFormStackId
                }
            })
        }).then(response => {
            console.log(response);
        });
    }

    manualRegisteration() {

        const annotations = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
        let annotationsData = (annotations) ? annotations[Object.keys(annotations)[0]] : null

        if (annotationsData) {
            let probeData = annotationsData.Probe
            console.log("annotation: ", annotationsData)
            if (probeData && probeData.data.length == 8) {
                fetch(this.manualRegisterUrl, {
                    headers: {
                        'X-API-KEY': this.apiKey,
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        iol_image_id: this.iolImageId,
                        request: this.requestImageObj,
                        annotations: annotationsData
                    })
                }).then(response => {
                    (response.status == 200) ? this.successAlert() : this.errorAlert();
                    (response.status == 200) ? window.location.replace(`${document.location.origin}${this.iolImageListingUrl}`) : '';
                    console.log(response);


                });
                console.log('manual register payload :', JSON.stringify({
                    iol_image_id: this.iolImageId,
                    request: this.requestImageObj,
                    annotations: annotationsData
                }))

            } else {
                alert("8 points must be marked on the image")
            }

        }

    }

    submitGradingToML() {
        const annotations = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
        let annotationsData = (annotations) ? annotations[Object.keys(annotations)[0]] : null
        this.saveGrading({ ...annotations, ...this.annotation });
        if (annotationsData) {
            let probeData = annotationsData.Probe;
            let ArrowAnnotateData = annotationsData.ArrowAnnotate;
            if (this.isRegistered) {

                if (probeData && probeData.data.length == 8 && ArrowAnnotateData && ArrowAnnotateData.data.length == 1) {


                    fetch(this.submitGradingToMLUrl, {
                        headers: {
                            'X-API-KEY': this.apiKey,
                            'Content-Type': 'application/json; charset=utf-8'
                        },
                        method: 'POST',
                        body: JSON.stringify({
                            iol_image_id: this.iolImageId,
                            annotations: annotationsData,
                            image_url: this.url,
                            user_id: this.gradingIolFormUserId,
                            stack_id: this.gradingIolFormStackId
                        })
                    }).then(response => {

                        (response.status == 200) ? this.successAlert() : this.errorAlert();
                        return response.json();
                    }).then(data => {
                        console.log("data :", data)
                        this.updateIolImageValue(data.data);
                        this.formSaveDraft();
                    });

                } else {
                    alert("8 points and 1 scaling must be marked on the image")
                }
            }
            else {
                alert("Image is not registered yet");
            }
        }
    }

    updateIolImageValue(value) {
        fetch(this.updateImageValueUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({ value: value })
        }).then(response => {
            (response.status == 200) ? this.successAlert() : this.errorAlert();
            location.reload();
            console.log(response);
        });
    }

    rejectImage() {
        // set property of iol image's manually registered to true 
        fetch(this.rejectImageUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({ id: this.iolImageId })
        }).then(response => {
            console.log(response);

        });

        // send request for image concatenation
        fetch(this.apiUrl, {
            headers: {
                'X-API-KEY': this.apiKey,
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: 'POST',
            body: JSON.stringify({
                iol_image_id: this.iolImageId,
                request: this.requestImageObj
            })
        }).then(response => {
            console.log("response:", response)
            location.reload();
        });
    }

    acceptImage() {
        fetch(this.acceptImageUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({ id: this.iolImageId })
        }).then(response => {
            console.log(response);
            location.reload();
        });
    }

    get url() {
        return this.data.get('url');
    }

    get iolImageListingUrl() {
        return this.data.get('iolListingUrl');
    }

    successAlert() {
        window.dispatchEvent(new CustomEvent('successAlert'));
    }

    errorAlert() {
        window.dispatchEvent(new CustomEvent('errorAlert'));
    }

    // formio functions
    formSaveDraft(e) {
        if (this.gradingForm.submission) this.gradingForm.submission.isAgreed = false;
        this.formSaveDraftStatus = true;
        this.gradingForm.submit();
        e.preventDefault();
    }

    formCommit(e) {
        const isAgreed = this.gradingFormAgreementElem.checked;
        if (isAgreed) {
            if (this.gradingForm.submission) this.gradingForm.submission.isAgreed = isAgreed;
            this.gradingForm.submit();
        } else {
            alert('You must agree to terms');
        }

        e.preventDefault();
    }

    saveGradingForm(submission) {

        // const show_alert = document.querySelector('.alert-success');
        // const msg_alert = document.querySelector('.msg-success');
        const method = this.data.get('mode') == "update"
            ? "PATCH" : "POST";
        document.querySelectorAll('.attached_images').forEach((input) => {
            if (input.value !== '') {
                // Check if the value is not already in this.images
                if (!this.gradingImages.includes(input.value)) {
                    // If it's not in the array, then push it
                    this.gradingImages.push(input.value);
                }
            }
        });
        fetch(this.gradingFormSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: method,
            body: JSON.stringify({
                grading_form: {
                    responses: submission,
                    disclaimer: submission.isAgreed,
                    status: this.formSaveDraftStatus ? 'initiated' : 'completed',
                    form_version_id: this.gradingFormVersionId,
                    user_id: this.gradingIolFormUserId,
                    images: this.gradingImages,
                    stack_id: this.gradingIolFormStackId

                }
            })
        }).then(response => {
            if (this.formSaveDraftStatus === false) {
                this.makeFormDisabled(this.gradingForm);
                this.submitTarget.classList.add('hidden');
                this.updateTarget.classList.remove('hidden');
                location.reload();
            }
            this.formSaveDraftStatus = false;
            (response.status == 200) ? this.successAlert() : this.errorAlert();
        });
    }

    formUpdate(e) {
        fetch(this.gradingFormSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({
                grading_form: {
                    'status': 'editing',
                    form_version_id: this.gradingFormVersionId,
                    user_id: this.gradingIolFormUserId,
                    stack_id: this.gradingIolFormStackId
                }
            })
        }).then(response => {
            (response.status == 200) ? this.successAlert() : this.errorAlert();
            location.reload();
        });
    }

    toggleImage() {
        if (this.cornerstoneImages.dataset.expanded === "1") {
            this.collapse_cornerstone()
        } else {
            this.expand_cornerstone()
        }
    }

    collapse_cornerstone() {

        const grading_tools = this.cornerstoneImages.querySelector('.grading_tools');
        const iol_actions_btn = this.cornerstoneImages.querySelector('#iol-accept');
        this.cornerstoneImages.classList.add('col-span-1')
        this.cornerstoneImages.classList.remove('col-span-9')
        grading_tools.classList.add('hidden');
        iol_actions_btn.classList.add('hidden');
        this.viewerTarget.classList.add('hidden');
        this.cornerstoneImages.dataset.expanded = "0";
        this.cornerstoneIconTarget.innerHTML = `VIEWER`;
        this.cornerstoneIconTarget.classList.add('text-white');
        this.cornerstoneIconTarget.classList.add('p-1');
        this.cornerstoneIconTarget.classList.remove('float-right');
        this.largeForm()
    }

    expand_cornerstone() {
        const grading_tools = this.cornerstoneImages.querySelector('.grading_tools');
        const iol_actions_btn = this.cornerstoneImages.querySelector('#iol-accept');
        this.cornerstoneImages.classList.add('col-span-9')
        this.cornerstoneImages.classList.remove('col-span-1')
        grading_tools.classList.remove('hidden');
        iol_actions_btn.classList.remove('hidden');
        this.viewerTarget.classList.remove('hidden');
        this.cornerstoneImages.dataset.expanded = "1";
        this.cornerstoneIconTarget.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
        </svg>
        `;
        this.cornerstoneIconTarget.classList.remove('text-white');
        this.cornerstoneIconTarget.classList.remove('p-1');
        this.cornerstoneIconTarget.classList.add('float-right');
        this.reverseFormChanges()
    }

    largeForm() {
        this.formSection.classList.add('col-span-11');
        this.formSection.classList.remove('col-span-3');
    }

    reverseFormChanges() {
        this.formSection.classList.add('col-span-3');
        this.formSection.classList.remove('col-span-11');
    }

    makeFormDisabled(form) {
        form.disabled = true;
        this.submitTarget.classList.add('hidden');
        this.submitGradingbuttonTarget.classList.add("hidden");
        const links = this.annotationToolsTarget.querySelectorAll("a");
        links.forEach((link) => {
            link.classList.add("hidden");
        });
        const dropzoneElements = document.getElementsByClassName('dropzone');
        for (const element of dropzoneElements) {
            element.classList.add('hidden');
        }
    }

    makeFormEnable(form) {
        form.disabled = false;
        this.updateTarget.classList.add('hidden');
        this.submitGradingbuttonTarget.classList.remove("hidden");;
        const links = this.annotationToolsTarget.querySelectorAll("a");
        links.forEach((link) => {
            link.classList.remove("hidden");
        });
        const dropzoneElements = document.getElementsByClassName('dropzone');
        for (const element of dropzoneElements) {
            element.classList.remove('hidden');
        }
    }

    toggleSubmitArea() {
        var staticPanel = document.getElementById('expend_colapse');
        if (this.questionAreaTarget.classList.contains('hidden')) {
            this.questionAreaTarget.classList.remove('hidden');
            staticPanel.classList.add('fa-minus-square-o');
            staticPanel.classList.remove('fa-plus-square-o');
        } else {
            this.questionAreaTarget.classList.add('hidden');
            staticPanel.classList.add('fa-plus-square-o');
            staticPanel.classList.remove('fa-minus-square-o');
        }
    }
}