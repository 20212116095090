import { Controller } from 'stimulus';
import $ from 'jquery';
export default class extends Controller {
  static targets = ['parentSelect', 'childSelect','issueDate', 'expiryDate', "status", "type"]; 

  connect() {
    this.pdfButtonClicked = false;
    // By Clicking on View PDF button. all form values will be picked through this code. 
    this.element.querySelector("#view_pdf_for_date").addEventListener("click", () => {
    const formData = new FormData(this.element.querySelector("#change_date_form"));
    const url = this.element.querySelector("#view_pdf_for_date").getAttribute("href") + "?" + new URLSearchParams(formData).toString();
    this.element.querySelector("#view_pdf_for_date").setAttribute("href", url);
    });
    // CC email select 2 is implemented here. 
    $(".cc_email").select2({
      tags: true,
      tokenSeparators: [',', ' ']
    });
    // BCC email select 2 is implemented here. 
    $(".request_bcc_email").select2({
      tags: true,
      tokenSeparators: [',', ' ']
    });
  }
  validate() {
    this.pdfButtonClicked=true;
    document.getElementById("generate_certificate").classList.remove('remove_disabled_color');    
  }
  checkViewPdfStatus(event) {
    if (this.pdfButtonClicked === false) {
      alert("Click the 'View PDF' button first, then proceed to Update the Certificate");
      event.preventDefault();
    }
  }
  handleParentSelectChange() { 
    if (this.parentSelectTarget.value != "") {
      this.populateChildSelect();
    }
  }
  populateChildSelect() {
    const study_id = this.data.get('study');
    this.selectChangeUrl = document.location.origin + `/studies/${study_id}/fetch_all_templates.json`;
    const childModalityId = this.data.get('childModalityId');
    // populate child modalities
    fetch(this.selectChangeUrl, {
      headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ study_id: study_id, template_id: this.parentSelectTarget.value }),
      method: 'POST',
    })
    .then(response => response.json())
    .then((data) => { 
      document.getElementById("fetch_template_body").value = data.body
    });
  }
  checkCertificateStatus(){ 
    if (this.statusTarget.value == "Full") {
      this.selectChangeUrl = document.location.origin + `/check_certificate_status.json`;
      fetch(this.selectChangeUrl, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ certification_request_id: this.data.get("requestId") }),
        method: 'POST',
      })
      .then(response => response.json())
      .then((data) => {
        if (data.status === false) {
          Swal.fire({
            icon: 'warning',
            title: 'Full Certificate cannot be generated. As all sets of images are not uploaded / accepted.',
          }).then(() => {
            location.reload();
          });
        }
        if (data.already_present === true) {
          Swal.fire({
            icon: 'warning',
            title: 'Full Certificate is already generated.',
          }).then(() => {
            location.reload();
          });
        }     
      });
    } else if (this.statusTarget.value == "Provisionally Certified") {
  
      this.selectChangeUrl = document.location.origin + `/check_provisional_status.json`;
      fetch(this.selectChangeUrl, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ certification_request_id: this.data.get("requestId") }),
        method: 'POST',
      })
      .then(response => response.json())
      .then((data) => {
        if (data === true) {
          Swal.fire({
            icon: 'warning',
            title: 'Provisional/Full Certificate is already generated for this request.',
          }).then(() => {
            location.reload();
          });
        }            
      });
    }
  }
}
