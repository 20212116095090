import { Controller } from "stimulus"
import $ from 'jquery';
export default class extends Controller {
  async connect() {
    const array = this.data.get('select')
    $('#filtered').select2();
    if (array != null) {
      const arr = JSON.parse(array)
      for(let i=0; i< arr.length; i++){
        $('#'+ arr[i]).select2();
        $('#'+ arr[i]).on('select2:select', (event) => {
          const selectedOptionId = $(event.currentTarget).val();
          handleParentSelectChange(selectedOptionId);
        });
        $('#'+ arr[i]).on('select2:unselect', (event) => {
          const selectedOptionId = $(event.currentTarget).val();
          handleParentSelectChange(selectedOptionId, arr[i]);
        });
        $('#device_'+ arr[i]).select2();
        $('#device_'+ arr[i]).on('select2:select', (event) => {
          const selectedDevicesIDs = $(event.currentTarget).val();
          certifyDevicesSelectChange(selectedDevicesIDs, arr[i]);
        });
        $('#device_'+ arr[i]).on('select2:unselect', (event) => {
          const selectedDevicesIDs = $(event.currentTarget).val();
          certifyDevicesSelectChange(selectedDevicesIDs, arr[i]);
        });
      }
    }
    function handleParentSelectChange(child_modality_ids, modality) { 
      const study_id = document.getElementById("study_id").value;
      const selectChangeUrl = document.location.origin + `/studies/${study_id}/certifiable_child_modality.json`;
        fetch(selectChangeUrl, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ child_modality_id: child_modality_ids, modality_id: modality }),
        method: 'POST',
      })
    }
    function certifyDevicesSelectChange(devices_ids, modality) { 
      const study_id = document.getElementById("study_id").value;
      const selectChangeUrl = document.location.origin + `/studies/${study_id}/certifiable_devices.json`;
        fetch(selectChangeUrl, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ device_id: devices_ids, modality_id: modality }),
        method: 'POST',
      })
    }
  }

}