import { Controller } from "stimulus"


export default class extends Controller {

    zoomIn(event) {
        const imageUrl = event.target.getAttribute("src");
        const zoomedImage = document.getElementById("zoomed-image");
        zoomedImage.src = imageUrl;
 
        const modalOverlay = document.getElementById("modal-overlay");
        modalOverlay.style.display = "block";
 
        // Add click event listener to close the modal if clicked outside the zoomed image
        modalOverlay.addEventListener("click", function(e) {      
            modalOverlay.style.display = "none";        
        });
    }

}