import { Controller } from 'stimulus';
import { Formio } from 'formiojs';
import FormioUtils from 'formiojs/utils';

/**
 * API Docs for Form.io
 * https://github.com/formio/formio.js/wiki
 *
 * Full API Docs
 * https://formio.github.io/formio.js/docs/
 *
 * Examples:
 * https://formio.github.io/formio.js/app/examples/
 *
 */

import Hammer from 'hammerjs';
import * as cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';

import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import utils from 'formiojs/utils';

const {
    PanTool,
    WwwcTool,
    ZoomMouseWheelTool,
    LengthTool,
    ArrowAnnotateTool,
    AngleTool,
    EllipticalRoiTool,
    EraserTool,
    RectangleRoiTool,
    FreehandRoiTool
} = cornerstoneTools;

const LEFT_CLICK = 1;
const MIDDLE_CLICK = 4;
// const RIGHT_CLICK = 2;

export default class extends Controller {
    static targets = ['viewer', 'url', 'form', 'path', 'agreement', 'users', 'regradingForm', 'regraders', 'menu', 'menuIcon', 'formSection', 'cornerstone', 'cornerstoneIcon', 'questionArea', 'submit', 'update']

    async initialize() {
        // Define Targets
        const elem = this.viewerTarget;
        this.gradingFormElem = this.formTarget;
        this.gradingFormAgreementElem = this.agreementTarget;
        this.reviewFormAgreementElem = this.agreementTarget;
        this.annotationUsersElem = this.usersTarget;
        this.regradersElems = this.regradersTargets;
        this.regradingFormElem = this.regradingFormTarget;
        this.imagesMenu = this.menuTarget;
        this.formSection = this.formSectionTarget;
        this.cornerstoneViewer = this.cornerstoneTarget;
        const imageKeys = this.data.get('imageKeys');
        this.adjImages = imageKeys ? imageKeys.split(',').map(key => key.trim()) : [];
        this.viewerElement = elem;
        this.gradingSaveUrl = document.location.origin + this.data.get('updatePath') + '.json';
        this.regradingUrl = document.location.origin + this.data.get('regradePath') + '.json';
        this.graderNameUrl = document.location.origin + this.data.get('graderNamePath') + '.json';
        this.releaseEye = this.data.get('releaseEye');
        this.images = [];
        this.readImages();
        this.currentImage = 0;
        this.eligibilityFormVersionId = this.data.get('versionId');
        this.eligibilitystudyId = this.data.get('studyId');
        this.formSaveDraftStatus = false;
        // load previously saved grading data if available
        this.annotation = JSON.parse(this.data.get('annotation'));
        this.annotationIsLoadedOnce = {};

        /**
         * Annotations from Graders
         */
        this.graderAnnotations = JSON.parse(this.data.get('graderAnnotations'));
        /**
         * Responses from Graders
         */
        this.graderResponses = JSON.parse(this.data.get('graderResponses'));

        this.imagesUrls = {};
        // Iterate through each GradingForm object in your collection
        this.graderResponses.forEach((gradingForm) => {
            // Access the responses.files data from the GradingForm object
            const responsesFiles = gradingForm.responses.files;
            for (const fileKey in responsesFiles) {
                if (responsesFiles.hasOwnProperty(fileKey)) {
                    const fileUrls = responsesFiles[fileKey];
                    if (this.imagesUrls[fileKey]) {
                        // If the file name is already a key in this.imagesUrls, append the new URLs to the existing array
                        this.imagesUrls[fileKey] = this.imagesUrls[fileKey].concat(fileUrls);
                    } else {
                        // If the file name is not already a key, create a new key with an array containing the URLs
                        this.imagesUrls[fileKey] = fileUrls;
                    }
                }
            }
        });

        /**
         * Adjudication Form Setup
         */

        this.gradingFormSchema = JSON.parse(this.data.get('gradingForm'));
        const updatedComponents = [];
        this.gradingFormSchema.components.forEach(multiple_trials => {
            if ((multiple_trials['key'].slice(-2).toLowerCase() == this.releaseEye || this.releaseEye == 'ou') || (multiple_trials['key'].slice(-2).toLowerCase() != 'od' && multiple_trials['key'].slice(-2).toLowerCase() != 'os')) {
                updatedComponents.push(multiple_trials);
            }
        });
        this.gradingFormSchema['components'] = updatedComponents;
        this.gradingFormResponses = JSON.parse(this.data.get('responses'));
        this.reviewFormSaveUrl = document.location.origin + this.data.get('formUpdatePath') + '.json';
        // renderer
        this.gradingForm = await Formio.createForm(this.gradingFormElem, this.gradingFormSchema, {
            noAlerts: true
        });

        // set the draft responses if any
        this.gradingForm.submission = this.gradingFormResponses;

        // attach event listeners to the form io
        this.gradingForm.on('submit', submission => {
            this.saveGradingForm(submission);
        });

        // make form disabled if adjudication completed
        if (this.reviewFormAgreementElem.checked) {
            this.makeFormDisabled(this.gradingForm);
        } else {
            this.makeFormEnable(this.gradingForm);
        }
        // end
        // show grader responses for each form field
        FormioUtils.eachComponent(this.gradingForm.components, c => {
            if (c && c.component && c.element) {
                const element = c.element;
                if (!element.classList.contains('formio-hidden')) {
                    const graderAnswersDiv = document.createElement('div');
                    // now check for an answer to this question in the grader responses
                    const questionKey = c.component.key;
                    let valToCompare = 'rand1234';
                    let match = true;
                    this.graderResponses.forEach((r, i) => {
                        const br = document.createElement('br');
                        const span = document.createElement('span');
                        span.classList.add('grader-response');
                        span.classList.add('pl-1');
                        if (r.responses.data && r.responses.data.hasOwnProperty(questionKey)) {
                            if (typeof (r.responses.data[questionKey]) == 'object') {
                                const keys = Object.keys(r.responses.data[questionKey]).filter(k => r.responses.data[questionKey][k])
                                span.append(`Grader ${i + 1}: ${keys.join(', ')}`);
                            } else {
                                span.append(`Grader ${i + 1}: ${r.responses.data[questionKey]}`);
                            }

                            if (valToCompare === 'rand1234') valToCompare = r.responses.data[questionKey];
                            else if (r.responses.data[questionKey] !== valToCompare) match = false;
                        } else {
                            span.append(`Grader ${i + 1}: N/A`);
                            match = false;
                        }
                        graderAnswersDiv.append(span);
                        graderAnswersDiv.append(br);
                    });
                    if (!match) graderAnswersDiv.classList.add('mt-1', 'border', 'rounded', 'border-red-400');
                    else graderAnswersDiv.classList.add('mt-1', 'border', 'rounded', 'border-green-400');
                    c.element.append(graderAnswersDiv);
                }
            }
        });
        /**
         *
         * Corner Stone Setup
         */
        cornerstoneWebImageLoader.external.cornerstone = cornerstone;
        cornerstoneTools.external.cornerstone = cornerstone;
        cornerstoneTools.external.Hammer = Hammer;
        cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
        cornerstoneTools.init({
            mouseEnabled: true,
            touchEnabled: true,
            globalToolSyncEnabled: true,
            showSVGCursors: true

        });

        cornerstoneTools.loadHandlerManager.setErrorLoadingHandler(
            (element, imageId, error) => {
                console.error(imageId);
                // throw error;
            }
        );

        const imageUrl = document.location.origin + '/' + this.url;
        cornerstone.enable(elem);
        // cornerstone.registerImageLoader = 'dicomweb';
        cornerstone.loadAndCacheImage(imageUrl).then(image => {
            this.loadExistingAnnotationsForImage(imageUrl);

            cornerstone.displayImage(elem, image);

            // Activate Pan Tool on Middle Click Drag
            cornerstoneTools.addTool(PanTool);
            cornerstoneTools.setToolActive('Pan', { mouseButtonMask: MIDDLE_CLICK });

            // Activate WWWc tool on Left Click Drag
            cornerstoneTools.addTool(WwwcTool);
            cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });

            // Activate Zoom Mouse Wheel Tool
            cornerstoneTools.addTool(ZoomMouseWheelTool, {
                // Optional configuration
                configuration: {
                    invert: false,
                    preventZoomOutsideImage: false,
                    minScale: 0.01,
                    maxScale: 20.0
                }
            });
            cornerstoneTools.setToolActive('ZoomMouseWheel', {});

            // Add Non Active tools
            cornerstoneTools.addTool(LengthTool);

            cornerstoneTools.addTool(ArrowAnnotateTool);

            cornerstoneTools.addTool(AngleTool);

            cornerstoneTools.addTool(RectangleRoiTool);

            cornerstoneTools.addTool(FreehandRoiTool);

            cornerstoneTools.addTool(EraserTool);

            cornerstoneTools.addTool(EllipticalRoiTool, {
                configuration: {
                    drawHandlesOnHover: false,
                    hideHandlesIfMoving: true,
                    renderDashed: false
                }
            });

            // register cornerstone tools events
            // https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/events.js
            elem.addEventListener('cornerstonetoolsmeasurementcompleted', e => {
                console.log(`------${e.detail.toolName}-----`);
                console.table(e.detail.measurementData);
            });

            this.refreshTools();
        });

        // attach event listeners
        this.viewerElement.addEventListener('cornerstonenewimage', (e) => {
            // console.log(e);
            e.detail.viewport.displayedArea.brhc.x = e.detail.image.width;
            e.detail.viewport.displayedArea.brhc.y = e.detail.image.height;
        });
        // to show dropzones area
        this.element.addEventListener("successFileUpload", this.handleFileUploadEvent.bind(this));
        this.element.addEventListener("removeFile", this.handleRemoveFileEvent.bind(this));
        // end

        await this.renderGradingDataAndImages();
    }

    renderGradingDataAndImages() {
        Formio.createForm(this.gradingFormElem, 'https://examples.form.io/example').then((form) => {
            // Attach the callback function to the "render" event
            this.gradingForm.on("render", () => {
                // Call the defined functions
                this.displayGradingAnswerToAdjudication();
                if (Object.keys(this.gradingFormResponses).length > 0) {
                    this.displayImagesForQuestions(this.gradingForm.components, this.gradingFormResponses, this.data.get('attachments'));
                    this.displayImagesForQuestions(this.gradingForm.components, this.gradingFormResponses, this.data.get('gradingAttachments'));
                }
                this.highlightQuestionsToAdjudicator(this.gradingForm.components, this.gradingFormResponses);
            });
            // Attach thebfunction to the "change" event
            this.gradingForm.on("change", () => {
                // defined functions
                this.displayGradingAnswerToAdjudication();
                if (Object.keys(this.gradingFormResponses).length > 0) {
                    this.displayImagesForQuestions(this.gradingForm.components, this.gradingFormResponses, this.data.get('attachments'));
                    this.displayImagesForQuestions(this.gradingForm.components, this.gradingFormResponses, this.data.get('gradingAttachments'));
                }
                this.highlightQuestionsToAdjudicator(this.gradingForm.components, this.gradingFormResponses);
            });
            // uncomment the line below to rerender
            this.gradingForm.redraw();
            if (this.gradingForm.disabled) {
                const dropzoneElements = document.getElementsByClassName('dropzone');
                for (const element of dropzoneElements) {
                    element.classList.add('hidden');
                }
            }
        });
    }

    async displayImagesForQuestions(formComponents, gradingFormResponses, ImagesArray) {
        // Split the string into an array of URLs using a comma as the separator
        const imagesArray = ImagesArray.split('<==>').map(url => url.trim());
        // Iterate through the form components
        FormioUtils.eachComponent(formComponents, component => {
            // Check if the component key is in gradingFormResponses
            if (component.key in gradingFormResponses.data) {
                const questionKey = component.key;
                const verified_key = questionKey + "_sdv_verified";
                const comments_key = questionKey + "_sdv_comments";
                if (gradingFormResponses.sdv) {
                    if (gradingFormResponses && gradingFormResponses.sdv && verified_key in gradingFormResponses.sdv) {
                        const check_sdv = gradingFormResponses.sdv[verified_key];
                        const sdv_comments = gradingFormResponses.sdv[comments_key];
                        this.appendSdvWithQuestionTitles(component, check_sdv, sdv_comments);
                    }
                }
                if (gradingFormResponses && gradingFormResponses.files && questionKey in gradingFormResponses.files) {
                    const imageUrls = gradingFormResponses.files[questionKey];
                    this.appendAdjudicationImageWithQuestion(component, imageUrls, imagesArray);
                }
                // console.log(questionKey);
            }
            for (const response of this.graderResponses) {
                if ('data' in response.responses && component.key in response.responses.data) {
                    const questionKey = component.key;
                    if (this.graderResponses && this.imagesUrls && questionKey in this.imagesUrls) {
                        this.appendGradingImageWithQuestion(component, questionKey, this.imagesUrls, imagesArray);
                    }
                }
            }
        });
    }
    highlightQuestionsToAdjudicator() {
        if (!this.gradingFormAgreementElem.checked) {
            FormioUtils.eachComponent(this.gradingForm.components, c => {
                if (c && c.component && c.element) {
                    const element = c.element;
                    if (!element.classList.contains('formio-hidden')) {
                        const questionKey = c.component.key;
                        const responseValues = this.graderResponses.map(r => r.responses.data[questionKey]);
                        if (responseValues.length > 1 && !responseValues.every(value => value === responseValues[0])) {
                            // Values are not equal, add red border to the parent div
                            const parentDiv = element.closest('.formio-component');
                            if (parentDiv) {
                                parentDiv.classList.add('border-dashed', 'border-2', 'border-red-600', 'p-1');
                            }
                        }
                    }
                }
            });
        }
    }

    loadExistingAnnotationsForImage(url) {

        let annotation = {};
        if (this.annotationUsersElem.value === 'adjudicator') {
            annotation = this.annotation[url];
        } else {
            annotation = this.graderAnnotations[this.annotationUsersElem.value][url];
        }
        // this check avoids reloads between next and previous loads
        if (annotation && !this.annotationIsLoadedOnce[url]) {
            cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(url, annotation);
            this.annotationIsLoadedOnce[url] = true;
            this.refreshTools();
        }
    }

    refreshTools() {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        tools.forEach(tool => {
            cornerstoneTools.setToolActive(tool, { mouseButtonMask: LEFT_CLICK });
        });
    }

    readImages() {
        const images = document.querySelectorAll('.image');
        images.forEach(i => {
            this.images.push({
                id: i.getAttribute('data-id'),
                url: i.getAttribute('data-url'),
                elem: i
            });
        });
    }

    loadImage(url, id) {

        url = document.location.origin + '/' + url;
        cornerstone.loadAndCacheImage(url).then((image) => {
            this.loadExistingAnnotationsForImage(url);
            cornerstone.displayImage(this.viewerElement, image);
            cornerstone.enable(this.viewerElement);
        });
    }

    changeImage(e, elem) {
        let target;
        if (e) target = e.target;
        else target = elem;
        // load new image
        this.loadImage(target.dataset.url, target.dataset.id);

        // if the call came from click adjust the currentImage index
        if (e) {
            this.currentImage = target.dataset.idx;
        }
        // remove all active borders
        const allImageBoxes = document.querySelectorAll('.image-box');
        allImageBoxes.forEach(i => i.classList.remove('border-primary-500'));

        // active border around current image
        target.parentNode.classList.add('border-primary-500');
    }

    nextImage(e) {
        console.log(`current index: ${this.currentImage}`);
        const len = this.images.length;
        this.currentImage = Math.abs((this.currentImage + 1) % len);
        console.log(` after: ${this.currentImage}`);
        this.changeImage(null, this.images[this.currentImage].elem);
        e.preventDefault();
    }

    previousImage(e) {
        console.log(`current index: ${this.currentImage}`);
        const len = this.images.length;
        this.currentImage = Math.abs((this.currentImage + len - 1)) % len;
        console.log(` after: ${this.currentImage}`);
        this.changeImage(null, this.images[this.currentImage].elem);
        e.preventDefault();
    }

    changGradingUser(e) {

        const currentImageUrl = this.images[this.currentImage].url;
        console.log('currentImageUrl');
        console.log(currentImageUrl);
        this.annotationIsLoadedOnce = {};// [document.location.origin + currentImageUrl] = null;
        if (this.annotationUsersElem.value === 'adjudicator') {
            // TODO: Enable all tools and hide the toolbar
            this.loadImage(currentImageUrl);
        } else {
            // TODO: Disable all tools and hide the toolbar
            this.loadImage(currentImageUrl);
        }
    }

    /**
     *
     * TODO: Remove function and UI. this feature is not being ued currently
     */
    labelTool(e) {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];

        tools.forEach(tool => {
            cornerstoneTools.setToolEnabled(tool, { mouseButtonMask: LEFT_CLICK });
        });

        this.viewerElement.addEventListener('cornerstonetoolsmousedown', e => {
            const coord = e.detail.currentPoints.canvas;
            const mouseBtn = e.detail.buttons;
            // if its a right click
            if (mouseBtn === 2) {
                tools.forEach(tool => {
                    const state = cornerstoneTools.getToolState(this.viewerElement, tool);
                    if (state) {
                        state.data.forEach(d => {
                            const isNear = cornerstoneTools.getToolForElement(this.viewerElement, tool).__proto__.pointNearTool(this.viewerElement, d, coord);
                            if (isNear) console.log(`${tool} with ${d.uuid} near : ${isNear}`);
                            cornerstoneTools.setToolActive(tool, { mouseButtonMask: LEFT_CLICK });
                            // when near is detected see if we can have the annotation highlited green
                        });
                    }
                });
            }
        });
        e.preventDefault();
    }

    levelsTool(e) {
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    panTool(e) {
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    lengthTool(e) {
        cornerstoneTools.setToolActive('Length', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    arrowAnnotationTool(e) {
        cornerstoneTools.setToolActive('ArrowAnnotate', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    angleTool(e) {
        cornerstoneTools.setToolActive('Angle', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    rectangleTool(e) {
        cornerstoneTools.setToolActive('RectangleRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    freehandTool(e) {
        cornerstoneTools.setToolActive('FreehandRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    ellipseTool(e) {
        cornerstoneTools.setToolActive('EllipticalRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    eraserTool(e) {
        cornerstoneTools.setToolActive('Eraser', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    /**
     * TODO: combing submitGrading and saveGrading functions. They no longer need to be split up
     */
    submitGrading(e) {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        // const annotations = {};

        // https://groups.google.com/g/cornerstone-platform/c/QWHTGgkLufo
        // https:// github.com/cornerstonejs/cornerstoneTools/blob/3925399f72e69f69a4b108be10bfce115eda3247/src/stateManagement/imageIdSpecificStateManager.js
        const annotations = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
        // tools.forEach(tool => {
        //     const toolState = cornerstoneTools.getToolState(this.viewerTarget, tool);
        //     annotations[tool] = toolState;
        // });

        this.saveGrading(annotations);
        e.preventDefault();
    }

    /**
     * TODO: combing submitGrading and saveGrading functions. They no longer need to be split up
     * TODO: Add notification on UI after submission is complete.
     */
    saveGrading(annotations) {
        console.log(annotations);
        fetch(this.gradingSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({
                eligibility_review: {
                    annotations: annotations
                }
            })
        }).then(response => {
            console.log(response);
        });
    }

    get url() {
        return this.data.get('url');
    }

    // formio functions
    /* formSaveDraft(e) {
        if (this.gradingForm.submission) this.gradingForm.submission.isAgreed = false;
        this.gradingForm.submit();
        e.preventDefault();
    } */

    formSaveDraft(e) {

        if (this.gradingForm.submission) this.gradingForm.submission.isAgreed = false;
        // set draft status
        this.formSaveDraftStatus = true;
        this.gradingForm.submit();
        e.preventDefault();
    }

    formCommit(e) {
        const isAgreed = this.reviewFormAgreementElem.checked;
        if (isAgreed) {
            if (this.gradingForm.submission) this.gradingForm.submission.isAgreed = isAgreed;
            this.gradingForm.submit();
        } else {
            alert('You must agree to terms');
        }

        e.preventDefault();
    }

    /**
     * TODO: Add notification on UI after submission is complete.
     */
    saveGradingForm(submission) {
        this.defaultSaveAnnotation();
        document.querySelectorAll('.attached_images').forEach((input) => {
            if (input.value !== '') {
                // Check if the value is not already in this.images
                if (!this.adjImages.includes(input.value)) {
                    // If it's not in the array, then push it
                    this.adjImages.push(input.value);
                }
            }
        });
        const show_alert = document.querySelector('.alert-success');
        const msg_alert = document.querySelector('.msg-success');
        fetch(this.reviewFormSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({
                eligibility_review: {
                    responses: submission,
                    status: this.formSaveDraftStatus ? 'initiated' : 'completed',
                    form_version_id: this.eligibilityFormVersionId
                }
            })
        }).then(response => {

            if (this.formSaveDraftStatus === false) {
                this.makeFormDisabled(this.gradingForm);
                this.submitTarget.classList.add('hidden');
                this.updateTarget.classList.remove('hidden');
            }
            this.formSaveDraftStatus = false;
            show_alert.classList.remove('hidden');
            msg_alert.innerHTML = "Submitted Successfully!";
            setInterval(function () { show_alert.classList.add('hidden') }, 3000);
        });
    }

    defaultSaveAnnotation() {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        // const annotations = {};

        const annotations = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();

        this.saveGrading({ ...annotations, ...this.annotation });
    }

    formUpdate(e) {
        fetch(this.reviewFormSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({
                eligibility_review: {
                    status: 'editing',
                    form_version_id: this.eligibilityFormVersionId
                }
            })
        }).then(response => {
            //window.location.reload();
        });
    }

    // disabled form and toggle one section between submit or edit section
    makeFormDisabled(form) {
        form.disabled = true;
        this.submitTarget.classList.add('hidden');
    }

    makeFormEnable(form) {
        form.disabled = false;
        this.updateTarget.classList.add('hidden');
    }
    // end
    regrade(e) {
        this.regradingFormElem.classList.toggle('hidden');;
        e.preventDefault();
    }

    confirmRegrade(e) {
        const markedForRegrade = this.regradersElems.filter((c) => c.checked).map(c => c.getAttribute('data-user'));
        if (markedForRegrade.length) { this.saveRegradeRequest(markedForRegrade); }
        e.preventDefault();
    }

    // TODO: Refactor and extract all the ajax calls (3 in this controller) to a common utility and DRY it up
    saveRegradeRequest(userIDs) {
        console.log(this.regradingUrl);
        if (userIDs && userIDs.length) {
            console.log(`requested regrading on users ${userIDs.join(',')}`);
            fetch(this.regradingUrl, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: 'PATCH',
                body: JSON.stringify({
                    stack: {
                        user_ids: userIDs
                    }
                })
            }).then(response => {
                console.log(response);
            });
        } else {
            console.log('No users were selected for regrading');
        }
    }

    // toggle menus and cornerstone for adjudication

    toggleMenu() {

        if (this.imagesMenu.dataset.expanded === "1") {
            this.collapse()
        } else {
            this.expand()
        }
    }

    collapse() {
        this.imagesMenu.classList.add('bg-blue-500');
        this.imagesMenu.dataset.expanded = "0";
        const buttons = this.imagesMenu.querySelector('.pre_next_btns');
        const img_menu = this.imagesMenu.querySelector('.img_menu');
        buttons.classList.add('hidden');
        img_menu.classList.add('hidden');
        this.menuIconTarget.classList.add('text-white');
        this.menuIconTarget.innerHTML = `MENU`;
    }

    expand() {
        this.imagesMenu.classList.remove('bg-blue-500');
        this.imagesMenu.dataset.expanded = "1";
        const buttons = this.imagesMenu.querySelector('.pre_next_btns');
        const img_menu = this.imagesMenu.querySelector('.img_menu');
        buttons.classList.remove('hidden');
        img_menu.classList.remove('hidden');
        this.menuIconTarget.classList.remove('text-white');
        this.menuIconTarget.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
        </svg>`;
    }

    toggleImage() {

        if (this.cornerstoneViewer.dataset.expanded === "1") {
            this.collapse_cornerstone()
        } else {
            this.expand_cornerstone()
        }
    }

    collapse_cornerstone() {

        const grading_tools = this.cornerstoneViewer.querySelector('.grading_tools');
        this.cornerstoneViewer.classList.add('col-span-1')
        this.cornerstoneViewer.classList.remove('col-span-6')
        grading_tools.classList.add('hidden');
        this.viewerTarget.classList.add('hidden');
        this.cornerstoneViewer.dataset.expanded = "0";
        this.cornerstoneIconTarget.classList.add('p-2');
        this.cornerstoneIconTarget.classList.add('rounded-sm');
        this.cornerstoneIconTarget.classList.add('text-white');
        this.cornerstoneIconTarget.innerHTML = `VIEWER`;
        this.largeForm()
    }

    largeForm() {
        this.formSection.classList.add('col-span-10');
        this.formSection.classList.remove('col-span-5');
    }

    expand_cornerstone() {

        const grading_tools = this.cornerstoneViewer.querySelector('.grading_tools');
        this.cornerstoneViewer.classList.add('col-span-6')
        this.cornerstoneViewer.classList.remove('col-span-1')
        grading_tools.classList.remove('hidden');
        this.viewerTarget.classList.remove('hidden');
        this.cornerstoneViewer.dataset.expanded = "1";
        this.cornerstoneIconTarget.classList.remove('p-2');
        this.cornerstoneIconTarget.classList.remove('rounded-sm');
        this.cornerstoneIconTarget.classList.remove('text-white');
        this.cornerstoneIconTarget.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
    </svg>`;
        this.reversLargeForm()
    }

    reversLargeForm() {
        this.formSection.classList.remove('col-span-10');
        this.formSection.classList.add('col-span-5');
    }

    toggleSubmitArea() {
        var staticPanel = document.getElementById('expend_colapse');
        // console.log(staticPanel.classList.re);
        if (this.questionAreaTarget.classList.contains('hidden')) {
            this.questionAreaTarget.classList.remove('hidden');
            staticPanel.classList.add('fa-minus-square-o');
            staticPanel.classList.remove('fa-plus-square-o');
        } else {
            this.questionAreaTarget.classList.add('hidden');
            staticPanel.classList.add('fa-plus-square-o');
            staticPanel.classList.remove('fa-minus-square-o');
        }
    }



    displayGradingAnswerToAdjudication() {
        if (!this.gradingFormAgreementElem.checked) {
            FormioUtils.eachComponent(this.gradingForm.components, c => {
                if (c && c.component && c.element) {
                    const element = c.element;
                    if (!element.classList.contains('formio-hidden')) {
                        // Check if graderAnswersDiv already exists
                        let graderAnswersDiv = element.querySelector('.grader-answers-div');
                        if (!graderAnswersDiv) {
                            graderAnswersDiv = document.createElement('div');
                            graderAnswersDiv.classList.add('grader-answers-div');
                            element.append(graderAnswersDiv);
                        } else {
                            return;
                        }
                        const questionKey = c.component.key;
                        let valToCompare = 'rand1234';
                        let match = true;
                        // Check if there are two answers and they are equal
                        const answers = this.graderResponses.map(r => r.responses.data[questionKey]);
                        if (answers.length === 2) {
                            if (answers[0] === '' && answers[1] === '') {
                                answers[0] = 'nil';
                                answers[1] = 'nil';
                            }
                        }
                        this.graderResponses.forEach((r, i) => {
                            const br = document.createElement('br');
                            const span = document.createElement('span');
                            span.classList.add('grader-response');
                            span.classList.add('pl-1');
                            if (r.responses.data && r.responses.data.hasOwnProperty(questionKey)) {
                                const label = this.getLabelFromSchema(this.gradingFormSchema, questionKey, r.responses.data[questionKey])
                                const displayLabel = label ? label : 'nil';
                                this.getGraderName(r['user_id']).then(userName => {
                                    span.append(`${userName}: ${displayLabel}`);
                                }).catch(error => {
                                    console.error(error);
                                });
                                if (valToCompare === 'rand1234') valToCompare = r.responses.data[questionKey];
                                else if (r.responses.data[questionKey] !== valToCompare) match = false;
                            } else {
                                span.append(`Grader ${i + 1}: Nill`);
                                match = false;
                            }
                            graderAnswersDiv.append(span);
                            graderAnswersDiv.append(br);
                        });
                        if (!match) {
                            graderAnswersDiv.classList.add('mt-1', 'border', 'rounded', 'border-red-400');
                        } else {
                            graderAnswersDiv.classList.add('mt-1', 'border', 'rounded', 'border-green-400');
                        }
                        c.element.append(graderAnswersDiv);
                    }
                }
            });
        }
    }

    getLabelFromSchema(schema, questionKey, questionValue) {
        for (const component of schema.components) {
            if (component.components) {
                // If the component has nested questions, iterate through them
                for (const question of component.components) {
                    const targetKey = question['key'];
                    const checkboxes = []
                    if (question.values && question['type'] === 'radio' && targetKey === questionKey) {
                        for (const item of question.values) {
                            if (item['value'] == questionValue) {
                                return item['label'];
                            }
                        }
                    } else if (question.values && question['type'] === 'selectboxes' && targetKey === questionKey) {
                        for (var key in questionValue) {
                            if (questionValue[key]) {
                                // Use the current key to access the corresponding element in question.values
                                checkboxes.push(question.values[key - 1]['label']);
                            }
                        }
                        return checkboxes;
                    }
                }
            }
        }
        return questionValue;
    }

    getGraderName(userId) {
        return fetch(this.graderNameUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'POST',
            body: JSON.stringify({
                user: {
                    user_id: userId
                }
            })
        }).then(response => {
            if (response.ok) {
                return response.json().then(data => data.full_name);
            } else {
                throw new Error('Failed to fetch user data');
            }
        }).catch(error => {
            console.error(error);
        });
    }



    appendGradingImageWithQuestion(component, questionId, imageUrls, imagesArray) {
        // Check if imageDiv and gradingSpan are already appended
        if (component.element && component.element.querySelector('.grading-screenshots') && component.element.querySelector('.grading-images')) {
            return; // If already appended, exit the function
        }
        // Extract image URLs and usernames
        const extractedData = imagesArray.map(item => {
            if (item.includes('<===>')) {
                let dataBeforeSeparator = item.split('<===>')[0].trim();
                return dataBeforeSeparator;
            }
        });
        const userNames = imagesArray.map(item => {
            if (item.includes('<===>')) {
                let dataAfterSeparator = item.split('<===>')[1].trim();
                return dataAfterSeparator;
            }
        });
        // Add a span for grading images if gradingImage is true
        const gradingSpan = document.createElement('p');
        gradingSpan.classList.add('grading-screenshots');
        gradingSpan.textContent = 'Eligibility Screenshots';
        // Create a div to hold the images
        const addedImages = new Set();
        const imageDiv = document.createElement('div');
        imageDiv.classList.add('mt-1', 'border', 'rounded', 'border-green-300', 'flex', 'gap-2', 'grading-images');
        // Iterate through the image URLs and create image elements
        for (const key in imageUrls) {
            if (key === questionId) {
                for (let j = 0; j < imageUrls[key].length; j++) {
                    for (let i = 0; i < extractedData.length; i++) {
                        if (extractedData[i]) {
                            let imageUrlParts = extractedData[i].split('/');
                            let encodedImageName = imageUrlParts[imageUrlParts.length - 1];
                            let imageName = decodeURIComponent(encodedImageName);
                            if (imageUrls[key][j] === imageName && !addedImages.has(imageName)) {
                                const imageElement = document.createElement('img');
                                imageElement.setAttribute('src', extractedData[i]);
                                imageElement.setAttribute('alt', 'Attachment Image');
                                imageElement.setAttribute('width', '100');
                                imageElement.setAttribute('height', '100');
                                imageElement.style.cursor = 'pointer';
                                imageElement.setAttribute('title', `Uploaded by: ${userNames[i]}`);
                                imageElement.addEventListener('click', function () {
                                    const lightboxImage = document.getElementById('lightbox-image');
                                    const lightboxUsername = document.getElementById('lightbox-username');
                                    lightboxImage.src = extractedData[i];
                                    lightboxUsername.textContent = 'Uploaded by : ' + userNames[i];
                                    const lightboxContainer = document.getElementById('lightbox-container');
                                    lightboxContainer.style.display = 'block';
                                });
                                const lightboxContainer = document.getElementById('lightbox-container');
                                lightboxContainer.addEventListener('click', function () {
                                    lightboxContainer.style.display = 'none';
                                    const lightboxUsername = document.getElementById('lightbox-username');
                                    lightboxUsername.textContent = '';
                                });
                                // Check if component.element exists before appending
                                if (component.element) {
                                    // Append the image to the div
                                    imageDiv.appendChild(imageElement);
                                    addedImages.add(imageName);
                                }
                            }
                        }
                    }
                }
            }
        }
        // Check if both imageDiv and gradingSpan are not empty before appending
        if (imageDiv.innerHTML.trim() !== "" && gradingSpan.innerHTML.trim() !== "") {
            // Check if component.element exists before appending
            if (component.element) {
                // Append the image div and grading span to the question component's element
                component.element.appendChild(imageDiv);
                component.element.appendChild(gradingSpan);
            }
        }
    }
    appendSdvWithQuestionTitles(component, check_sdv, sdv_comments) {

        if (check_sdv === 'true') {
            // Create a span element for the SVG icon with the tooltip
            const svgDive = document.createElement('span');
            svgDive.classList.add('has-tooltip');
            svgDive.innerHTML = `
          <?xml version="1.0" encoding="iso-8859-1"?>
            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg fill="#007BFF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                width="16px" height="16px" viewBox="0 0 509.604 509.604"
                xml:space="preserve" style ="margin-top:3px">
                <g>
                    <g>
                        <path d="M34.262,333.282c8.119,6.75,14.793,15.223,14.143,20.988c-0.382,3.443-0.593,6.943-0.593,10.5
                            c0,52.393,41.3,94.861,92.24,94.861c6.292,0,12.431-0.65,18.37-1.885c10.002-2.074,21.812,1.941,28.888,9.793
                            c16.82,18.646,40.803,30.342,67.492,30.342c28.19,0,53.426-13.016,70.342-33.518c6.723-8.146,18.103-11.533,28.22-8.5
                            c8.166,2.447,16.811,3.768,25.751,3.768c50.939,0,92.24-42.477,92.24-94.861c0-5.861-0.535-11.59-1.549-17.145
                            c-1.712-9.371,2.85-21.047,10.471-28.363c18.025-17.289,29.328-41.883,29.328-69.242c0-29.787-13.368-56.323-34.263-73.698
                            c-8.118-6.751-14.793-15.224-14.143-20.99c0.383-3.442,0.593-6.942,0.593-10.5c0-52.393-41.301-94.86-92.24-94.86
                            c-6.292,0-12.431,0.65-18.369,1.884c-10.002,2.075-21.812-1.941-28.889-9.792c-16.82-18.647-40.803-30.342-67.492-30.342
                            c-26.688,0-50.671,11.695-67.492,30.342c-7.076,7.841-18.886,11.867-28.888,9.792c-5.938-1.234-12.078-1.884-18.37-1.884
                            c-50.939,0-92.24,42.477-92.24,94.86c0,5.049,0.392,10.002,1.147,14.832c1.262,8.128-4.447,18.149-12.747,24.681
                            C14.219,201.663,0,228.887,0,259.583C0,289.37,13.368,315.907,34.262,333.282z M131.475,263.016
                            c2.046-3.625,7.268-3.672,12.049,0.479l48.119,33.918c2.61,1.588,5.106,2.4,7.506,2.4c4.963,0,8.893-3.576,12.689-7.02
                            l153.985-154.138c9.629-10.471,18.99-14.162,25.102-10.146c2.82,1.855,4.646,4.647,5.135,7.87
                            c0.583,3.825-0.756,7.946-3.768,11.599l-185.149,224.91c-2.687,3.26-6.11,5.059-9.629,5.059c-4.179,0-7.965-2.516-10.404-6.895
                            l-54.344-97.969C130.519,269.422,130.021,265.618,131.475,263.016z"/>
                    </g>
                </g>
            </svg>     
            <span class="tooltip bottom-2 w-min">${sdv_comments}</span>
          `;
            // Get the label element within component.element
            const labelElement = component.element.querySelector('label');
            labelElement.classList.add('flex', 'gap-2');
            // Concatenate component.label and svgDive and set it as the innerHTML of labelElement
            labelElement.innerHTML = component.label + svgDive.outerHTML;
            // Optionally, you can also console.log the updated label
            console.log(labelElement.innerHTML);
        }
    }
    appendAdjudicationImageWithQuestion(component, imageUrls, imagesArray) {

        if (component.element && component.element.querySelector('.adjudication-screenshots') && component.element.querySelector('.adjudication-files')) {
            return; // If already appended, exit the function
        }

        const addedFiles = new Set();
        // Add a span for adjudication files
        const adjudicationSpan = document.createElement('p');
        adjudicationSpan.classList.add('adjudication-screenshots');
        adjudicationSpan.textContent = 'Adjudication Screenshots and Files';

        // Create a div to hold the images and files
        const container = document.createElement('div');
        container.classList.add('mt-1', 'border', 'rounded', 'border-green-300', 'flex', 'gap-2', 'adjudication-files');

        // Iterate through the URLs and create elements
        for (const imageUrl of imageUrls) {
            for (let i = 0; i < imagesArray.length; i++) {
                let imageUrlParts = imagesArray[i].split('/');
                let imageName = imageUrlParts[imageUrlParts.length - 1];
                // Decode the URL containing %20
                let decodedImageUrl = decodeURIComponent(imageName);

                if (imageUrl == decodedImageUrl && !addedFiles.has(decodedImageUrl)) {
                    const fileExtension = imageName.split('.').pop().toLowerCase();

                    if (fileExtension === 'pdf') {
                        // Append the PDF link container to the main container
                        const pdfIconElement = document.createElement('img');
                        pdfIconElement.setAttribute('src', this.pdfIcon);
                        pdfIconElement.setAttribute('alt', 'Attachment Image');
                        pdfIconElement.setAttribute('width', '100');
                        pdfIconElement.setAttribute('height', '100');
                        pdfIconElement.style.cursor = 'pointer';

                        const pdfLink = document.createElement('a');
                        pdfLink.setAttribute('href', imagesArray[i]);
                        pdfLink.setAttribute('target', '_blank');
                        pdfLink.appendChild(pdfIconElement); // Append pdfIconElement directly to pdfLink

                        const fileContainer = document.createElement('div');
                        fileContainer.classList.add('file-container');
                        fileContainer.appendChild(pdfLink);
                        // Append the PDF link container to the main container
                        container.appendChild(fileContainer);
                    } else {
                        // Handle image file
                        const imageElement = document.createElement('img');
                        imageElement.setAttribute('src', imagesArray[i]);
                        imageElement.setAttribute('alt', 'Attachment Image');
                        imageElement.setAttribute('width', '100');
                        imageElement.setAttribute('height', '100');
                        imageElement.style.cursor = 'pointer';
                        // Create the delete icon using an SVG image
                        const deleteIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                        deleteIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                        deleteIcon.setAttribute('width', '20');
                        deleteIcon.setAttribute('height', '20');
                        deleteIcon.setAttribute('viewBox', '0 0 24 24');
                        deleteIcon.style.cursor = 'pointer';
                        deleteIcon.setAttribute('id', 'deleteIconId');
                        if (this.gradingForm._disabled == false) {
                            deleteIcon.innerHTML = `
                <title>Delete Attachment</title>
                <path fill="#FF0000" d="M18 4h-4.18l-1.82-2H10L8.82 4H4v2h16V4zM6.36 8h11.28l1.23 10.96l.01.04V20H4v-1.96l.01-.04L5.64 8zM8 18h8v-2H8v2zm0-4h8v-6H8v6z"/>
                `;
                        }
                        const stackId = this.gradingFormStackId;

                        deleteIcon.addEventListener('click', () => {
                            // Display a confirmation dialog
                            const confirmation = confirm('Are you sure you want to delete this Adj image?');
                            if (confirmation) {
                                imageElement.parentElement.remove();
                                addedFiles.delete(decodedImageUrl);
                                // Get the image URL associated with the clicked delete icon
                                const imageUrlToDelete = imageElement.src;
                                const fileSegments = imageUrlToDelete.split('/');
                                const fileName = fileSegments[fileSegments.length - 1];

                                // Send a DELETE request to the server to delete the image
                                fetch(`/delete_adj_image`, {
                                    method: 'post',
                                    headers: {
                                        'Content-Type': 'application/json; charset=utf-8',
                                        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                                    },
                                    body: JSON.stringify({
                                        image_url: fileName,
                                        stack_id: stackId,
                                        image_array: component.key
                                    })
                                })
                                    .then(response => {
                                        if (response.ok) {
                                            // Image deleted successfully from storage
                                            console.log('Adj Image deleted successfully');
                                            // Remove the corresponding image element from the DOM
                                            window.location.reload();

                                        } else {
                                            // Failed to delete image
                                            console.error('Failed to delete image');
                                        }
                                    })
                                    .catch(error => {
                                        console.error('Error:', error);
                                    });
                            }
                        });

                        // Create a container div for the image and delete icon
                        const imageContainer = document.createElement('div');
                        imageContainer.classList.add('image-container');
                        imageContainer.appendChild(imageElement);
                        imageContainer.appendChild(deleteIcon);

                        imageElement.addEventListener('click', function () {
                            const lightboxImage = document.getElementById('lightbox-image');
                            lightboxImage.src = imagesArray[i];
                            const lightboxContainer = document.getElementById('lightbox-container');
                            lightboxContainer.style.display = 'block';
                        });

                        const lightboxContainer = document.getElementById('lightbox-container');
                        lightboxContainer.addEventListener('click', function () {
                            lightboxContainer.style.display = 'none';
                        });

                        // Append the image container to the main container
                        container.appendChild(imageContainer);
                    }

                    addedFiles.add(decodedImageUrl);
                }
            }
        }

        // Check if the container is not empty before appending
        if (container.innerHTML.trim() !== '' && adjudicationSpan.innerHTML.trim() !== '') {
            // Check if component.element exists before appending
            if (component.element) {
                // Append the container and adjudication span to the question component's element
                component.element.appendChild(container);
                component.element.appendChild(adjudicationSpan);
            }
        }
    }
    handleFileUploadEvent(event) {

        // Extract the necessary values from the event
        const fileName = event.detail.fileName;
        const questionName = event.detail.questionName;
        // Initialize this.qcForm.submission.files if it doesn't exist
        if (!this.gradingForm.submission.files) {
            this.gradingForm.submission.files = {};
        }
        // Check if the questionName already exists in the files object
        if (!this.gradingForm.submission.files.hasOwnProperty(questionName)) {
            this.gradingForm.submission.files[questionName] = [];
        }
        // // Add the fileName to the respective questionName in the files object
        this.gradingForm.submission.files[questionName].push(fileName);
    }
    handleRemoveFileEvent(event) {

        const fileName = event.detail.fileName;
        const questionName = event.detail.questionName;

        // Check if the questionName exists in the files object
        if (this.gradingForm.submission.files && this.gradingForm.submission.files.hasOwnProperty(questionName)) {
            const fileArray = this.gradingForm.submission.files[questionName];
            const index = fileArray.indexOf(fileName);

            // Check if the fileName exists in the questionName's file array
            if (index !== -1) {
                // Remove the fileName from the file array
                fileArray.splice(index, 1);

                // If the file array is empty, remove the questionName from files object
                if (fileArray.length === 0) {
                    delete this.gradingForm.submission.files[questionName];
                }
            }
        }
    }

    printForm() {
        if (this.graderResponses && this.graderResponses.length > 0) {
            const stackId = this.graderResponses[0].stack_id;
            this.getFormData(stackId)
                .then(response => {
                    // Extract eligibility_details from the response
                    const eligibilityDetails = response.eligibility_details;
    
                    // Create a table element
                    const customTable = document.createElement('table');
                    customTable.classList.add('custom-table');
    
                    // Define headers and corresponding data keys
                    const headerDataPairs = [
                        { header: 'Study Name', key: 'study_name' },
                        { header: 'Site Name', key: 'site_name' },
                        { header: 'Site Number', key: 'site_number' },
                        { header: 'OIRRC Site Code', key: 'oirrc_site_code' },
                        { header: 'Subject Code', key: 'subject_code' },
                        { header: 'Screened Eye(s)', key: 'enrolled_eye' },
                        { header: 'Visit Name', key: 'visit_name' },
                        { header: 'Visit Date', key: 'visit_date' },
                    ];
    
                    // Insert headers and corresponding data into the table
                    for (let i = 0; i < headerDataPairs.length; i += 2) {
                        // Create table row
                        const row = customTable.insertRow();
    
                        // Insert cells for two columns or fewer if reaching the end
                        for (let j = i; j < i + 2 && j < headerDataPairs.length; j++) {
                            const pair = headerDataPairs[j];
                            // Create cell for header
                            const headerCell = row.insertCell();
                            headerCell.textContent = pair.header;
                            headerCell.style.fontWeight = 'bold'; // Set header text to bold
                            headerCell.style.borderBottom = '1px solid #ddd';
    
                            // Create cell for corresponding data
                            const dataCell = row.insertCell();
                            dataCell.textContent = eligibilityDetails[pair.key];
                            dataCell.style.borderBottom = '1px solid #ddd';
                        }
                        // Insert an empty row between pairs, except for the last pair
                        if (i + 2 < headerDataPairs.length) {
                            customTable.insertRow().innerHTML = '<td colspan="2" style="height: 20px;"></td>';
                        }
                    }
    
                    // Create a custom header element for study details
                    const studyDetails = document.createElement('div');
                    studyDetails.classList.add('custom-header');
                    studyDetails.textContent = 'Study And Subject Details';
                    studyDetails.style.padding = '10px';
                    studyDetails.style.textAlign = 'center';
                    studyDetails.style.fontWeight = 'bold';
                    studyDetails.style.marginBottom = '0px';
                    studyDetails.style.fontSize = '22px'; 
    
                    // Create a wrapper element
                    const wrapper = document.createElement('div');
                    wrapper.style.textAlign = 'center'; // Center align all elements
                    wrapper.style.marginBottom = '5px'; // Space between elements
    
                    // Create a logo element
                    const logo = document.createElement('img');
                    const basePath = document.querySelector('meta[name="asset-base-path"]').getAttribute('content');
                    logo.src = basePath;
                    logo.style.display = 'block';
                    logo.style.margin = '0 auto 10px auto';
                    logo.style.width = '250px'; // Set the desired width
                    logo.style.height = 'auto'; // Maintain aspect ratio
    
                    // Set up the onload event to append elements only after the logo is fully loaded
                    logo.onload = () => {
                        
                        // Append elements to the wrapper
                        wrapper.appendChild(logo);
                        wrapper.appendChild(studyDetails);
    
                        // Create a space between the header and the table
                        const headerSpace = document.createElement('div');
                        headerSpace.style.height = '20px'; // Adjust the height as needed
                        wrapper.appendChild(headerSpace);
                        wrapper.appendChild(customTable);
                        // Apply CSS to style the table
                        customTable.style.width = '100%'; // Adjust as needed
                        customTable.style.borderCollapse = 'collapse'; // Optional
    
                        // Prepend the wrapper to the document body
                        document.body.prepend(wrapper);
    
                        // Remove elements to hide before printing
                        const elementsToRemove = [
                            {
                                class: '.printhide',
                                elements: document.querySelectorAll('.printhide')
                            },
                            {
                                class: '.printhidenav',
                                elements: document.querySelectorAll('.printhidenav')
                            },
                            {
                                class: '.printfoothide',
                                elements: document.querySelectorAll('.printfoothide')
                            },
                            {
                                class: '.printsidehide',
                                elements: document.querySelectorAll('.printsidehide')
                            },
                            // Add more objects for other classes as needed
                        ];
    
                        elementsToRemove.forEach(obj => {
                            obj.elements.forEach(element => {
                                element.remove();
                            });
                        });
    
                        // Trigger the browser's print functionality
                        window.print();
    
                        // Remove the wrapper after printing
                        wrapper.remove();
                    };
    
                    // Handle image load errors
                    logo.onerror = () => {
                        console.error('Failed to load the logo');
                    };
    
                })
                .catch(error => {
                    console.error(error);
                    // Handle errors if any
                });
        }
    }
    
    

    getFormData(stackId) {
        const url = `${document.location.origin}/studies/${this.eligibilitystudyId}/eligibility_reviews/get_eligibility_form_details`;
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'POST',
            body: JSON.stringify({
                stack_id: stackId
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                // Parse JSON response
                return response.json();
            })
            .then(data => {
                // Handle the data received from the server
                return data; // Return the data if necessary
            })
            .catch(error => {
                console.error(error);
                // Handle errors if any
            });
    }


}
