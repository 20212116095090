import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['form', 'parentSelect', 'childSelect', 'devices', 'grandfathering', 'oldCertificate', 'oldCertificateCheck', 'FileUploadTypeInput', 'pdfType', 'imageType', 'partialButton', 'hideNumberSets', 'selectedSitevalue', 'expeditedSelect', 'imagePartSelect', 'formSubmitSelect', 'deviceSerialNumber', 'deviceSoftwareVersion', 'loadingImage'];

    async initialize() {
        this.selectChangeUrl = document.location.origin + `/modalities/${this.parentSelectTarget.value}/certification_request_child_modalities.json`;
        if (this.data.get('mode') == "reload") {
            this.populateChildSelect(true);
        }
    }
    handleParentSelectChange() {
        if (this.parentSelectTarget.value != "") {
            this.devicesTarget.removeAttribute("disabled");
            this.childSelectTarget.removeAttribute("disabled");
            this.populateChildSelect();
        }
    }
    checkNumbers(event) {
      const value = parseInt(event.target.value);
      const partialButton = this.partialButtonTarget;
      const elements = document.getElementsByClassName('all-btns');
      for (let i = 0; i < elements.length; i++) {
        elements[i].click();
      }
      for (let i = 1; i <= value; i++) {
        partialButton.click();
      }
      document.getElementById("generate_for_same_device").checked = false;
      document.getElementById("device_serial_software_same").style.display = 'none';
    }
    populateChildSelect(initialize = false) {
        this.selectChangeUrl = document.location.origin + `/modalities/${this.parentSelectTarget.value}/certification_request_child_modalities.json`;
        const childModalityId = this.data.get('childModalityId');
        const deviceId = this.data.get('deviceId');
        const study_id = this.data.get('study');
        const certificateId = "";
        const request_type = this.data.get("controllers");
        // populate child modalities
        fetch(this.selectChangeUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ study_id: study_id, device_request: request_type }),
            method: 'POST',
        })
            .then(response => response.json())
            .then((data) => {
                const selectBox = this.childSelectTarget;
                selectBox.innerHTML = '';
                // default text
                const defaultOption = document.createElement('option');
                defaultOption.value = "";
                defaultOption.innerHTML = "Select Child Modality";
                defaultOption.selected = childModalityId === "";
                selectBox.appendChild(defaultOption);
                // loop through data
                data["child_modalities"].forEach((item, index) => {
                    const opt = document.createElement('option');
                    opt.value = item[0];
                    opt.innerHTML = item[1];
                    opt.selected = childModalityId === item[0];
                    selectBox.appendChild(opt);
                });
                const deviceselectBox = this.devicesTarget;
                deviceselectBox.innerHTML = '';
                // default text
                const devicesdefaultOption = document.createElement('option');
                devicesdefaultOption.value = "";
                devicesdefaultOption.innerHTML = "Select Device";
                devicesdefaultOption.selected = deviceId === "";
                deviceselectBox.appendChild(devicesdefaultOption);
                // loop through data
                data["devices"].forEach((item, index) => {
                    const opt = document.createElement('option');
                    opt.value = item["id"];
                    opt.innerHTML = item["name"];
                    opt.selected = deviceId === item["id"];
                    deviceselectBox.appendChild(opt);
                }); 
                const certificateselectBox = this.oldCertificateCheckTarget;
                certificateselectBox.innerHTML = '';
                // default text
                const certificatedefaultOption = document.createElement('option');
                certificatedefaultOption.value = "";
                certificatedefaultOption.innerHTML = "Select Certificate";
                certificatedefaultOption.selected = certificateId === "";
                certificateselectBox.appendChild(certificatedefaultOption);
                // loop through data
                data["certification_id"].forEach((item, index) => {
                    const opt = document.createElement('option');
                    opt.value = item[0];
                    opt.innerHTML = item[1];
                    opt.selected = certificateId === item[0];
                    certificateselectBox.appendChild(opt);
                });
                const staticOption = document.createElement('option');
                staticOption.value = "00000000-0000-0000-0000-000000000000";
                staticOption.innerHTML = "Other";
                certificateselectBox.appendChild(staticOption);
            });
    }
    dependentSelect() {
        const modalitycheck = this.parentSelectTarget;
        const selectedSiteValue = this.selectedSitevalueTarget.value;
        if (selectedSiteValue !== "") {
            modalitycheck.removeAttribute("disabled");
        }
    }
    deviceSelectCheck() {
        if (this.devicesTarget.value != "") {
            this.compareWithCertificationRequests();
        }
    }
    serialNoChecked() {
        const checkbox = document.getElementById("serial_number_checkbox").checked
        if (checkbox === true)
        {
            document.getElementById("show_serial_number_field").style.display = "block"
            document.getElementById("ExistingSerialNumber").style.display = "none"
        } else {
            document.getElementById("show_serial_number_field").style.display = "none"
            document.getElementById("ExistingSerialNumber").style.display = "block"
        }
    }
    compareWithCertificationRequests() {
        const checkCertificateUrl = document.location.origin + `/studies/${this.data.get('study')}/certification_requests/check_certificate_exist.json`;
        // Make an AJAX request to the backend endpoint
        fetch(checkCertificateUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({
                study_id: this.data.get('study'),
                user_id: this.data.get('userId'),
                devicecheck: this.devicesTarget.value,
                modalitycheck: this.parentSelectTarget.value,
                selected_site_value: this.selectedSitevalueTarget.value,
                device_request: this.data.get("controllers"),
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.certificate_exist === true) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Request for this modality already exists',
                    }).then(() => {
                        location.reload(); // Refresh the page
                    });
                } else {
                    this.childSelectTarget.removeAttribute("disabled");
                    this.expeditedSelectTarget.removeAttribute("disabled");
                    this.grandfatheringTarget.removeAttribute("disabled");
                    this.hideNumberSetsTarget.classList.remove("pointer-events-none");
                    this.formSubmitSelectTarget.classList.remove("pointer-events-none");
                    this.imagePartSelectTarget.classList.remove("pointer-events-none");
                    if (data.device_request === 'true') {
                        document.getElementById("device_serial_software").style.display = "block";
                        const deviceSerialNumber = this.deviceSerialNumberTarget;
                        deviceSerialNumber.innerHTML = '';
                        const defaultOption = document.createElement('option');
                        defaultOption.value = "";
                        defaultOption.innerHTML = "Select A Serial Number";
                        deviceSerialNumber.appendChild(defaultOption);
                        data["serial_no"].forEach((item, index) => {
                            const opt = document.createElement('option');
                            opt.value = item;
                            opt.innerHTML = item;
                            deviceSerialNumber.appendChild(opt);
                        });
                        const staticOption = document.createElement('option');
                        staticOption.value = "Other";
                        staticOption.innerHTML = "Other";
                        deviceSerialNumber.appendChild(staticOption);
                        const deviceSoftwareVersion = this.deviceSoftwareVersionTarget;
                        deviceSoftwareVersion.innerHTML = '';
                        const SVdefaultOption = document.createElement('option');
                        SVdefaultOption.value = "";
                        SVdefaultOption.innerHTML = "Select A Software Version";
                        deviceSoftwareVersion.appendChild(SVdefaultOption);
                        data["software_version"].forEach((item, index) => {
                            const opt = document.createElement('option');
                            opt.value = item;
                            opt.innerHTML = item;
                            deviceSoftwareVersion.appendChild(opt);
                        });
                        const SVstaticOption = document.createElement('option');
                        SVstaticOption.value = "Other";
                        SVstaticOption.innerHTML = "Other";
                        deviceSoftwareVersion.appendChild(SVstaticOption);    
                    } else {
                        const deviceSerialNumber = this.deviceSerialNumberTarget;
                        deviceSerialNumber.innerHTML = '';
                        const defaultOption = document.createElement('option');
                        defaultOption.value = "";
                        defaultOption.innerHTML = "Select A Serial Number";
                        deviceSerialNumber.appendChild(defaultOption);
                        data["serial_no"].forEach((item, index) => {
                            const opt = document.createElement('option');
                            opt.value = item;
                            opt.innerHTML = item;
                            deviceSerialNumber.appendChild(opt);
                        });
                        const staticOption = document.createElement('option');
                        staticOption.value = "Other";
                        staticOption.innerHTML = "Other";
                        deviceSerialNumber.appendChild(staticOption);
                        const deviceSoftwareVersion = this.deviceSoftwareVersionTarget;
                        deviceSoftwareVersion.innerHTML = '';
                        const SVdefaultOption = document.createElement('option');
                        SVdefaultOption.value = "";
                        SVdefaultOption.innerHTML = "Select A Software Version";
                        deviceSoftwareVersion.appendChild(SVdefaultOption);
                        data["software_version"].forEach((item, index) => {
                            const opt = document.createElement('option');
                            opt.value = item;
                            opt.innerHTML = item;
                            deviceSoftwareVersion.appendChild(opt);
                        });
                        const SVstaticOption = document.createElement('option');
                        SVstaticOption.value = "Other";
                        SVstaticOption.innerHTML = "Other";
                        deviceSoftwareVersion.appendChild(SVstaticOption);
                    }
                    
                }
            });
    }
    submitForm(event) { 
        const dateElements = document.querySelectorAll('.all-sets-dates');
        const dateValues = Array.from(dateElements).map(element => element.value);
        const pathologyElements = document.querySelectorAll('.all-sets-pathology');
        const pathologyValues = Array.from(pathologyElements).map(element => element.value);
        const device_request = this.data.get("controllers")
        if (device_request === 'true'){
            const devicecheck = this.deviceSerialNumberTarget.value
            if(devicecheck === '') {
                this.deviceSerialNumberTarget.setAttribute('required', true);
        
            } else if(devicecheck === 'Other') {
                const serial_number = document.getElementById("certification_request_new_serial_number")
                if (serial_number.value === ''){
                    document.getElementById("certification_request_new_serial_number").setAttribute('required', true);
                }
            } else if(devicecheck !== '' && devicecheck !== "Other") {
                document.getElementById("certification_request_new_serial_number").removeAttribute('required');
            }
        }
        if (!dateValues.includes('') && !pathologyValues.includes('')) {
            this.showLoader();
        }
    }
    showLoader() {
        this.loadingImageTarget.classList.add("visible");
    }
    handlegrandfatheringSelectChange() {
        const selectedValue = this.grandfatheringTarget.value;
        if (selectedValue === "Yes") {
            this.oldCertificateTarget.classList.remove("hidden");
            if (this.data.get("controllers") !== 'true'){
                document.getElementById("number-of-times-select").value = 1
            }
            this.hideNumberSetsTarget.classList.add("hidden");
            const elements = document.getElementsByClassName('all-btns');
            for (let i = 0; i < elements.length; i++) {
              elements[i].click();
            }
            const same_request_check_box = document.getElementsByClassName("generate_same_request_checkbox");
            if (same_request_check_box[0] !== undefined){
                document.getElementById("generate_for_same_device").checked = false;
                same_request_check_box[0].style.display = "none";
                document.getElementById("device_serial_software_same").style.display = 'none';
            }
        } else if (selectedValue === "No") {
            this.oldCertificateTarget.classList.add("hidden");
            this.FileUploadTypeInputTarget.classList.remove("hidden");
            this.imageTypeTarget.classList.remove("hidden");
            this.pdfTypeTarget.classList.add("hidden");
            // Set oldCertificateCheckTarget value to empty
            this.oldCertificateCheckTarget.value = "";
            this.hideNumberSetsTarget.classList.remove("hidden");
            document.getElementById("partial_button").click();
            const same_request_check_box = document.getElementsByClassName("generate_same_request_checkbox");
            if (same_request_check_box[0] !== undefined){
                same_request_check_box[0].style.display = "block";
            }
        }
    }
    handleoldCertificateSelectChange() {
        const selectedValue = this.oldCertificateCheckTarget.value;
        if (selectedValue !== "00000000-0000-0000-0000-000000000000") {
            this.FileUploadTypeInputTarget.classList.add("hidden");
        } else if (selectedValue === "00000000-0000-0000-0000-000000000000") {
            this.FileUploadTypeInputTarget.classList.remove("hidden");
            this.imageTypeTarget.classList.add("hidden");
            this.pdfTypeTarget.classList.remove("hidden");
        }
    }
    deviceSelectOther(){
        if (this.deviceSerialNumberTarget.value == "Other") {
            document.getElementById("show_serial_number_field").style.display = "block"
        }
        else {
            document.getElementById("show_serial_number_field").style.display = "none"
            const checkCertificateUrl = document.location.origin + `/studies/${this.data.get('study')}/certification_requests/check_device_certificate_exist.json`;
            // Make an AJAX request to the backend endpoint
            fetch(checkCertificateUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({
                    study_id: this.data.get('study'),
                    user_id: this.data.get('userId'),
                    devicecheck: this.devicesTarget.value,
                    serial_no: this.deviceSerialNumberTarget.value,
                    modalitycheck: this.parentSelectTarget.value,
                    selected_site_value: this.selectedSitevalueTarget.value,
                    device_request: this.data.get("controllers"),
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data === true) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Request for this modality and selected serial number already exists',
                    }).then(() => {
                        location.reload(); // Refresh the page
                    });
                }
            });  
        }
    }
    deviceOtherSoftwareVersion() {
        if (this.deviceSoftwareVersionTarget.value == "Other") {
            document.getElementById("show_software_version_field").style.display = "block"
        }
        else {
            document.getElementById("show_software_version_field").style.display = "none"
        }
    }
    ShowHidedeviceCheckBox() {
        const value = document.getElementById("generate_for_same_device").checked
        const deviceCheckBoxes = document.querySelectorAll(".deviceCheckBox");
        if(value) {
            document.getElementById("device_serial_software_same").style.display = "block";
        }
        else {
            document.getElementById("device_serial_software_same").style.display = "none";
        }
        deviceCheckBoxes.forEach((checkBox) => {
            if (value)
            {
                checkBox.style.display = "block";
            } else {
                checkBox.style.display = "none";
            }
        });

    }
}
