import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["studySiteIdInput"];

  connect() {
    console.log("Connected to Stimulus controller");
  }
//function for updating study_side id when focusout
  updateStudySite() {
    const sId = this.studySiteIdInputTarget.getAttribute('data-s-id');
    const studyId = this.studySiteIdInputTarget.getAttribute('data-study-id');
    const studySiteId = this.studySiteIdInputTarget.value;
    const checkUrl = document.location.origin + `/studies/${studyId}/study_sites`;
  
    fetch(checkUrl, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      method: 'PATCH',
      body: JSON.stringify({
        sId: sId,
        studySiteId: studySiteId,
        studyId: studyId,
      })
    })
    .then(response => {
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Successfully Done',
          showConfirmButton: false,
          timer: 1000
      });  
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'An error occurreds',
          showConfirmButton: false,
          timer: 2000
      });  
      }
    })
  }
  


  

}
