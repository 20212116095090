import { Controller } from 'stimulus';
import { Formio } from 'formiojs';

/**
 * API Docs for Form.io
 * https://github.com/formio/formio.js/wiki
 *
 * Full API Docs
 * https://formio.github.io/formio.js/docs/
 *
 * Examples:
 * https://formio.github.io/formio.js/app/examples/
 *
 */
export default class extends Controller {
    static targets = ['editor', 'preview',];


    async initialize() {
        const editorElem = this.editorTarget;
        const previewElem = this.previewTarget;

        //get data properties
        this.qcFormSaveUrl = document.location.origin + this.data.get('qcSubmitPath') + '.json';
        this.qcFormRedirectUrl = document.location.origin + this.data.get('qcRedirectPath');
        const initialSchema = JSON.parse(this.data.get('qcFormSchema') || '{}');


        // renderer
        this.formRenderer = await Formio.createForm(previewElem, initialSchema);

        this.formRenderer.on('submit', data => {
            console.log('data :', data);
        });

        // builder
        this.formBuilder = await Formio.builder(editorElem, initialSchema, {
            noDefaultSubmitButton: true
        });

        this.formBuilder.on('change', () => {
            // update the preview
            this.formRenderer.setForm(this.formBuilder.schema);
            // save the schema to backend only in edit mode
            if(this.data.get('qcEditMode') == "yes") {
                this.saveFormSchema(this.formBuilder.schema);
            }
        });
        this.formBuilderResponsive();
    }

    formBuilderResponsive() {
        let el = document.querySelector('.formcomponents');
        let el2 = document.querySelector('.formarea');

        el.classList.remove('col-xs-4', 'col-sm-3', 'col-md-2');
        el.classList.add('col-xs-12', 'col-sm-3', 'col-md-3', 'col-lg-3');

        el2.classList.remove('col-xs-8', 'col-sm-9', 'col-md-10');
        el2.classList.add('col-xs-12', 'col-sm-9', 'col-md-9', 'col-lg-9');
    }

    saveFormSchema(schema) {
        const method = this.data.get('qcEditMode') == "yes" 
                      ? "PATCH" : "POST" ;
        // send request
        fetch(this.qcFormSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: method,
            body: JSON.stringify({
                form_template: {
                    qc_form: schema
                }
            })
        }).then(response => {
            if (method == "POST") {
               window.location = this.qcFormRedirectUrl;
               return false;
           }
           console.log(response);
        });
    }

    async changeHandler(event) {
        // get json form from template dropdown
        const formSchema = JSON.parse(event.target.value);
        // renderer
        this.formRenderer = await Formio.createForm(this.previewTarget, formSchema);
        // builder
        this.formBuilder = await Formio.builder(this.editorTarget, formSchema, {
            noDefaultSubmitButton: true
        });
        // change event
        this.formBuilder.on('change', () => {
            // update the preview
            this.formRenderer.setForm(this.formBuilder.schema);
        });
        // save the schema to backend
        this.formBuilderResponsive();

    }

    submitForm() {
        // submit the form
        this.saveFormSchema(this.formBuilder.schema);
    }
}