import Hammer from 'hammerjs';
import * as cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.configure({
    beforeSend: function (xhr) {

        // Add custom headers here (e.g. auth tokens)
        //xhr.setRequestHeader('x-auth-token', 'my auth token');
    },
    useWebWorkers: true,
});


// const RIGHT_CLICK = 2;



const {
    PanTool,
    WwwcTool,
    ZoomMouseWheelTool,
    LengthTool,
    ArrowAnnotateTool,
    AngleTool,
    EllipticalRoiTool,
    EraserTool,
    RectangleRoiTool,
    FreehandRoiTool,
    StackScrollMouseWheelTool
} = cornerstoneTools;

const LEFT_CLICK = 1;
const MIDDLE_CLICK = 4;

export default class CornerstoneCommonFunctions {
    constructor(element, imageTagElement = null, annotation = []){
        this.element = element;
        this.annotation = annotation;
        this.imageTagElement = imageTagElement
    } 

    dataSetGlobal = [];
    annotationIsLoadedOnce = {};
    imageTagList = [{ "label": "Study Date", "hex": 'x00080020' },
        { "label": "Study Time", "hex": 'x00080030' },
        { "label": "Operators Name", "hex": 'x00081070' },
        { "label": "Manufacturer's Model Name", "hex": 'x00081090' },
        { "label": "Patient ID", "hex": 'x00100020' },
        { "label": "Device Serial Number", "hex": 'x00181000' },
        { "label": "Software Version(s)", "hex": 'x00181020' },
        { "label": "Image Laterality", "hex": 'x00200062' }];
   
    enableCornerstoneElement (){ 
        cornerstoneWebImageLoader.external.cornerstone = cornerstone;
        cornerstoneTools.external.cornerstone = cornerstone;
        cornerstoneTools.external.Hammer = Hammer;
        cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
        cornerstoneTools.init({
            mouseEnabled: true,
            touchEnabled: true,
            globalToolSyncEnabled: true,
            showSVGCursors: true

        });

        cornerstoneTools.loadHandlerManager.setErrorLoadingHandler(
            (element, imageId, error) => {
                console.error(imageId);
                // throw error;
            }
        );
        cornerstone.enable(this.element);
    }

    imageLoader(imageUrl) {

        imageUrl = document.location.origin + '/' + imageUrl;
        this.imageTagElement.innerHTML = "";
        if (imageUrl) {

            let imageIds = [];
            let imageIdRoot = 'wadouri:' + imageUrl;
            let stack = {};
            // http:
            if (imageUrl.includes('.dcm')) {

                cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.load(imageUrl, cornerstoneWADOImageLoader.internal.xhrRequest).then((dataSet) => {
                    // dataset is now loaded, get the # of frames so we can build the array of imageIds
                    //(0028,0008)	IS	Number of Frames is the tag that defines the number of frames as per dicomliberary which means this will not change
                    this.dataSetGlobal = dataSet;
                    let numFrames = dataSet.intString('x00280008');
                    this.imageTagList.forEach(t => {
                        if (dataSet.intString(t.hex)) {
                            this.imageTagElement.innerHTML += `<div>${t.label} : ${dataSet.intString(t.hex)}</div>`;
                        }

                    })

                    if (!numFrames) {

                        imageIds.push(imageIdRoot);

                    } else {

                        for (var i = 0; i < numFrames; i++) {
                            imageIds.push(imageIdRoot + "?frame=" + i);
                        }


                    }

                    stack = {
                        currentImageIdIndex: 0,
                        imageIds: imageIds
                    };

                    console.log("imageIds :", imageIds);

                    if (imageIds.length > 2) {

                        cornerstone.loadAndCacheImage(imageIds[0]).then((image) => {

                            // now that we have an image frame in the cornerstone cache, we can decrement
                            // the reference count added by load() above when we loaded the metadata.  This way
                            // cornerstone will free all memory once all imageId's are removed from the cache

                            cornerstone.displayImage(this.element, image);
                            cornerstoneTools.addStackStateManager(this.element, ["stack"]);
                            cornerstoneTools.addToolState(this.element, "stack", stack);

                            stack.imageIds.forEach((imageId) => {
                                this.loadExistingAnnotationsForImage(imageId);
                            });

                            this.addCornerstoneTools();
                            cornerstoneTools.addTool(StackScrollMouseWheelTool);
                            cornerstoneTools.setToolActive('StackScrollMouseWheel', {});

                        }, function (err) {
                            console.log('loadAndCacheImage error', err);
                        });
                    }
                    else {

                        cornerstone.loadAndCacheImage(imageIds[0]).then((image) => {
                            cornerstone.displayImage(this.element, image);
                            this.addCornerstoneTools();

                        }, function (err) {
                            console.log('loadImage error', err.message);
                        });
                    }

                });

            }
            else {

                cornerstone.loadAndCacheImage(imageUrl).then(image => {
                    cornerstone.displayImage(this.element, image);
                    this.addCornerstoneTools(imageUrl);
                    this.loadExistingAnnotationsForImage(imageUrl);
                });

            }

        }
    }

    loadExistingAnnotationsForImage(url) {
        if (this.annotation[url] && !this.annotationIsLoadedOnce[url]) {
            cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(url, this.annotation[url]);
            this.annotationIsLoadedOnce[url] = true;
            this.refreshTools();
        }
    }

    refreshTools() {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        tools.forEach(tool => {
            cornerstoneTools.setToolActive(tool, { mouseButtonMask: LEFT_CLICK });
        });
    }

    addCornerstoneTools() {


        // Activate Pan Tool on Middle Click Drag
        cornerstoneTools.addTool(PanTool);
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: MIDDLE_CLICK });

        // Activate WWWc tool on Left Click Drag
        cornerstoneTools.addTool(WwwcTool);
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });


        // Activate Zoom Mouse Wheel Tool
        cornerstoneTools.addTool(ZoomMouseWheelTool, {
            // Optional configuration
            configuration: {
                invert: false,
                preventZoomOutsideImage: false,
                minScale: 0.01,
                maxScale: 20.0
            }
        });
        cornerstoneTools.setToolActive('ZoomMouseWheel', {});

        

        // register cornerstone tools events
        // https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/events.js
        this.element.addEventListener('cornerstonetoolsmeasurementcompleted', e => {
            console.log(`------${e.detail.toolName}-----`);
            console.table(e.detail.measurementData);
        });

        this.refreshTools();
    }

    addGradingTools(){
        // Add Non Active tools
        cornerstoneTools.addTool(LengthTool);

        cornerstoneTools.addTool(ArrowAnnotateTool);

        cornerstoneTools.addTool(AngleTool);

        cornerstoneTools.addTool(RectangleRoiTool);

        cornerstoneTools.addTool(FreehandRoiTool);

        cornerstoneTools.addTool(EraserTool);

        //cornerstoneTools.addTool(cornerstoneTools.ProbeTool);

        cornerstoneTools.addTool(EllipticalRoiTool, {
            configuration: {
                drawHandlesOnHover: false,
                hideHandlesIfMoving: true,
                renderDashed: false
            }
        });
    }

}
    

