import consumer from "./consumer";
consumer.subscriptions.create("GradingStatusChannel", {
  received(data) {
    const chatContainer = document.getElementById('old_study');
    const stack_old_study_id = document.getElementById('stack_old_study_id');
    const stack_subject_id = document.getElementById('stack_subject_id');
 
    if (stack_old_study_id){
      const stackStudyId = stack_old_study_id.innerText.trim();  
      const subjectId = stack_subject_id.innerText.trim();  
      if (data.study_id == stackStudyId) {
        const stack_index_url = `${document.location.origin}/studies/${data.study_id}/subjects/${subjectId}/stacks`;
        console.log('hi');
     fetch(stack_index_url, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(response => response.text())
    .then(data => {
      const parsedData = new DOMParser().parseFromString(data, 'text/html');
      const updatedContent = parsedData.querySelector('#your-view-element');
      if(updatedContent){
        const innerHTML = updatedContent.innerHTML;
        if(innerHTML){
      document.getElementById('your-view-element').innerHTML = innerHTML;
      }
    }
      const updatedaddContent = parsedData.querySelector('#your-additional-view-element');
      if(updatedaddContent){
        const innerHTML = updatedaddContent.innerHTML;
        if(innerHTML){
            document.getElementById('your-additional-view-element').innerHTML = innerHTML;
        }
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
      }
    }
    if (chatContainer){
    const studyId = chatContainer.innerText.trim();  
    if (data.study_id == studyId) {
    const url = `${document.location.origin}/studies/${data.study_id}/worklists/grading_worklist`;
    fetch(url, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    }).then(html => {
      // Update the table with the new HTML content
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      // Get the tbody element from the received HTML
      const newTBody = tempDiv.querySelector('tbody');
      if (newTBody) {
        // Get the existing tbody element from the page
        const oldTBody = document.querySelector('#your-view-element tbody');
        if (oldTBody) {
          // Replace the contents of the existing tbody with the new one
          oldTBody.innerHTML = newTBody.innerHTML;
         
        } else {
          console.error('Existing tbody element not found');
        }
      } else {
        console.error('New tbody element not found in the received HTML');
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }
} 
} 
});