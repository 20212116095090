import { Controller } from "stimulus";

export default class extends Controller {
  async initialize() {
    this.modalitiesFormSaveUrl = document.location.origin + this.data.get("modalitiesSubmitPath") + ".json";
    this.modalitiesApiUrl      = this.data.get("modalitiesApiUrl");
    this.clientId         = this.data.get("clientId");
    this.clientSecret     = this.data.get("clientSecret");
  }

  importModalities(event, skip = 0, take = 50, count = 1, start = 0) {
    if (start == 0 || skip < count) {
      this.getModalities(skip, take, start);
      return;
    }
    window.location.reload();
  }

  getModalities(skip, take, start) {
    // query string
    let params = {
      "skip": skip,
      "take": take
    };
    
    let query = Object.keys(params)
                 .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                 .join('&');
    // send request
    fetch(this.modalitiesApiUrl+ "?" + query, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Client-Id": this.clientId,
        "Client-Secret": this.clientSecret,
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        this.pushModalities(result.data, skip, take, start);
      });
  }

  pushModalities(responseData, skip, take, start) {
    // send request
    fetch(this.modalitiesFormSaveUrl, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": document.head
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      method: "POST",
      body: JSON.stringify({
        import_data: {
          modalities: responseData.data,
        },
      }),
    }).then((response) => {
      skip = take;
      take = take + 50;
      start = start + 1;
      var count = responseData.count;
      this.importModalities("", skip, take, count, start);
    });
  }
}
