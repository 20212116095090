import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import Dropzone from "dropzone"
import { getMetaValue, findElement, removeElement, insertAfter } from "../helpers/dropzone_helpers"

Dropzone.autoDiscover = false

export default class extends Controller {
    static targets = ["input"]

    connect() {
        this.dropZone = createDropZone(this)
        this.hideFileInput()
        this.bindEvents()
        window.uploadImageCounter = 0;
        window.responseCounter = 0;
        window.fetchResponses = [];
    }

    // Private
    hideFileInput() {
        this.inputTarget.disabled = true
        this.inputTarget.style.display = "none"
    }

    bindEvents() {
        this.dropZone.on("addedfile", (file) => {
            setTimeout(() => { file.accepted && createDirectUploadController(this, file).start() }, 500)
        })

        this.dropZone.on("removedfile", (file) => {
            file.controller && removeElement(file.controller.hiddenInput)
            this.emitRemoveEvent(file)
        })

        this.dropZone.on("canceled", (file) => {
            file.controller && file.controller.xhr.abort()
        })

        this.dropZone.on("processing", (file) => {
            this.submitButton.disabled = true
        })

        this.dropZone.on("queuecomplete", (file) => {
            this.submitButton.disabled = false
        })

        this.dropZone.on("success", (file) => {
            this.emitSuccessEvent(file);
        })
    }

    emitSuccessEvent(file) {

        const event = new CustomEvent("successFileUpload", {
          detail: {
            message: "Hello from Sender Controller",
            fileName: file.name,
            questionName: this.inputTarget.getAttribute('data-question')
          },
          bubbles: true,
        });
        this.element.dispatchEvent(event)
    }

    emitRemoveEvent(file) {
        const event = new CustomEvent("removeFile", {
          detail: {
            fileName: file.name,
            questionName: this.inputTarget.getAttribute('data-question')
          },
          bubbles: true,
        });
        this.element.dispatchEvent(event)
    }

    get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }

    get url() { return this.inputTarget.getAttribute("data-direct-upload-url") }

    get maxFiles() { return this.data.get("maxFiles") || 1 }

    get maxFileSize() { return this.data.get("maxFileSize") || 5000000000 }

    get acceptedFiles() { return this.data.get("acceptedFiles") }

    get addRemoveLinks() { return this.data.get("addRemoveLinks") || true }

    get form() { return this.element.closest("form") }

    get submitButton() { return findElement(this.form, "input[type=submit], button[type=submit]") }

}

class DirectUploadController {
    constructor(source, file) {
        this.directUpload = createDirectUpload(file, source.url, this)
        this.source = source
        this.file = file
        this.iolUrl = this.source.element.dataset.dropzoneIolUrl;
    }

    start() {
        this.file.controller = this
        this.hiddenInput = this.createHiddenInput()
        this.directUpload.create((error, attributes) => {
            if (error) {
                removeElement(this.hiddenInput)
                this.emitDropzoneError(error)
            } else {
                this.hiddenInput.value = attributes.signed_id
                this.emitDropzoneSuccess(attributes, this.iolUrl)
            }
        })
    }

    // Private
    createHiddenInput() {
        const input = document.createElement("input")
        input.type = "hidden"
        input.name = this.source.inputTarget.name
        input.classList.add("attached_images");
        insertAfter(input, this.source.inputTarget)
        return input
    }

    directUploadWillStoreFileWithXHR(xhr) {
        this.bindProgressEvent(xhr)
        this.emitDropzoneUploading()
    }

    bindProgressEvent(xhr) {
        this.xhr = xhr
        this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
    }

    uploadRequestDidProgress(event) {
        const element = this.source.element
        const progress = event.loaded / event.total * 100
        findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
    }

    emitDropzoneUploading() {
        window.uploadImageCounter += 1;
        this.file.status = Dropzone.UPLOADING
        this.source.dropZone.emit("processing", this.file)
    }

    emitDropzoneError(error) {
        this.file.status = Dropzone.ERROR
        this.source.dropZone.emit("error", this.file, error)
        this.source.dropZone.emit("complete", this.file)
    }

    emitDropzoneSuccess(response, apiUrl) {
        this.file.status = Dropzone.SUCCESS
        this.source.dropZone.emit("success", this.file)
        this.source.dropZone.emit("complete", this.file)
        //allowStackCreate(false);
        //callAPIFunction(this.xhr.responseURL, response.id.toString(), apiUrl);
    }
}
function callAPIFunction(url, id, apiUrl, fetchImageValues) {
    fetch(apiUrl, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        method: 'POST',
        body: JSON.stringify({
            image: url,
            models: [
                'magnification',
                'focus',
                'artifacts',
                'exposure'
            ]
        })
    }).then(response => {
        return response.json();
    }).then((data) => {
        const keyValues = Object.entries(data);
        keyValues.splice(0, 0, ['blob_id', id]);
        const newObj = Object.fromEntries(keyValues);
        window.fetchResponses.push(newObj);
        window.responseCounter += 1;
        if (window.uploadImageCounter <= window.responseCounter) {
            allowStackCreate(true);
        }
    });
}
// Top level...
function createDirectUploadController(source, file) {
    return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
    return new DirectUpload(file, url, controller)
}
function allowStackCreate(value) {
    if (value === true) {
        document.getElementById('getResponse').value = JSON.stringify(window.fetchResponses);
        document.getElementById('otherBtn').style.display = 'none';
        document.getElementById('stackBtn').style.display = 'block';
    } else if (value === false) {
        document.getElementById('stackBtn').style.display = 'none';
        document.getElementById('otherBtn').style.display = 'block';
    }
}
function createDropZone(controller) {
    return new Dropzone(controller.element, {
        url: controller.url,
        headers: controller.headers,
        maxFiles: controller.maxFiles,
        maxFilesize: controller.maxFileSize,
        acceptedFiles: controller.acceptedFiles,
        addRemoveLinks: controller.addRemoveLinks,
        autoQueue: false
    })
}