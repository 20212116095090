import { Controller } from 'stimulus';

export default class extends Controller {

    static targets = ['alertRed', 'alertGreen', 'subjectId'];

    async initialize() {
        this.apiUrl = `${this.data.get("apiUrl")}iol/register-images`;
        this.apiKey = this.data.get("apiKey");
        this.iolImageCreateUrl = document.location.origin + this.createIolImageUrl + '.json';
        this.registeredStacks = this.iolImages.map(o => o.stack_id) || [];
    }

    get iolImages(){ return JSON.parse(this.data.get('iolImages')) || [] }     
    get createIolImageUrl(){ return this.data.get('createUrl') || '' }     
    
    refImage(e){
        let className = e.target.className;
        let imageBlobId = e.target.dataset.imageBlobId;
        
        if (e.target.checked) { 
            document.getElementById(`base-image-${imageBlobId}`).classList.remove("hidden"); 
        } else {            
            document.querySelectorAll(`.${className}`).forEach(element => {
                element.disabled = false
            });
            document.getElementById(`base-image-${imageBlobId}`).classList.add("hidden");
            document.getElementById(`${imageBlobId}`).checked = false;
        }
        
    }

    setBaseImage(e){
        console.log('baseimage :', e.target.checked)
        if (e.target.checked) {
            document.querySelectorAll(`.base-image`).forEach(element => {    
                if (!element.checked) {
                    element.disabled = true
                }
                
            });
        } else {
            document.querySelectorAll(`.base-image`).forEach(element => {    
                element.disabled = false
            });
        }
        

    }

    registerImages(){
        
        let iolRegisterationObj = [];
        let userNotified = false;
        let overwriteRegistration = false;
        document.querySelectorAll(`[name="ref-image"]`).forEach(element => {    
            if (element.checked) {
                
                let imageObj = {};
                let imgElem = document.getElementById(`image-${element.dataset.imageBlobId}`);
                let baseImg = document.getElementById(`${element.dataset.imageBlobId}`);
                imageObj.stack_id = element.dataset.stack;
                imageObj.image_url = `${document.location.origin}${imgElem.dataset.url}`;
                imageObj.blob_id = element.dataset.imageBlobId;
                imageObj.subject_id = element.dataset.subject;
                (baseImg.checked)? imageObj.is_base_image = true: imageObj.is_base_image = false;
                
                iolRegisterationObj.push(imageObj);
                if (this.registeredStacks.includes(imageObj.stack_id) && !userNotified && !imageObj.is_base_image) {
                    if (confirm("This action will overwrite a previous registration for IOL Image") == true ) overwriteRegistration = true ;
                    userNotified = true;
                }
            }
            
        });
        
        if (!userNotified && iolRegisterationObj.length) {
            this.sendAPICall(iolRegisterationObj);
        } else if(userNotified && overwriteRegistration && iolRegisterationObj.length){
            this.sendAPICall(iolRegisterationObj);
        }
        
    }

    createIolImages(iolRegisterationObj){
        const urlParams = location.href.split('/');
        const studyModalityId = urlParams[urlParams.length - 2];
        
        iolRegisterationObj.forEach( registrationObj => {

            if (!registrationObj.is_base_image) {
                registrationObj.subject_id = registrationObj.subject_id;
                registrationObj.study_modality_id = studyModalityId;
                

                if (this.registeredStacks.includes(registrationObj.stack_id)) {
                    registrationObj.id = this.iolImages.filter(s => s.stack_id === registrationObj.stack_id )[0].id;
                    registrationObj.is_processed = false;
                    fetch(`${this.createIolImageUrl}/${registrationObj.id}.json`, { 
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                        },
                        method: 'PATCH',
                        body: JSON.stringify({
                            iol_image: registrationObj
                        })
                    }).then(response => {
                        console.log('patch response :', response);
                    });
                }
                else{
                    fetch(this.iolImageCreateUrl, { 
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                        },
                        method: 'POST',
                        body: JSON.stringify({
                            iol_image: registrationObj
                        })
                    }).then(response => {
                        console.log('response :', response);
                    });
                }
                
            }
            
        });

    }

    sendAPICall(iolRegisterationObj){
        fetch(this.apiUrl, { 
            headers: {
                'X-API-KEY' : this.apiKey,
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: 'POST',
            body: JSON.stringify({
                iol_images: iolRegisterationObj
            })
        }).then(response => {
            
            if (response.status == 200){
                this.successAlert(); 
                this.createIolImages(iolRegisterationObj);
            }
            else{
                this.errorAlert(); 
            }
            
        });
    }

    successAlert(){
        window.dispatchEvent(new CustomEvent('successAlert'));
    }

    errorAlert(){
        window.dispatchEvent(new CustomEvent('errorAlert'));
    }

}