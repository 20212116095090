import { Controller } from 'stimulus';

export default class extends Controller {

    static targets = ['dashboardSubMenu', 'systemTools', 'studyTools', 'dataManagement', 'adjTools', 'auditTrails', 'iolGrading', 'randomImgGrading', 'certificationRequest', 'certificationSetup', 'certificationTemplate', 'eligibilityTools', 'assignedwork', 'worklist', 'transmissionLogs'];
    navigate_assignworks() {
        this.assignedworkTarget.classList.toggle("hidden");
    }
    navigate_worklist() {
        this.worklistTarget.classList.toggle("hidden");
    }

    navigate_dashboard_menu() {
        this.dashboardSubMenuTarget.classList.toggle("hidden");
    }
    navigate_menu() {
        this.systemToolsTarget.classList.toggle("hidden");
    }
    navigate_study_tools() {
        this.studyToolsTarget.classList.toggle("hidden");
    }

    navigate_data_management() {
        this.dataManagementTarget.classList.toggle("hidden");
    }
    navigate_certification_request() {
        this.certificationRequestTarget.classList.toggle('hidden');
    }
    navigate_certification_template() {
        this.certificationTemplateTarget.classList.toggle('hidden');
    }
    navigate_adjudication() {
        this.adjToolsTarget.classList.toggle("hidden");
    }

    navigate_audit_trail() {
        this.auditTrailsTarget.classList.toggle("hidden");
    }

    navigate_iol_grading() {
        this.iolGradingTarget.classList.toggle("hidden");
    }

    navigate_random_images_grading() {
        this.randomImgGradingTarget.classList.toggle("hidden");
    }

    navigate_certification_setup() {
        this.certificationSetupTarget.classList.toggle("hidden");
    }

    navigate_eligibility() {
        this.eligibilityToolsTarget.classList.toggle("hidden");
    }

    navigate_transmission_logs() {
        this.transmissionLogsTarget.classList.toggle("hidden");
    }

}