import { Controller } from 'stimulus';
import { Formio } from 'formiojs';
import FormioUtils from 'formiojs/utils';

/**
 * API Docs for Form.io
 * https://github.com/formio/formio.js/wiki
 *
 * Full API Docs
 * https://formio.github.io/formio.js/docs/
 *
 * Examples:
 * https://formio.github.io/formio.js/app/examples/
 *
 */

import Hammer from 'hammerjs';
import * as cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';

import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import utils from 'formiojs/utils';

const {
    PanTool,
    WwwcTool,
    ZoomMouseWheelTool,
    LengthTool,
    ArrowAnnotateTool,
    AngleTool,
    EllipticalRoiTool,
    EraserTool,
    RectangleRoiTool,
    FreehandRoiTool
} = cornerstoneTools;

const LEFT_CLICK = 1;
const MIDDLE_CLICK = 4;
// const RIGHT_CLICK = 2;

export default class extends Controller {
    static targets = ['viewer', 'url', 'form', 'path', 'agreement', 'users', 'regradingForm', 'regraders', 'formSection', 'cornerstone', 'cornerstoneIcon', 'questionArea', 'submit', 'update']

    async initialize() {
        // Define Targets
        const elem = this.viewerTarget;
        this.gradingFormElem = this.formTarget;
        this.gradingFormAgreementElem = this.agreementTarget;
        this.annotationUsersElem = this.usersTarget;
        this.regradersElems = this.regradersTargets;
        this.regradingFormElem = this.regradingFormTarget;
        this.formSection = this.formSectionTarget;
        this.cornerstoneViewer = this.cornerstoneTarget;

        this.viewerElement = elem;
        this.gradingSaveUrl = document.location.origin + this.data.get('updatePath') + '.json';
        this.regradingUrl = document.location.origin + this.data.get('regradePath') + '.json';
        this.images = [];
        this.currentImage = 0;

        // load previously saved grading data if available
        this.annotation = JSON.parse(this.data.get('annotation'));
        console.log('------adjudicator annotations');
        console.log(this.annotation);
        console.log('------adjudicator annotations');
        this.annotationIsLoadedOnce = {};

        /**
         * Annotations from Graders
         */
        this.graderAnnotations = JSON.parse(this.data.get('graderAnnotations'));
        console.log('------grader annotations');
        console.log(this.graderAnnotations);
        console.log('------grader annotations');
        /**
         * Responses from Graders
         */
        this.graderResponses = JSON.parse(this.data.get('graderResponses'));

        /**
         * Adjudication Form Setup
         */

        this.gradingFormSchema = JSON.parse(this.data.get('gradingForm'));
        this.gradingFormResponses = JSON.parse(this.data.get('responses'));
        this.gradingFormSaveUrl = document.location.origin + this.data.get('formUpdatePath') + '.json';

        // renderer
        this.gradingForm = await Formio.createForm(this.gradingFormElem, this.gradingFormSchema, {
            noAlerts: true
        });

        // set the draft responses if any
        this.gradingForm.submission = this.gradingFormResponses;
        this.formSaveDraftStatus = false;

        // attach event listeners to the form io
        this.gradingForm.on('submit', submission => {
            this.saveGradingForm(submission);
        });

        // make form disabled if adjudication completed
        if (this.gradingFormAgreementElem.checked) {
            this.makeFormDisabled(this.gradingForm);
        } else {
            this.makeFormEnable(this.gradingForm);
        }
        // end

        // show grader responses for each form field
        FormioUtils.eachComponent(this.gradingForm.components, c => {
            const graderAnswersDiv = document.createElement('div');
            // now check for answer to this question in the grader responses
            const questionKey = c.component.key;
            let valToCompare = 'rand1234';
            let match = true;
            this.graderResponses.forEach((r, i) => {
                const br = document.createElement('br');
                const span = document.createElement('span');
                span.classList.add('grader-response');
                span.classList.add('pl-1');
                if (r.responses.data.hasOwnProperty(questionKey)) {
                    if (typeof (r.responses.data[questionKey]) == 'object') {
                        const keys = Object.keys(r.responses.data[questionKey]).filter(k => r.responses.data[questionKey][k])
                        span.append(`Grader ${i + 1}: ${keys.join(', ')}`);
                    } else {
                        span.append(`Grader ${i + 1}: ${r.responses.data[questionKey]}`);
                    }

                    if (valToCompare === 'rand1234') valToCompare = r.responses.data[questionKey];
                    else if (r.responses.data[questionKey] !== valToCompare) match = false;
                } else {
                    span.append(`Grader ${i + 1}: N/A`);
                    match = false;
                }
                graderAnswersDiv.append(span);
                graderAnswersDiv.append(br);
            });
            if (!match) graderAnswersDiv.classList.add('mt-1', 'border', 'rounded', 'border-red-400');
            else graderAnswersDiv.classList.add('mt-1', 'border', 'rounded', 'border-green-400');
            c.element.append(graderAnswersDiv);
        });
        /**
         *
         * Corner Stone Setup
         */
        cornerstoneWebImageLoader.external.cornerstone = cornerstone;
        cornerstoneTools.external.cornerstone = cornerstone;
        cornerstoneTools.external.Hammer = Hammer;
        cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
        cornerstoneTools.init({
            mouseEnabled: true,
            touchEnabled: true,
            globalToolSyncEnabled: true,
            showSVGCursors: true

        });

        cornerstoneTools.loadHandlerManager.setErrorLoadingHandler(
            (element, imageId, error) => {
                console.error(imageId);
                // throw error;
            }
        );

        const imageUrl = document.location.origin + '/' + this.url;
        cornerstone.enable(elem);
        // cornerstone.registerImageLoader = 'dicomweb';
        cornerstone.loadAndCacheImage(imageUrl).then(image => {
            this.loadExistingAnnotationsForImage(imageUrl);

            cornerstone.displayImage(elem, image);

            // Activate Pan Tool on Middle Click Drag
            cornerstoneTools.addTool(PanTool);
            cornerstoneTools.setToolActive('Pan', { mouseButtonMask: MIDDLE_CLICK });

            // Activate WWWc tool on Left Click Drag
            cornerstoneTools.addTool(WwwcTool);
            cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });

            // Activate Zoom Mouse Wheel Tool
            cornerstoneTools.addTool(ZoomMouseWheelTool, {
                // Optional configuration
                configuration: {
                    invert: false,
                    preventZoomOutsideImage: false,
                    minScale: 0.01,
                    maxScale: 20.0
                }
            });
            cornerstoneTools.setToolActive('ZoomMouseWheel', {});

            // Add Non Active tools
            cornerstoneTools.addTool(LengthTool);

            cornerstoneTools.addTool(ArrowAnnotateTool);

            cornerstoneTools.addTool(AngleTool);

            cornerstoneTools.addTool(RectangleRoiTool);

            cornerstoneTools.addTool(FreehandRoiTool);

            cornerstoneTools.addTool(EraserTool);

            cornerstoneTools.addTool(EllipticalRoiTool, {
                configuration: {
                    drawHandlesOnHover: false,
                    hideHandlesIfMoving: true,
                    renderDashed: false
                }
            });

            // register cornerstone tools events
            // https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/events.js
            elem.addEventListener('cornerstonetoolsmeasurementcompleted', e => {
                console.log(`------${e.detail.toolName}-----`);
                console.table(e.detail.measurementData);
            });

            this.refreshTools();
        });

        // attach event listeners
        this.viewerElement.addEventListener('cornerstonenewimage', (e) => {
            // console.log(e);
            e.detail.viewport.displayedArea.brhc.x = e.detail.image.width;
            e.detail.viewport.displayedArea.brhc.y = e.detail.image.height;
        });
    }

    loadExistingAnnotationsForImage(url) {

        let annotation = {};
        if (this.annotationUsersElem.value === 'adjudicator') {
            annotation = this.annotation[url];

        } else {
            annotation = this.graderAnnotations[this.annotationUsersElem.value][url];
        }

        // this check avoids reloads between next and previous loads
        if (annotation && !this.annotationIsLoadedOnce[url]) {
            cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(url, annotation);
            this.annotationIsLoadedOnce[url] = true;
            this.refreshTools();
        }
    }

    refreshTools() {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        tools.forEach(tool => {
            cornerstoneTools.setToolActive(tool, { mouseButtonMask: LEFT_CLICK });
        });
    }

    loadImage(url, id) {

        cornerstone.loadAndCacheImage(url).then((image) => {
            this.loadExistingAnnotationsForImage(url);
            cornerstone.displayImage(this.viewerElement, image);
            cornerstone.enable(this.viewerElement);
        });
    }

    changeGradingUser(e) {

        const currentImageUrl = document.location.origin + '/' + this.url;

        this.annotationIsLoadedOnce = {};// [document.location.origin + currentImageUrl] = null;
        // load image and annotation
        this.loadImage(currentImageUrl);
    }

    /**
     *
     * TODO: Remove function and UI. this feature is not being ued currently
     */
    labelTool(e) {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];

        tools.forEach(tool => {
            cornerstoneTools.setToolEnabled(tool, { mouseButtonMask: LEFT_CLICK });
        });

        this.viewerElement.addEventListener('cornerstonetoolsmousedown', e => {
            const coord = e.detail.currentPoints.canvas;
            const mouseBtn = e.detail.buttons;
            // if its a right click
            if (mouseBtn === 2) {
                tools.forEach(tool => {
                    const state = cornerstoneTools.getToolState(this.viewerElement, tool);
                    if (state) {
                        state.data.forEach(d => {
                            const isNear = cornerstoneTools.getToolForElement(this.viewerElement, tool).__proto__.pointNearTool(this.viewerElement, d, coord);
                            if (isNear) console.log(`${tool} with ${d.uuid} near : ${isNear}`);
                            cornerstoneTools.setToolActive(tool, { mouseButtonMask: LEFT_CLICK });
                            // when near is detected see if we can have the annotation highlited green
                        });
                    }
                });
            }
        });
        e.preventDefault();
    }

    levelsTool(e) {
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    panTool(e) {
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    lengthTool(e) {
        cornerstoneTools.setToolActive('Length', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    arrowAnnotationTool(e) {
        cornerstoneTools.setToolActive('ArrowAnnotate', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    angleTool(e) {
        cornerstoneTools.setToolActive('Angle', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    rectangleTool(e) {
        cornerstoneTools.setToolActive('RectangleRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    freehandTool(e) {
        cornerstoneTools.setToolActive('FreehandRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    ellipseTool(e) {
        cornerstoneTools.setToolActive('EllipticalRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    eraserTool(e) {
        cornerstoneTools.setToolActive('Eraser', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    /**
     * TODO: combing submitGrading and saveGrading functions. They no longer need to be split up
     */
    submitGrading(e) {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        // const annotations = {};

        // https://groups.google.com/g/cornerstone-platform/c/QWHTGgkLufo
        // https:// github.com/cornerstonejs/cornerstoneTools/blob/3925399f72e69f69a4b108be10bfce115eda3247/src/stateManagement/imageIdSpecificStateManager.js
        const annotations = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
        // tools.forEach(tool => {
        //     const toolState = cornerstoneTools.getToolState(this.viewerTarget, tool);
        //     annotations[tool] = toolState;
        // });

        this.saveGrading(annotations);
        e.preventDefault();
    }

    /**
     * TODO: combing submitGrading and saveGrading functions. They no longer need to be split up
     * TODO: Add notification on UI after submission is complete.
     */
    saveGrading(annotations) {
        console.log(annotations);
        fetch(this.gradingSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({
                adjudication: {
                    annotations: annotations
                }
            })
        }).then(response => {
            console.log(response);
        });
    }

    get url() {
        return this.data.get('url');
    }

    // formio functions
    formSaveDraft(e) {
        if (this.gradingForm.submission) this.gradingForm.submission.isAgreed = false;
        // set draft status
        this.formSaveDraftStatus = true;
        this.gradingForm.submit();
        e.preventDefault();
    }

    formCommit(e) {
        const isAgreed = this.gradingFormAgreementElem.checked;
        if (isAgreed) {
            if (this.gradingForm.submission) this.gradingForm.submission.isAgreed = isAgreed;
            this.gradingForm.submit();
        } else {
            alert('You must agree to terms');
        }

        e.preventDefault();
    }

    /**
     * TODO: Add notification on UI after submission is complete.
     */
    saveGradingForm(submission) {
        const show_alert = document.querySelector('.alert-success');
        const msg_alert = document.querySelector('.msg-success');
        // save annotation with form submit
        this.defaultSaveAnnotation();
        fetch(this.gradingFormSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({
                adjudication: {
                    responses: submission,
                    status: this.formSaveDraftStatus ? 'initiated' : 'completed'
                }
            })
        }).then(response => {
            // set draft to false
            if (this.formSaveDraftStatus === false) {
                this.makeFormDisabled(this.gradingForm);
                this.submitTarget.classList.add('hidden');
                this.updateTarget.classList.remove('hidden');
            }
            this.formSaveDraftStatus = false;
            show_alert.classList.remove('hidden');
            msg_alert.innerHTML = "Submitted Successfully!";
            setInterval(function () { show_alert.classList.add('hidden') }, 3000);
        });
    }

    formUpdate(e) {
        fetch(this.gradingFormSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({
                adjudication: {
                    status: 'editing'
                }
            })
        }).then(response => {
            // window.location.reload();
        });
    }

    defaultSaveAnnotation() {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        // const annotations = {};

        // https://groups.google.com/g/cornerstone-platform/c/QWHTGgkLufo
        // https:// github.com/cornerstonejs/cornerstoneTools/blob/3925399f72e69f69a4b108be10bfce115eda3247/src/stateManagement/imageIdSpecificStateManager.js
        const annotations = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();

        this.saveGrading(annotations);
    }

    // disabled form and toggle one section between submit or edit section
    makeFormDisabled(form) {
        form.disabled = true;
        this.submitTarget.classList.add('hidden');
    }

    makeFormEnable(form) {
        form.disabled = false;
        this.updateTarget.classList.add('hidden');
    }

    regrade(e) {
        this.regradingFormElem.classList.toggle('hidden');;
        e.preventDefault();
    }

    confirmRegrade(e) {
        const markedForRegrade = this.regradersElems.filter((c) => c.checked).map(c => c.getAttribute('data-user'));
        if (markedForRegrade.length) { this.saveRegradeRequest(markedForRegrade); }
        e.preventDefault();
    }

    // TODO: Refactor and extract all the ajax calls (3 in this controller) to a common utility and DRY it up
    saveRegradeRequest(userIDs) {
        if (userIDs && userIDs.length) {
            fetch(this.regradingUrl, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: 'PATCH',
                body: JSON.stringify({
                    random_image_grading_form: {
                        user_ids: userIDs
                    }
                })
            }).then(response => {
                console.log(response);
            });
        } else {
            console.log('No users were selected for regrading');
        }
    }

    toggleSubmitArea() {
        var staticPanel = document.getElementById('expend_colapse');
        // console.log(staticPanel.classList.re);
        if (this.questionAreaTarget.classList.contains('hidden')) {
            this.questionAreaTarget.classList.remove('hidden');
            staticPanel.classList.add('fa-minus-square-o');
            staticPanel.classList.remove('fa-plus-square-o');
        } else {
            this.questionAreaTarget.classList.add('hidden');
            staticPanel.classList.add('fa-plus-square-o');
            staticPanel.classList.remove('fa-minus-square-o');
        }
    }

}
