import { Controller } from 'stimulus';

export default class extends Controller {
    refImage(e){
        let className = e.target.className;
        let imageBlobId = e.target.dataset.imageBlobId;
        
        if (e.target.checked) { 
            document.getElementById(`base-image-${imageBlobId}`).classList.remove("hidden"); 
        } else {            
            document.querySelectorAll(`.${className}`).forEach(element => {
                element.disabled = false
            });
            document.getElementById(`base-image-${imageBlobId}`).classList.add("hidden");
            document.getElementById(`${imageBlobId}`).checked = false;
        }
        
    }

    setBaseImage(e){
        console.log('baseimage :', e.target.checked)
        if (e.target.checked) {
            document.querySelectorAll(`.base-image`).forEach(element => {    
                if (!element.checked) {
                    element.disabled = true
                }
                
            });
        } else {
            document.querySelectorAll(`.base-image`).forEach(element => {    
                element.disabled = false
            });
        }
        

    }

    registerImages(){
        
        let iolRegisterationObj = [];
        document.querySelectorAll(`[name="ref-image"]`).forEach(element => {    
            if (element.checked) {
                
                let imageObj = {};
                let imgElem = document.getElementById(`image-${element.dataset.imageBlobId}`);
                let baseImg = document.getElementById(`${element.dataset.imageBlobId}`);
                imageObj.stack_id = element.dataset.stack;
                imageObj.image_url = `${document.location.origin}${imgElem.dataset.url}`;
                (baseImg.checked)? imageObj.is_base_image = true: imageObj.is_base_image = false;
                
                iolRegisterationObj.push(imageObj);
            }
            
        });

        console.log('register :', {"iol_images": iolRegisterationObj});
        fetch("http://localhost:3000", { 
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: 'POST',
            body: JSON.stringify({
                iol_images: iolRegisterationObj
            })
        }).then(response => {
            
            console.log("response:", response)
            
        });
    }
}