import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu"];

  toggleMenu() {
    this.menuTarget.style.display = this.menuTarget.style.display === "none" ? "block" : "none";
    
  }

  viewAllImages() {
    // Handle view all images action
    console.log("View All Images clicked");
  }

  viewCertificationPDF() {
    // Handle view certification PDF action
    console.log("View Certification in PDF clicked");
  }

  // Define other actions for different menu items
}
