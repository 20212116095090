// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.Rails = require("@rails/ujs")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@nathanvda/cocoon")
// Tailwind CSS
import "stylesheets/application"
// Stimulus controllers
import "controllers"
// Application Functionality
import "src/timezone"
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import LocalTime from "local-time"
LocalTime.start()

// Start Rails UJS
Rails.start()
import "trix"
import "@rails/actiontext"
import Swal from 'sweetalert2/dist/sweetalert2.js';
window.Swal = Swal;
import '../channels/chatroom_channel'
import '../channels/grading_status_channel'
