// app/javascript/controllers/norecord_alert_controller.js
import { Controller } from "stimulus";
import Swal from 'sweetalert2';

export default class extends Controller {
    static targets = ["message"];

    connect() {
        if (this.data.get("isEmpty") === "true") {
            this.showAlert();
        }
    }

    showAlert() {
        Swal.fire({
            icon: 'error',
            title: 'Not Found!',
            text: 'No Record Found.',
        });

        this.hideMessage();
    }

    hideMessage() {
        this.messageTarget.style.display = "none";
    }
}
