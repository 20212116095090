import { Controller } from 'stimulus';
import $ from 'jquery';
export default class extends Controller {
  static targets = ['parentSelect', 'childSelect', 'ccemail','bccemail','issueDate', 'expiryDate', 'study' ]; 

  connect() {
    this.pdfButtonClicked = false;
    this.element.querySelector("#view_pdf_for_grandfather").addEventListener("click", this.onClick.bind(this))
    // By Clicking on View PDF button. all form values will be picked through this code. 
    this.element.querySelector("#view_pdf_for_grandfather").addEventListener("click", () => {
    const formData = new FormData(this.element.querySelector("#change_date_form"));
    const url = this.element.querySelector("#view_pdf_for_grandfather").getAttribute("href") + "?" + new URLSearchParams(formData).toString();
    this.element.querySelector("#view_pdf_for_grandfather").setAttribute("href", url);
    });
    // CC email select 2 is implemented here. 
    $(".cc_email").select2({
      tags: true,
      tokenSeparators: [',', ' ']
    });
    // BCC email select 2 is implemented here. 
    $(".request_bcc_email").select2({
      tags: true,
      tokenSeparators: [',', ' ']
    });
    // this code is implemented once issue date is changed so expiry date will be changed automatically 
    this.issueDateTarget.addEventListener("change", () => {
      let generated_for_device = document.getElementById("generated_for_device").value;
      if (generated_for_device === 'true'){
        const issueDate = new Date(this.issueDateTarget.value);
        const expiryDate = new Date(issueDate.setMonth(issueDate.getMonth() + 48));
        this.expiryDateTarget.valueAsDate = expiryDate;
      } 
      else {
        const issueDate = new Date(this.issueDateTarget.value);
        const expiryDate = new Date(issueDate.setMonth(issueDate.getMonth() + 24));
        this.expiryDateTarget.valueAsDate = expiryDate;
      }
  });
  }
  GrandfatherValidate() { 
    this.pdfButtonClicked = true;
    document.getElementById("generate_gf_certificate").classList.remove('remove_disabled_color');    
  }
  checkViewPdfStatus(event) { 
    if (this.pdfButtonClicked === false) {
      alert("Click the 'View PDF' button first, then proceed to Generate the Certificate.");
      event.preventDefault();
    }
  }
  handleParentSelectChange() { 
    if (this.parentSelectTarget.value != "") {
      this.populateChildSelect();
    }
  }
  populateChildSelect() {
    const study_id = this.data.get('study');
    this.selectChangeUrl = document.location.origin + `/studies/${study_id}/fetch_all_templates.json`;
    const childModalityId = this.data.get('childModalityId');
    // populate child modalities
    fetch(this.selectChangeUrl, {
      headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ study_id: study_id, template_id: this.parentSelectTarget.value }),
      method: 'POST',
    })
    .then(response => response.json())
    .then((data) => { 
      document.getElementById("fetch_template_body").value = data.body
    });
  }
  onClick(event) {
    if (!this.validate()) {
      event.preventDefault()
    }
  }
  validate() {
    const study = this.studyTarget.value
    const issue_date = this.issueDateTarget.value

    if (!study || !issue_date ) {
      Swal.fire({
        icon: 'warning',
        title: 'Please select a study first',
    }).then(() => {
    });
      return false
    } else {
      return true
    }
  }
  checkRequestForStudy() { 
    if (this.studyTarget.value != "") {
      this.confirmCertificationRequestForStudy();
    }
  }
  confirmCertificationRequestForStudy() { 
    const certification_request = this.data.get('certification-request-id');
    this.selectChangeUrl = document.location.origin + `/check_request_for_this_study.json`;
    fetch(this.selectChangeUrl, {
      headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ study_id: this.studyTarget.value, certification_request_id: certification_request}),
      method: 'POST',
    })
    .then(response => response.json())
    .then((data) => { 
      if (data.certification_request.length > 0)
        {
          
          Swal.fire({
            icon: 'warning',
            title: 'Certification is already present for this study.',
        }).then(() => {
          location.reload();
        });
      }
      else {
        const selectBox = this.ccemailTarget;
        selectBox.innerHTML = '';
        const defaultOption = document.createElement('option');
        defaultOption.value = "";
        selectBox.appendChild(defaultOption);
        if (data.preference !== null)
        {
          data.preference.cc_email.forEach((item, index) => {
            const opt = document.createElement('option');
            opt.value = item;
            opt.innerHTML = item;
            opt.selected = data.preference.cc_email;
            selectBox.appendChild(opt);
          });
        }
        const bccemail = this.bccemailTarget;
        bccemail.innerHTML = '';
        const bccOption = document.createElement('option');
        bccOption.value = "";
        bccemail.appendChild(bccOption);
        if (data.preference !== null)
        {
          data.preference.bcc_email.forEach((item, index) => {
              const opt = document.createElement('option');
              opt.value = item;
              opt.innerHTML = item;
              opt.selected = data.preference.cc_email;
              bccemail.appendChild(opt);
          });
        }
      }
    });
  }
}
