import { Controller } from 'stimulus';
import $ from 'jquery';
export default class extends Controller {
  static targets = ['changeStatus'];
  connect() {
    $('.select2-search-box').select2();
  }

  checkImageStatus(){
    if (this.changeStatusTarget.value === "Accepted")
    {
      document.getElementById("accept-form").style.display = "block";
      document.getElementById("reject-form").style.display = "none";
    } else if(this.changeStatusTarget.value === "Rejected" || this.changeStatusTarget.value === "Deficient" || this.changeStatusTarget.value === "Duplicate") {
      document.getElementById("accept-form").style.display = "none";
      document.getElementById("reject-form").style.display = "block";
    }
    else {
      document.getElementById("accept-form").style.display = "none";
      document.getElementById("reject-form").style.display = "none";
    }
  }
  showGrandfatheringError(event){
    event.preventDefault();
    Swal.fire({
        icon: 'warning',
        title: 'Cannot generate a grandfather certificate because it is a provisional certificate.',
    })
  }
}