import { Controller } from 'stimulus';
import Swal from 'sweetalert2';
export default class extends Controller {
    show_adjudication_options(event) {

        const element = event.target;
        this.show_base_on_logic(element);

    }

    showAlert() {
        Swal.fire({
            icon: 'success',
            title: 'Updated!',
            showConfirmButton: false,
            timer: 1500
        });
    }

    show_base_on_logic(element) {

        let logical_options = document.querySelector('.logic_base_on_' + element.dataset.index);
        element.value == 'Required' ? logical_options.classList.remove('hidden') : logical_options.classList.add('hidden');


    }

    show_operater_value(event) {
        const element = event.target;
        this.show_operate_value_fields(element);
    }

    show_operate_value_fields(element) {
        let operater_option = document.querySelector('.operator_' + element.dataset.index);
        let value_option = document.querySelector('.custom_value_' + element.dataset.index);
        // Action if select
        (element.value == 'Custom' || element.value == 'Percentage') ? operater_option.classList.remove('hidden') : operater_option.classList.add('hidden');

        (element.value == 'Custom' || element.value == 'Percentage') ? value_option.classList.remove('hidden') : value_option.classList.add('hidden');
    }
}