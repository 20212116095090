import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["form", "parentSelect", "childSelect", "deviceModelSelect", "deviceModelInput", 'deviceName', 'deviceManufacturer', 'deviceSerialNumberSelect', "deviceSerialNumberInput", 'deviceSoftwareVersion', "photographerSelect", "photographerInput"];

    async initialize() {
        this.selectChangeUrl = document.location.origin + `/modalities/${this.parentSelectTarget.value}/stack_child_modalities.json`;
        if (this.data.get('mode') == "reload") {
            this.populateChildSelect(true);
        }
    }

    handleParentSelectChange() {
        // only call child modalities/ devices if parent modality selected
        if (this.parentSelectTarget.value != "") {
            this.populateChildSelect();
            // call the ocap certified devices api
            this.populateDeviceModelSelect();
            // call the ocap certified photographer api
            this.populatePhotographerSelect();
        }
    }

    populateChildSelect(initialize = false) {
        this.selectChangeUrl = document.location.origin + `/modalities/${this.parentSelectTarget.value}/stack_child_modalities.json`;
        const childModalityId = this.data.get('childModalityId');
        // populate child modalities
        fetch(this.selectChangeUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then((data) => {
                const selectBox = this.childSelectTarget;
                selectBox.innerHTML = '';
                // default text
                const defaultOption = document.createElement('option');
                defaultOption.value = "";
                defaultOption.innerHTML = "Select Child Modality";
                defaultOption.selected = childModalityId === "";
                selectBox.appendChild(defaultOption);
                // loop through data
                data.forEach((item, index) => {
                    const opt = document.createElement('option');
                    opt.value = item[0];
                    opt.innerHTML = item[1];
                    opt.selected = childModalityId === item[0];
                    selectBox.appendChild(opt);
                });
                // call only when its reload
                if (initialize) {
                    // call the ocap certified photographer api
                    this.populatePhotographerSelect();
                    // call the ocap certified devices
                    this.populateDeviceModelSelect(initialize)
                }
            });
    }

    handleChildSelectChange() {
        // call the ocap certified devices api
        this.populateDeviceModelSelect();
        // call the ocap certified photographer api
        this.populatePhotographerSelect();
    }

    populateDeviceModelSelect(initialize = false) {
        let devicesData;
        const modality_name = this.parentSelectTarget.value != "" ? this.parentSelectTarget.options[this.parentSelectTarget.selectedIndex].text : "";
        const child_modality_name = this.childSelectTarget.value != "" ? this.childSelectTarget.options[this.childSelectTarget.selectedIndex].text : "";
        const deviceModelId = this.data.get('deviceModel');
        const studyId = this.data.get("studyId");
        const site_id = this.data.get("siteId")
        
        if (child_modality_name !== '') {
            const certified_devices_url = document.location.origin + `/studies/${studyId}/stacks/fetch_certified_devices`;
            fetch(certified_devices_url, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: 'POST',
                body: JSON.stringify({
                    study_id: studyId,
                    modality: modality_name,
                    child_modality: child_modality_name,
                    site_id: site_id,
                })
            })
            .then(response => response.json())
            .then((certified_devices) => {  
                const devicesDataArray = certified_devices || [];
                const ocapUrl = this.data.get('ocapDeviceUrl') + '&certified_device_information=' + true + '&modality_name=' + modality_name + '&child_modality_name=' + child_modality_name;

            fetch(ocapUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Client-Id': this.data.get('clientId'),
                    'Client-Secret': this.data.get('clientSecret')
                },
                method: 'GET',
            })
                .then(response => response.json())
                .then((data) => {
                    const selectBox = this.deviceModelSelectTarget;
                    selectBox.innerHTML = '';
                    // default text
                    const defaultOption = document.createElement('option');
                    defaultOption.value = "";
                    defaultOption.innerHTML = "Select Device Model";
                    defaultOption.selected = deviceModelId === "";
                    selectBox.appendChild(defaultOption);
                    // loop through data 
                    const dataArray = data.data?.data || [];
                    const combinedArray = dataArray.concat(devicesDataArray);
                    combinedArray.forEach((item, index) => { 
                        const opt = document.createElement('option');
                    
                        opt.value = item.label;
                        opt.innerHTML = item.label;
                        opt.setAttribute('data-name', item.device_name);
                        opt.setAttribute('data-model', item.label);
                        opt.setAttribute('data-serial-no', JSON.stringify(item.device_serial));
                        opt.setAttribute('data-soft-version', item.device_software_version);
                        opt.setAttribute('data-manufacturer', item.device_manufacturer);
                        opt.selected = deviceModelId === item.label;
                        selectBox.appendChild(opt);
                    });
                    // others option
                    const othersOption = document.createElement('option');
                    othersOption.value = "Others";
                    othersOption.innerHTML = "Others";
                    othersOption.setAttribute('data-name', "");
                    othersOption.setAttribute('data-model', "");
                    othersOption.setAttribute('data-serial-no', "");
                    othersOption.setAttribute('data-soft-version', "");
                    othersOption.setAttribute('data-manufacturer', "");
                    othersOption.selected = deviceModelId === "Others";
                    selectBox.appendChild(othersOption);
                    // call device model select function
                    this.handleDeviceModelSelectChange("", initialize);
                });
            });
        }
    }

    handleDeviceModelSelectChange(event, initialize = false) {
        const deviceModelSelectBox = this.deviceModelSelectTarget.options[this.deviceModelSelectTarget.selectedIndex];
        const modelInput = document.querySelector('.device-model-input');
        // set values
        if (deviceModelSelectBox.value == "Others") {
            // show model input field
            modelInput.classList.remove('hidden');
            if (!initialize) {
                // remove old values
                this.appendDeviceFieldValues();
            }
            // remove or add values to devices serial
            this.removeAppendDeviceSerialSelectvalues();
        }
        else if (deviceModelSelectBox.value !== "" && deviceModelSelectBox.value !== "Others") {
            // hide model input
            modelInput.classList.add('hidden');
            // empty device model input value
            this.deviceModelInputTarget.value = "";
            if (!initialize) {
                // remove old values
                this.appendDeviceFieldValues();
            }
            // add values to devices serial
            this.removeAppendDeviceSerialSelectvalues(true);
        } else {
            // hide model input
            modelInput.classList.add('hidden');
            // empty device model input value
            this.deviceModelInputTarget.value = "";
            // remove old values
            if (!initialize) {
                // remove old values
                this.appendDeviceFieldValues();
            }
            // remove or add values to devices serial
            this.removeAppendDeviceSerialSelectvalues();
        }
    }

    appendDeviceFieldValues() {
        const deviceModelSelectBox = this.deviceModelSelectTarget.options[this.deviceModelSelectTarget.selectedIndex];
        console.log(deviceModelSelectBox);
        // append
        this.deviceNameTarget.value = deviceModelSelectBox.getAttribute('data-name');
        this.deviceSoftwareVersionTarget.value = deviceModelSelectBox.getAttribute('data-soft-version');
        this.deviceManufacturerTarget.value = deviceModelSelectBox.getAttribute('data-manufacturer');
    }

    removeAppendDeviceSerialSelectvalues(data = false) {
        const selectBox = this.deviceSerialNumberSelectTarget;
        const deviceSerialNumberId = this.data.get('deviceSerialNumber');

        selectBox.innerHTML = '';
        // default text
        const defaultOption = document.createElement('option');
        defaultOption.value = "";
        defaultOption.innerHTML = "Select Device Serial Number";
        defaultOption.selected = deviceSerialNumberId == "";
        selectBox.appendChild(defaultOption);
        if (data) {
            // access device model
            const deviceModelSelectBox = this.deviceModelSelectTarget.options[this.deviceModelSelectTarget.selectedIndex];
            // access selected option data-attribute for device serial number
            const deviceSerialDataAttribute = JSON.parse(deviceModelSelectBox.getAttribute('data-serial-no'));
            // loop through data
            deviceSerialDataAttribute.forEach((item, index) => {
                const opt = document.createElement('option');
                opt.value = item;
                opt.innerHTML = item;
                opt.selected = deviceSerialNumberId === item;
                selectBox.appendChild(opt);
            });
        } // end
        // others option
        const othersOption = document.createElement('option');
        othersOption.value = "Others";
        othersOption.innerHTML = "Others";
        othersOption.selected = deviceSerialNumberId == "Others";
        selectBox.appendChild(othersOption);
        // hide and remove serial input values
        this.handleDeviceSerialNumberSelectChange();
    }

    handleDeviceSerialNumberSelectChange() {
        const deviceSerialNumberSelectBox = this.deviceSerialNumberSelectTarget.options[this.deviceSerialNumberSelectTarget.selectedIndex];
        const serialNumberInput = document.querySelector('.device-serial-number-input');

        if (deviceSerialNumberSelectBox.value !== "Others") {
            // hide serial number input
            serialNumberInput.classList.add('hidden');
            // empty device model input value
            this.deviceSerialNumberInputTarget.value = "";
        } else {
            serialNumberInput.classList.remove('hidden');
        }
    }

    populatePhotographerSelect() {
        let photographersData;
        const modality_name = this.parentSelectTarget.value != "" ? this.parentSelectTarget.options[this.parentSelectTarget.selectedIndex].text : "";
        const child_modality_name = this.childSelectTarget.value != "" ? this.childSelectTarget.options[this.childSelectTarget.selectedIndex].text : "";
        const photographerId = this.data.get('photographer');
        const studyId = this.data.get("studyId");
        const site_id = this.data.get("siteId")
        // ocap url 
        if (child_modality_name !== '') {
            const certified_photographers_url = document.location.origin + `/studies/${studyId}/stacks/fetch_certified_photographers`;
            fetch(certified_photographers_url, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: 'POST',
                body: JSON.stringify({
                    study_id: studyId,
                    modality: modality_name,
                    child_modality: child_modality_name,
                    site_id: site_id,
                })
            })
            .then(response => response.json())
            .then((photographers) => {
                photographersData = photographers ? photographers.map(item => ({ label: item, value: item })) : [];
                const ocapUrl = this.data.get('ocapPhotographerUrl') + '&modality_name=' + modality_name + '&child_modality_name=' + child_modality_name;
                return fetch(ocapUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Client-Id': this.data.get('clientId'),
                        'Client-Secret': this.data.get('clientSecret')
                    },
                    method: 'GET',
                })
                .then(response => response.json())
                .then((data) => { 
                    // Handle response of the second fetch request using photographers data
                    const selectBox = this.photographerSelectTarget;
                    selectBox.innerHTML = '';
                    const mergedData = [...photographersData, ...data.data.data];
                     // default text
                    const defaultOption = document.createElement('option');
                    defaultOption.value = "";
                    defaultOption.innerHTML = "Select Photographer";
                    defaultOption.selected = photographerId === "";
                    selectBox.appendChild(defaultOption);
                    mergedData.forEach((item, index) => {
                        const opt = document.createElement('option');
                        opt.value = item.label;
                        opt.innerHTML = item.label;
                        opt.selected = photographerId === item.label;
                        selectBox.appendChild(opt);
                    });

                    // others option
                    const othersOption = document.createElement('option');
                    othersOption.value = "Others";
                    othersOption.innerHTML = "Others";
                    othersOption.selected = photographerId == "Others";
                    selectBox.appendChild(othersOption);

                    // call photographer select function
                    this.handlePhotographerSelectChange();
                });
            });
        }
    }

    handlePhotographerSelectChange() {
        const photographerSelectBox = this.photographerSelectTarget.options[this.photographerSelectTarget.selectedIndex];
        const photographerInput = document.querySelector('.photographer-input');

        if (photographerSelectBox.value !== "Others") {
            // hide serial number input
            photographerInput.classList.add('hidden');
            // empty device model input value
            this.photographerInputTarget.value = "";
        } else {
            photographerInput.classList.remove('hidden');
        }
    }

    formSubmit(event) {
        event.preventDefault();
        var errorMessage = false;

        const deviceModelSelect = this.deviceModelSelectTarget.options[this.deviceModelSelectTarget.selectedIndex];
        const deviceSerialSelect = this.deviceSerialNumberSelectTarget.options[this.deviceSerialNumberSelectTarget.selectedIndex];
        const photographerSelect = this.photographerSelectTarget.options[this.photographerSelectTarget.selectedIndex];
        // const images          = document.getElementById('images-length').childElementCount;
        const images = document.querySelectorAll("input[name='stack[images][]']").length;
        const requiredData = document.querySelectorAll('.required-data');

        requiredData.forEach((item, index) => {
            if (item.value === "" && item.getAttribute('data-label-name') !== null && !errorMessage) {
                alert(`${item.getAttribute('data-label-name')} field is required.`);
                errorMessage = true;
                return;
            }
        });

        if (!errorMessage) {
            if (deviceModelSelect.value === 'Others' && this.deviceModelInputTarget.value == "") {
                alert("Device model input field is require.");
                return;
            }
            if (deviceSerialSelect.value === 'Others' && this.deviceSerialNumberInputTarget.value == "") {
                alert("Device serial number input field is require.");
                return;
            }
            if (photographerSelect.value === 'Others' && this.photographerInputTarget.value == "") {
                alert("Photographer input field is require.");
                return;
            }
            // submit form
            event.target.form.requestSubmit();
        }
    }

    deleteImage(event) {
        event.preventDefault();
        if (confirm("Are you sure, you want to delete the image?")) {
           const imageElement = event.currentTarget.closest(".w-20.h-20");
           const imageId = imageElement.querySelector('input[name="stack[images_blob_id][]"]').value;
           const deleteUrlTemplate = event.currentTarget.dataset.stackDeleteImageUrl;
           const deleteUrl = deleteUrlTemplate.replace(':image_id', imageId);
          fetch(deleteUrl, {
            method: 'DELETE',
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
          })
          .then(response => {
            if (response.ok) {
              imageElement.remove();
            } else {
              console.error('Failed to delete the image.');
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
        }
      }

}