import { Controller } from 'stimulus';

import $ from 'jquery';

require('select2/dist/css/select2');
require('select2-bootstrap-theme/dist/select2-bootstrap');

export default class extends Controller {
    static targets = ['form'];

    connect() {
        $('.device-modalities').select2();
    }
}
