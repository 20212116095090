import { Controller } from 'stimulus';

import $ from 'jquery';

require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

import Select2 from "select2";
export default class extends Controller {
    static targets = ['form']

    connect() {
        $('.site-search').select2();

        $(".site-search").on("change", function (e) {
            // check select all else deselect
            if ($(".site-search :selected").length == $('.site-search option').length) {
                document.getElementsByClassName("checkbox_select_all")[0].checked = true;
            } else {
                document.getElementsByClassName("checkbox_select_all")[0].checked = false;
            }
        });
    }

    submit(e) {
        let isValid = this.validateform();
        if (!isValid) {
            e.preventDefault()
        }
    }

    validateform() {
        return this.validateRoles() && this.validateSiteForUploader();
    }

    validateRoles() {
        let roles = document.getElementsByClassName("checkbox_role");
        let isRoleValid = false;
        roles.forEach(role => {
            if (role.checked) {
                isRoleValid = true
            }
        });

        if (isRoleValid) {
            document.getElementById("role-error").classList.add("hidden");
        }
        else {
            document.getElementById("role-error").classList.remove("hidden");
        }

        return isRoleValid
    }

    validateSiteForUploader() {
        let site = document.getElementById("study_invitation_site_ids") || document.getElementById("study_user_site_ids");
        let isValid = true;
        let roleImageUploader = document.getElementById("study_invitation_image_uploader") || document.getElementById("study_user_image_uploader");
        let roleQC = document.getElementById("study_invitation_quality_controller") || document.getElementById("study_user_quality_controller");
        let roleGrader = document.getElementById("study_invitation_grader") || document.getElementById("study_user_grader");
        let roleAdj = document.getElementById("study_invitation_adjudicator") || document.getElementById("study_user_adjudicator");
        let roleSponsor = document.getElementById("study_invitation_sponsor") || document.getElementById("study_user_sponsor");
        if ((roleImageUploader.checked && !site.value) || (roleQC.checked && !site.value) || (roleGrader.checked && !site.value) || (roleAdj.checked && !site.value) || (roleSponsor.checked && !site.value)) {
            alert("At least one of the site needs to be selected")
            isValid = false
        }
        return isValid
    }

    roleChange(event) {

        let checkRoles = document.getElementsByClassName("checkbox_role");

        let isQcOrGraderRoleValid = false;
        // check roles
        checkRoles.forEach(role => {
            if ((role.getAttribute('data-rolevalue') == 'quality_controller' || role.getAttribute('data-rolevalue') == 'grader') && role.checked) {
                isQcOrGraderRoleValid = true;
            }
        });

        if (event.target.getAttribute('data-rolevalue') == 'quality_controller' || event.target.getAttribute('data-rolevalue') == 'grader') {
            this.selectAllChange("", true, isQcOrGraderRoleValid);
        }
        this.checkIfRoleIsPM(checkRoles);
    }

    checkIfRoleIsPM(checkRoles){
        let emailNotificationCheckbox = document.getElementsByClassName('checkbox_email_notification')[0];
        let foundProjectAdmin = false;
        checkRoles.forEach(role => {
            if (role.getAttribute('data-rolevalue') == 'project_admin' && role.checked) {
                foundProjectAdmin = true;
            }
        });
        this.AutoSelectEmailNotification(foundProjectAdmin, emailNotificationCheckbox);
    }
 
    AutoSelectEmailNotification(status, emailNotificationCheckbox) {
        if (status) {
            emailNotificationCheckbox.checked = true;
        } else {
            emailNotificationCheckbox.checked = false;
        }
    }

    selectAllChange(event, role = false, roleOption) {

        const selected = role ? roleOption : document.getElementsByClassName("checkbox_select_all")[0].checked;

        $('.site-search').select2("destroy");
        $('.site-search').find('option').attr('selected', selected);
        $('.site-search').select2();

        // check select all else deselect
        if ($(".site-search :selected").length == $('.site-search option').length) {
            document.getElementsByClassName("checkbox_select_all")[0].checked = true;
        } else {
            document.getElementsByClassName("checkbox_select_all")[0].checked = false;
        }
    }

}