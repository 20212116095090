import { Controller } from 'stimulus';


export default class extends Controller {
    static targets = ['imagesListing'];
;    async initialize() {
    }

    toggleRandomizeImages() {
        var imagesListing = document.querySelector('.images-listing');

        // toggle classes
       imagesListing.classList.contains('hidden')
        ? imagesListing.classList.remove('hidden') 
        : imagesListing.classList.add('hidden');
    }

}