import { Controller } from 'stimulus';
export default class extends Controller {
    
  rejectGFImages(event){  
    let reject_gf_form = document.getElementById("show_rejected_gf_form")
    if(reject_gf_form.style.display === "none"){
      reject_gf_form.style.display = "block";
    }
    document.getElementById("show_gf_certification_form").style.display = "none";
    document.getElementById("accept_images").style.display = "none";
    event.preventDefault();
  }
  acceptGFImages(event){
    debugger 
    let show_form = document.getElementById("show_gf_certification_form");
    if(show_form.style.display === "none"){
        show_form.style.display = "block";
    }
    document.getElementById("show_rejected_gf_form").style.display = "none";
    document.getElementById("accept_images").style.display = "none";
    event.preventDefault();
  }
  show_gf_set_images(event){
    event.preventDefault();
    const selectChangeUrl = document.location.origin + `/show_other_set_images.json`;
    fetch(selectChangeUrl, {
      headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ property_set_image_id: event.currentTarget.id }),
      method: 'POST',
    })
    .then(response => response.json())
          .then((data) => {
            document.getElementById('lower_gf_preview').innerHTML = data.html;
            document.getElementById(data.property_set_image_id).style.backgroundColor = 'blue';                
    });
  }
}
