import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['toggleSection', 'chromeMessage']

    connect() {
        this.checkBrowser();
    }

    toggleMenu() {
        this.toggleSectionTarget.classList.toggle("hidden");
    }
    checkBrowser() {
        const userAgent = navigator.userAgent;
        const isChrome = (/Chrome/.test(userAgent) && !/Edg|Firefox|OPR|Brave|Vivaldi|SamsungBrowser|CriOS|YaBrowser/.test(userAgent));
        if (isChrome) {
            this.chromeMessageTarget.style.display = 'none';
        }
    }
}