import consumer from "./consumer"

consumer.subscriptions.create("QueriesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  // received(data) {
  received() {
    // Called when there's incoming data on the websocket for this channel
    // console.log(data);
    // $('#Notifications').prepend(data.html);
  }
});
